import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import { UpdatePencil, DropdownArrow, SearchBig, AddwithWhiteCircle } from "../AllSvg";
import { Modal } from 'react-bootstrap';
import Createnewall from "../Modals/Createnewall";
import { handleErrorToast } from "../CustomFunctions";
// import { AddProjectDetails } from "../Modals/UpdateProjectDetails";


const AddProjectDetails = ({ getDetailsdesign }) => {
  const [sub_CompanyList, setsub_CompanyList] = useState([]);
  const getAllsub_Company = async () => {

    try {
      const sub = await axios.get(`${BASE_URL}/detail-design/subCompany`);
      setsub_CompanyList(sub.data);
    } catch (err) {

    } finally {

    }
  };
  const [projectList, setProjectlist] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    pr_code: '',
    sub_Company: '',
    project_name: '',
    client: '',
    location: '',
    concern_person: '',
    concern_person_name: '',
    length: '',
    award_date: '',
    wo_date: '',
    start_date: '',
    due_date: '',
    project_mode: '',
    woamount: '',
    gst_amount: '',
    without_gst_amount: '',
    confirm_by: '',
    confirmation_mode: '',
    status: 'pending',
    project_description: "",
  });




  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      'project_name',
      'pr_code',
      'client',
      "sub_Company",
      "concern_person",
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    setErrors(newErrors);


    return Object.keys(newErrors).length === 0;
  };

  console.log(formData)

  console.log(errors)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const filteredFormData = Object.keys(formData).reduce((acc, key) => {
          if (formData[key] !== '') {
            acc[key] = formData[key];
          }
          return acc;
        }, {});
        let res = await axios.post(`${BASE_URL}/detail-design/project/`, filteredFormData, {
          headers: {
            'Content-Type': 'application/json',
            accept: "application/json",
          }
        })

        if (res.status === 200) {
          setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
              acc[key] = "";
              return acc;
            }, {})
          );
          window.location.reload();

        } else {

        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
    // Handle other fields (e.g., project_name, client, etc.) as usual
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // get request Client Data
  useEffect(() => {
    if (show) {
      getClientDetails();
    }

  }, [show]);

  const [allClientData, setAllClientData] = useState([]);
  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/detail-design/client/`,
      );
      setAllClientData(res.data);
    } catch (err) {
      //toast Logic
      alert(err)
    } finally {

    }

  }
  const handleShow = () => {
    setShow(true);
    getAllsub_Company();

  }

  const handleClose = () => {
    setShow(false);
    setFormData(() =>
      Object.keys(formData).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {})
    );
  }


  return (
    <>
      <button
        title="Add Detail Design"
        className="upload-svg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle /> Add Project
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="register-form">
            <div className='form-flex-wrap'>
              <div className="flex-column">
                <label className="form-labels">PR. Code <span className="required">*</span></label>
                <input
                  type="text"
                  name="pr_code"
                  value={formData.pr_code}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                />
                {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Project Description </label>
                <input
                  type="text"
                  name="project_description"
                  value={formData.project_description}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_description ? 'error' : inputState.project_description ? 'success' : ''}`}
                />
                {errors.project_description && <span className="error-message">{errors.project_description}</span>}
              </div>

              <div className="flex-column">
                <label className="form-labels">Project Name <span className="required">*</span></label>
                <input
                  type="text"
                  name="project_name"
                  value={formData.project_name}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                />
                {errors.project_name && <span className="error-message">{errors.project_name}</span>}
              </div>
              <div title="sub_Company" className="flex-column form-group-selectt">
                <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company <span className="required">*</span>
                </label>
                <br />
                {sub_CompanyList.length === 0 ?
                  <div className="form-loader">
                    <div className="bar-loader"></div>
                  </div> :
                  <>
                    <select
                      id="sub_Company"
                      name="sub_Company"
                      value={formData.sub_Company}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                        }`}
                    >
                      <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                      {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                        <option value={i.id}>{i.title}</option>
                      </>))
                      }
                    </select>
                    <DropdownArrow />
                    <div>
                      {errors.sub_Company && (
                        <span className="error-message font-size-text ">{errors.sub_Company}</span>
                      )}
                    </div></>}
              </div>
              <div className="flex-column form-group-selectt">
                <label className="form-labels"> Client Name<span className="required">*</span></label>
                <br />
                {allClientData.length === 0 ?
                  <div className="form-loader">
                    <div className="bar-loader"></div>
                  </div> : <>
                    <select
                      name="client"
                      value={formData.client}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                    >
                      <option value="">Select a Client</option>
                      {
                        allClientData.length > 0 ?
                          (allClientData.map((e, index) => (
                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                          ))) : (
                            <option value="">No Clients Available</option>
                          )
                      }

                    </select>
                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <br />
                    {errors.client && (
                      <span className="error-message">{errors.client}</span>
                    )}
                  </>}
              </div>

              <div className="flex-column">
                <label className="form-labels">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                />
                {errors.location && <span className="error-message">{errors.location}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Length</label>
                <input
                  type="text"
                  name="length"
                  value={formData.length}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                />
                {errors.length && <span className="error-message">{errors.length}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">WO Amount</label>
                <input
                  type="number"
                  name="woamount"
                  value={formData.woamount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                />
                {errors.woamount && <span className="error-message">{errors.woamount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Without GST Amount</label>
                <input
                  type="number"
                  name="without_gst_amount"
                  value={formData.without_gst_amount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                />
                {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> GST Amount</label>
                <input
                  type="numbeer"
                  name="gst_amount"
                  value={formData.gst_amount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                />
                {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Project Mode</label>
                <input
                  type="text"
                  name="project_mode"
                  value={formData.project_mode}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                />
                {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Concern Person<span className="required">*</span></label>
                <input
                  type="text"
                  name="concern_person"
                  value={formData.concern_person}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                />
                {errors.concern_person && <span className="error-message">{errors.concern_person}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Confirm By</label>
                <input
                  type="text"
                  name="confirm_by"
                  value={formData.confirm_by}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                />
                {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
              </div>

              <div className="flex-column">
                <label className="form-labels">  Confirmation Mode</label>
                <input
                  type="text"
                  name="confirmation_mode"
                  value={formData.confirmation_mode}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                />
                {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Award Date</label>
                <input
                  type="date"
                  name="award_date"
                  value={formData.award_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                />
                {errors.award_date && <span className="error-message">{errors.award_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">WO Date</label>
                <input
                  type="date"
                  name="wo_date"
                  value={formData.wo_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                />
                {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                />
                {errors.start_date && <span className="error-message">{errors.start_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Due Date</label>
                <input
                  type="date"
                  name="due_date"
                  value={formData.due_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                />
                {errors.due_date && <span className="error-message">{errors.due_date}</span>}
              </div>

























              <div className="flex-column form-group-selectt">
                <label className="form-labels">Status:</label>
                <br />
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>
                  <option value="inprogress">In Progeress</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="completed">Completed</option>
                  <option value="hold">Hold</option>
                  <option value="cancelled">Cancelled</option>


                </select>
                <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.status && (
                  <span className="error-message ">{errors.status}</span>
                )}

              </div>







            </div>


            <div className="button-models">
              <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
            </div>

          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
const Updateprojectdata = ({
  i,
  getJobappdata
}) => {
  const [show, setShow] = useState(false);
  const [sub_CompanyList, setsub_CompanyList] = useState([]);
  const getAllsub_Company = async () => {

    try {
      const sub = await axios.get(`${BASE_URL}/detail-design/subCompany`);
      setsub_CompanyList(sub.data);
    } catch (err) {

    } finally {

    }
  };
  const [formData, setFormData] = useState({
    pr_code: '',
    client: "",
    project_name: "",
    location: "",
    concern_person_name: "",
    length: "",
    award_date: "",
    wo_date: "",
    start_date: "",
    due_date: "",
    project_mode: "",
    confirm_by: "",
    confirmation_mode: "",
    status: "",
    project_description: '',


  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFormData(i);
    getAllsub_Company();

  };
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      'project_name',
      'pr_code',
      'client',
      "sub_Company",
      "concern_person",
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    setErrors(newErrors);


    return Object.keys(newErrors).length === 0;
  };
  const [allClientData, setAllClientData] = useState([]);
  useEffect(() => {
    getClientDetails();
  }, []);
  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/detail-design/client`,
      );
      setAllClientData(res.data);
    } catch (err) {

    } finally {

    }

  }
  console.log(formData)

  console.log(errors)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const filteredFormData = Object.keys(formData).reduce((acc, key) => {
          if (formData[key] !== '') {
            acc[key] = formData[key];
          }
          return acc;
        }, {});
        let res = await axios.put(`${BASE_URL_PREBID}/detail-design/projectupdate/${formData.pr_code}/`, filteredFormData, {
          headers: {
            'Content-Type': 'application/json',
            accept: "application/json",
          }
        })

        if (res.status === 200) {
          setShow(false)
          await getJobappdata(null);
        } else {
          ;
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
    // Handle other fields (e.g., project_name, client, etc.) as usual
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <button onClick={handleShow} className="model-edit-button">
        <UpdatePencil />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Update All Projects Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {console.log(formData)}
          <form>
            <div className='form-flex-wrap'>
              <div className="flex-column">
                <label className="form-labels">PR. Code <span className="required">*</span></label>
                <input
                  type="text"
                  name="pr_code"
                  value={formData.pr_code}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                />
                {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Project Description </label>
                <input
                  type="text"
                  name="project_description"
                  value={formData.project_description}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_description ? 'error' : inputState.project_description ? 'success' : ''}`}
                />
                {errors.project_description && <span className="error-message">{errors.project_description}</span>}
              </div>

              <div className="flex-column">
                <label className="form-labels">Project Name <span className="required">*</span></label>
                <input
                  type="text"
                  name="project_name"
                  value={formData.project_name}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                />
                {errors.project_name && <span className="error-message">{errors.project_name}</span>}
              </div>
              <div title="sub_Company" className="flex-column form-group-selectt">
                <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company <span className="required">*</span>
                </label>
                <br />
                {sub_CompanyList.length === 0 ?
                  <div className="form-loader">
                    <div className="bar-loader"></div>
                  </div> :
                  <>
                    <select
                      id="sub_Company"
                      name="sub_Company"
                      value={formData.sub_Company}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                        }`}
                    >
                      <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                      {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                        <option value={i.id}>{i.title}</option>
                      </>))
                      }
                    </select>
                    <DropdownArrow />
                    <div>
                      {errors.sub_Company && (
                        <span className="error-message font-size-text ">{errors.sub_Company}</span>
                      )}
                    </div></>}
              </div>
              <div className="flex-column form-group-selectt">
                <label className="form-labels"> Client Name<span className="required">*</span></label>
                <br />
                {allClientData.length === 0 ?
                  <div className="form-loader">
                    <div className="bar-loader"></div>
                  </div> : <>
                    <select
                      name="client"
                      value={formData.client}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                    >
                      <option value="">Select a Client</option>
                      {
                        allClientData.length > 0 ?
                          (allClientData.map((e, index) => (
                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                          ))) : (
                            <option value="">No Clients Available</option>
                          )
                      }

                    </select>
                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <br />
                    {errors.client && (
                      <span className="error-message">{errors.client}</span>
                    )}
                  </>}
              </div>

              <div className="flex-column">
                <label className="form-labels">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                />
                {errors.location && <span className="error-message">{errors.location}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Length</label>
                <input
                  type="text"
                  name="length"
                  value={formData.length}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                />
                {errors.length && <span className="error-message">{errors.length}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">WO Amount</label>
                <input
                  type="number"
                  name="woamount"
                  value={formData.woamount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                />
                {errors.woamount && <span className="error-message">{errors.woamount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Without GST Amount</label>
                <input
                  type="number"
                  name="without_gst_amount"
                  value={formData.without_gst_amount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                />
                {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> GST Amount</label>
                <input
                  type="numbeer"
                  name="gst_amount"
                  value={formData.gst_amount}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                />
                {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Project Mode</label>
                <input
                  type="text"
                  name="project_mode"
                  value={formData.project_mode}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                />
                {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Concern Person<span className="required">*</span></label>
                <input
                  type="text"
                  name="concern_person"
                  value={formData.concern_person}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                />
                {errors.concern_person && <span className="error-message">{errors.concern_person}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Confirm By</label>
                <input
                  type="text"
                  name="confirm_by"
                  value={formData.confirm_by}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                />
                {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
              </div>

              <div className="flex-column">
                <label className="form-labels">  Confirmation Mode</label>
                <input
                  type="text"
                  name="confirmation_mode"
                  value={formData.confirmation_mode}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                />
                {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Award Date</label>
                <input
                  type="date"
                  name="award_date"
                  value={formData.award_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                />
                {errors.award_date && <span className="error-message">{errors.award_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels">WO Date</label>
                <input
                  type="date"
                  name="wo_date"
                  value={formData.wo_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                />
                {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                />
                {errors.start_date && <span className="error-message">{errors.start_date}</span>}
              </div>
              <div className="flex-column">
                <label className="form-labels"> Due Date</label>
                <input
                  type="date"
                  name="due_date"
                  value={formData.due_date}
                  onChange={handleInputChange}
                  className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                />
                {errors.due_date && <span className="error-message">{errors.due_date}</span>}
              </div>

























              <div className="flex-column form-group-selectt">
                <label className="form-labels">Status:</label>
                <br />
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                >
                  <option value="">Select a Status</option>
                  <option value="pending">Pending</option>
                  <option value="inprogress">In Progeress</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="completed">Completed</option>
                  <option value="hold">Hold</option>
                  <option value="cancelled">Cancelled</option>


                </select>
                <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.status && (
                  <span className="error-message ">{errors.status}</span>
                )}

              </div>







            </div>


            <div className="button-models">
              <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal >

    </>
  );
};

const DetailsdesignDelete = ({ i, getJobappdata }) => {
  const [show, setShow] = useState(false);
  // console.log(i)
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      let res = await axios.delete(
        `${BASE_URL}/detail-design/projectupdate/${i.pr_code}/`
      );

      if (res.status === 200) {
        await getJobappdata(null);
        setShow(false);
      } else {
        ;
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      // setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Insurance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Project {i.project_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are you sure You want to delete this project{i.project_name}
            <div className="button-models">
              <button
                className="model-button   font-weight500  model-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};
const AllProjectDetails = () => {
  const location = useLocation();
  const [allClientsData, setAllClientsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleState, setToggleState] = useState(
    location.state?.status || "null"
  );

  const getJobappdata = async (toggleState) => {
    setBuffering(true); // Start Buffering
    try {
      // /allprojectfilter/${toggleState}
      // const res = await axios.get(`${BASE_URL_PREBID}/project/allprojectfilter/${toggleState}/` );
      const res = await axios.get(`${BASE_URL}/detail-design/allprojectfilter/${toggleState}`);
      setAllClientsData(res.data);
      setFilteredData(res.data);
    } catch (err) {
      // alert(err.message);

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getJobappdata(toggleState);
  }, [toggleState]);

  const handleSearch = () => {
    let filteredingData = allClientsData;

    if (search1 !== "") {
      const lowercasesearch1 = search1?.toString().toLowerCase();
      filteredingData = filteredData.filter(
        (employee) => {
          const codeMatch = employee?.pr_code
            ?.toString()
            .includes(lowercasesearch1);

          const projectMatch = employee?.project_name
            ?.toString()
            .includes(lowercasesearch1);

          return codeMatch || projectMatch;
        }
      );
    }

    if (search2 !== "") {
      const lowercasesearch1 = search2.toLowerCase();

      filteredingData = filteredData.filter((employee) => {
        const desiMatch = employee?.client_name
          .toLowerCase()
          .includes(lowercasesearch1);

        return desiMatch;
      });
    }

    setFilteredData(filteredingData);
  };

  useEffect(() => {
    handleSearch();
  }, [search1, search2]);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const getEmployeeDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem(
          "departmentid"
        )}/`
      );
      setAllEmployeeData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [initiated, setInitiated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };




  const navigate = useNavigate();

  const getProjectdetails = (id) => {
    // Navigate to the new route and pass the state
    navigate(`/ed/Projectdetails/`, {
      state: { projectId: id }
    });
  };

  return (
    <>
      <Dashboardnavbar url="All Projects" name={"All Projects"} />

      <div className="content-tabs">
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw"
                placeholder="BY Project Code/Name"
                type="text"
                value={search1}
                onChange={(e) => setSearch1(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field  width-10vw"
                placeholder="By Client Name"
                type="text"
                value={search2}
                onChange={(e) => setSearch2(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

          </div>
          <div className="btn-count">
            <AddProjectDetails />
          </div>

        </div>
        <div className="table-css-white-background table-box scroll-container-table">
          <div className="table-heading-flex field-cont-between table-css-white-background">
            <div className="repo-heading heading-padding-none font-weight500 font-size-heading">All Projects</div>
          </div>
          <div className="table-heading-flex">
            <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
              {[
                "All",
                "pending",
                "inprogress",
                "completed",
                "hold",
                "cancelled",
              ].map((status) => (
                <button
                  key={status}
                  className={
                    toggleState === (status === "All" ? "null" : status)
                      ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs font-weight400"
                      : "dashboardcomponent-tabs white-tab font-weight400  font-size-subheading   white-tab"
                  }
                  onClick={() =>
                    setToggleState(status === "All" ? "null" : status)
                  }
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              ))}
            </div>
          </div>
          <table className="table-css">
            <thead className="table-heading">
              <tr className="custom-table-head-tr">
                <th className="text-center table-heading-text">Sr no.</th>
                <th className="text-center table-heading-text">PR. Code</th>
                <th className="text-center table-heading-text">Project Name</th>

                <th className="text-center table-heading-text">Client Name</th>

                <th className="text-center table-heading-text">Location</th>
                <th className="text-center table-heading-text">Length</th>
                <th className="text-center table-heading-text ">Project Mode</th>
                <th className="text-center table-heading-text">Concern Person</th>

                <th className="text-center table-heading-text">Award Date</th>
                <th className="text-center table-heading-text">Workorder Date</th>
                <th className="text-center table-heading-text">Start Date</th>
                <th className="text-center table-heading-text">Due Date</th>

                <th className="text-center table-heading-text ">Status</th>
                <th className=" text-center table-heading-text">View</th>
                <th className=" text-center table-heading-text width-5vw ">Actions</th>


              </tr>
            </thead>
            {buffer ? <div className="spinner-small"></div> :

              <tbody>
                {filteredData
                  .sort((a, b) =>
                    a.client_name.localeCompare(b.client_name, "en", {
                      ignorePunctuation: true,
                    })
                  )
                  .map((i, index) => (
                    <React.Fragment key={index}>

                      <tr className="custom-table-head-td" >
                        <td className="text-center table-body">{index + 1}</td>
                        <td className="text-center table-body">{i.pr_code}</td>
                        <td className="text-center table-body">{i.project_name}</td>
                        <td className="text-center table-body">{i.client_name}</td>

                        <td className="text-center table-body">{i.location}</td>
                        <td className="text-center table-body">{i.length}</td>
                        <td className="text-center table-body">{i.project_mode}</td>
                        <td className="text-center table-body">{i.concern_person}</td>

                        <td className="text-center table-body">{i.award_date}</td>
                        <td className="text-center table-body">{i.wo_date}</td>
                        <td className="text-center table-body">{i.start_date}</td>
                        <td className="text-center table-body">{i.due_date}</td>

                        <td className="text-center table-body">{i.status}</td>

                        <td className="text-center table-body">
                          <Link onClick={(e) => {
                            e.preventDefault()
                            getProjectdetails(i.pr_code)
                          }} >
                            <button
                              title="something"
                              className="models-button model-add "
                              onClick={handleShow}

                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <circle
                                  cx="15"
                                  cy="15"
                                  r="15"
                                  fill="#F6F7F9"
                                ></circle>
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                ></path>
                              </svg>
                            </button>
                          </Link>
                        </td>
                        <td className="text-center table-body width-5vw"  >
                          <div className="flex-row"> <Updateprojectdata i={i} getJobappdata={getJobappdata} /><DetailsdesignDelete i={i} getJobappdata={getJobappdata} /></div>
                        </td>


                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            }
          </table>


        </div >
      </div >
    </>
  );
};

export default AllProjectDetails;
