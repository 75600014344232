import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { AddwithWhiteCircle, UpdatePencil, DropdownArrow, SearchBig, UpdatePencilWhite, PDFIcon } from "../AllSvg";
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorToast } from "../CustomFunctions";
const Addclientdetails = ({ getClientdetails }) => {

    const [show, setShow] = useState(false);
    const [sub_CompanyList, setsub_CompanyList] = useState([]);
    const [formData, setFormData] = useState({

        name: '',
        contact_person: '',
        sub_Company: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: ''
    });

    const getAllsub_Company = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setsub_CompanyList(sub.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {

        }
    };


    const handleShow = () => {
        setShow(true);

    }
    useEffect(() => {
        if (show) {
            getAllsub_Company(null);
        }
    }, [formData.sub_Company, show])
    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(files)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue)
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "sub_Company",
            "pos",
            "name",
            "gstin_number",

        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });





        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}


        if (validateform()) {

            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();



                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                let res = await axios.post(
                    `${BASE_URL}/detail-design/client/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(res)
                if (res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getClientdetails();
                } else {
                    // toast.error(`Error: `);
                }
            } catch (err) {
                // setShow(false);
                toast.error(err)
                handleErrorToast(err)
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }


    };



    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add Client Details
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Client Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="name" className="flex-column">
                                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                                    Client Name<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="name"
                                    name="name"
                                    placeholder="Client Name"
                                    onChange={handleInputChange}
                                    value={formData.name}
                                    className={`form-input ${errors.name
                                        ? "error"
                                        : inputState.name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.name && (
                                    <span className="error-message font-size-text ">{errors.name}</span>
                                )}
                            </div>

                            <div title="contact_person" className="flex-column">
                                <label htmlFor="contact_person" className="form-labels font-weight500 font-size-subheading">
                                    Contact Person
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="contact_person"
                                    name="contact_person"
                                    placeholder="Contact Person"
                                    onChange={handleInputChange}
                                    value={formData.contact_person}
                                    className={`form-input ${errors.contact_person
                                        ? "error"
                                        : inputState.contact_person
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.contact_person && (
                                    <span className="error-message font-size-text ">{errors.contact_person}</span>
                                )}
                            </div>
                            <div title="sub_Company" className="flex-column form-group-selectt">
                                <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                                    Sub Company <span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="sub_Company"
                                    name="sub_Company"
                                    value={formData.sub_Company}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select a Sub Company</option>
                                    {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.company_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                        {console.log(i)}
                                        <option value={i.id}>{i.title}</option>
                                    </>))
                                    }
                                </select>
                                <DropdownArrow />
                                <div>
                                    {errors.sub_Company && (
                                        <span className="error-message font-size-text ">{errors.sub_Company}</span>
                                    )}
                                </div>
                            </div>
                            <div title="address" className="flex-column">
                                <label htmlFor="address" className="form-labels font-weight500 font-size-subheading">
                                    Client Address
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="address"
                                    name="address"
                                    placeholder="Client Address"
                                    onChange={handleInputChange}
                                    value={formData.address}
                                    className={`form-input ${errors.address
                                        ? "error"
                                        : inputState.address
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.address && (
                                    <span className="error-message font-size-text ">{errors.address}</span>
                                )}
                            </div>

                            <div title="email" className="flex-column">
                                <label htmlFor="email" className="form-labels font-weight500 font-size-subheading">
                                    Client Email
                                </label>
                                <input
                                    type="email"
                                    maxLength={100}
                                    id="email"
                                    name="email"
                                    placeholder="Client Email"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                    className={`form-input ${errors.email
                                        ? "error"
                                        : inputState.email
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.email && (
                                    <span className="error-message font-size-text ">{errors.email}</span>
                                )}
                            </div>

                            <div title="contact_number" className="flex-column">
                                <label htmlFor="contact_number" className="form-labels font-weight500 font-size-subheading">
                                    Client Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="contact_number"
                                    name="contact_number"
                                    placeholder="Client Number"
                                    onChange={handleInputChange}
                                    value={formData.contact_number}
                                    className={`form-input ${errors.contact_number
                                        ? "error"
                                        : inputState.contact_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.contact_number && (
                                    <span className="error-message font-size-text ">{errors.contact_number}</span>
                                )}
                            </div>

                            <div title="alt_contact_number" className="flex-column">
                                <label htmlFor="alt_contact_number" className="form-labels font-weight500 font-size-subheading">
                                    Alternate Contact Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="alt_contact_number"
                                    name="alt_contact_number"
                                    placeholder="Alternate Contact Number"
                                    onChange={handleInputChange}
                                    value={formData.alt_contact_number}
                                    className={`form-input ${errors.alt_contact_number
                                        ? "error"
                                        : inputState.alt_contact_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.alt_contact_number && (
                                    <span className="error-message font-size-text ">{errors.alt_contact_number}</span>
                                )}
                            </div>

                            <div title="gstin_number" className="flex-column">
                                <label htmlFor="gstin_number" className="form-labels font-weight500 font-size-subheading">
                                    Client GST Number<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="gstin_number"
                                    name="gstin_number"
                                    placeholder="Client GST Number"
                                    onChange={handleInputChange}
                                    value={formData.gstin_number}
                                    className={`form-input ${errors.gstin_number
                                        ? "error"
                                        : inputState.gstin_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.gstin_number && (
                                    <span className="error-message font-size-text ">{errors.gstin_number}</span>
                                )}
                            </div>

                            <div title="pos" className="flex-column">
                                <label htmlFor="pos" className="form-labels font-weight500 font-size-subheading">
                                    Place of Supply (POS)<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="pos"
                                    name="pos"
                                    placeholder="Place of Supply"
                                    onChange={handleInputChange}
                                    value={formData.pos}
                                    className={`form-input ${errors.pos
                                        ? "error"
                                        : inputState.pos
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.pos && (
                                    <span className="error-message font-size-text ">{errors.pos}</span>
                                )}
                            </div>

                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button font-weight500 model-button-submit"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}
const Updateclientdetails = ({
    i,
    getClientdetails,
}) => {

    const [show, setShow] = useState(false);
    const [sub_CompanyList, setsub_CompanyList] = useState([]);
    const getAllsub_Company = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany`);
            setsub_CompanyList(sub.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {

        }
    };

    const [formData, setFormData] = useState({
        name: '',
        contact_person: '',
        address: '',
        email: '',
        sub_Company: '',
        sub_Company_name: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: ''
    });
    useEffect(() => {
        if (show) {
            getAllsub_Company();
        }
    }, [formData.sub_Company, show])
    const handleClose = () => {
        setShow(false)
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    };
    const handleShow = () => {
        setShow(true);
        setFormData(i)

    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(value)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue)
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "sub_Company",
            "pos",
            "name",
            "gstin_number",

        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });





        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}

        if (validateform()) {
            // setErrors(newerrors)
            //   setLoading(true); 
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const formDataToSend = new FormData();



                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                let res = await axios.put(
                    `${BASE_URL}/detail-design/clientupdate/${formData.id}/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(res)
                if (res.status === 200) {
                    toast.success("Client Updated Successfully");
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    setShow(false);
                    await getClientdetails();
                } else {

                }
            } catch (err) {
                // setShow(false);
                toast.error(err)
                handleErrorToast(err)
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }


    };
    return (
        <>
            <button onClick={handleShow} className="model-edit-button">
                {/* <UpdatePencilWhite /> */}
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update Clients Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log(formData)}
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="name" className="flex-column">
                                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                                    Client Name
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="name"
                                    name="name"
                                    placeholder="Client Name"
                                    onChange={handleInputChange}
                                    value={formData.name}
                                    className={`form-input ${errors.name
                                        ? "error"
                                        : inputState.name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.name && (
                                    <span className="error-message font-size-text ">{errors.name}</span>
                                )}
                            </div>

                            <div title="contact_person" className="flex-column">
                                <label htmlFor="contact_person" className="form-labels font-weight500 font-size-subheading">
                                    Contact Person
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="contact_person"
                                    name="contact_person"
                                    placeholder="Contact Person"
                                    onChange={handleInputChange}
                                    value={formData.contact_person}
                                    className={`form-input ${errors.contact_person
                                        ? "error"
                                        : inputState.contact_person
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.contact_person && (
                                    <span className="error-message font-size-text ">{errors.contact_person}</span>
                                )}
                            </div>
                            <div title="sub_Company" className="flex-column form-group-selectt">
                                <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                                    Sub Company <span className="required">*</span>
                                </label>
                                <br />
                                {sub_CompanyList.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>
                                        <select
                                            id="sub_Company"
                                            name="sub_Company"
                                            value={formData.sub_Company}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                                }`}
                                        >
                                            <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                                            {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                {console.log(i)}
                                                <option value={i.id}>{i.title}</option>
                                            </>))
                                            }
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.sub_Company && (
                                                <span className="error-message font-size-text ">{errors.sub_Company}</span>
                                            )}
                                        </div></>}
                            </div>
                            <div title="address" className="flex-column">
                                <label htmlFor="address" className="form-labels font-weight500 font-size-subheading">
                                    Client Address
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="address"
                                    name="address"
                                    placeholder="Client Address"
                                    onChange={handleInputChange}
                                    value={formData.address}
                                    className={`form-input ${errors.address
                                        ? "error"
                                        : inputState.address
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.address && (
                                    <span className="error-message font-size-text ">{errors.address}</span>
                                )}
                            </div>

                            <div title="email" className="flex-column">
                                <label htmlFor="email" className="form-labels font-weight500 font-size-subheading">
                                    Client Email
                                </label>
                                <input
                                    type="email"
                                    maxLength={100}
                                    id="email"
                                    name="email"
                                    placeholder="Client Email"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                    className={`form-input ${errors.email
                                        ? "error"
                                        : inputState.email
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.email && (
                                    <span className="error-message font-size-text ">{errors.email}</span>
                                )}
                            </div>

                            <div title="contact_number" className="flex-column">
                                <label htmlFor="contact_number" className="form-labels font-weight500 font-size-subheading">
                                    Contact Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="contact_number"
                                    name="contact_number"
                                    placeholder="Client Number"
                                    onChange={handleInputChange}
                                    value={formData.contact_number}
                                    className={`form-input ${errors.contact_number
                                        ? "error"
                                        : inputState.contact_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.contact_number && (
                                    <span className="error-message font-size-text ">{errors.contact_number}</span>
                                )}
                            </div>

                            <div title="alt_contact_number" className="flex-column">
                                <label htmlFor="alt_contact_number" className="form-labels font-weight500 font-size-subheading">
                                    Alternate Contact Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="alt_contact_number"
                                    name="alt_contact_number"
                                    placeholder="Alternate Contact Number"
                                    onChange={handleInputChange}
                                    value={formData.alt_contact_number}
                                    className={`form-input ${errors.alt_contact_number
                                        ? "error"
                                        : inputState.alt_contact_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.alt_contact_number && (
                                    <span className="error-message font-size-text ">{errors.alt_contact_number}</span>
                                )}
                            </div>

                            <div title="gstin_number" className="flex-column">
                                <label htmlFor="gstin_number" className="form-labels font-weight500 font-size-subheading">
                                    Client GST Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="gstin_number"
                                    name="gstin_number"
                                    placeholder="Client GST Number"
                                    onChange={handleInputChange}
                                    value={formData.gstin_number}
                                    className={`form-input ${errors.gstin_number
                                        ? "error"
                                        : inputState.gstin_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.gstin_number && (
                                    <span className="error-message font-size-text ">{errors.gstin_number}</span>
                                )}
                            </div>

                            <div title="pos" className="flex-column">
                                <label htmlFor="pos" className="form-labels font-weight500 font-size-subheading">
                                    Place of Supply (POS)<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="pos"
                                    name="pos"
                                    placeholder="Place of Supply"
                                    onChange={handleInputChange}
                                    value={formData.pos}
                                    className={`form-input ${errors.pos
                                        ? "error"
                                        : inputState.pos
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.pos && (
                                    <span className="error-message font-size-text ">{errors.pos}</span>
                                )}
                            </div>

                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button font-weight500 model-button-submit"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >

        </>
    );
};


const Deleteclientdetails = ({ i, getClientdetails }) => {
    const [show, setShow] = useState(false);
    // console.log(i)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait...");

        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/clientupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getClientdetails();
                setShow(false);
            } else {

            }
        } catch (err) {
            // setShow(false);
            toast.error(err)
            handleErrorToast(err)
        } finally {
            // setLoading(false); //loading logic
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Client of {i.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Client {i.name}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500  model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
// const DownloadAndZip = ({ projectData, fromDate, toDate, sector, structure }) => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState();
//     const [show, setShow] = useState(false);
//     const [documentList,setDocumentList]=useState([]);
//     const handleShow = () => {
//       setShow(true);
//     }
//     const handleClose = () => {
//       setShow(false);
//     }

//     console.log({ fromDate, toDate, sector, structure });

//     const getDocument=async()=>{
//       const res=await axios.get(`${BASE_URL}/siteproject/certificationdownlaodfilter/${fromDate}/${toDate}/${sector}/${structure}/null/`);
//       setDocumentList(res.data);
//     }

//     useEffect(()=>{
//       getDocument();
//     },[fromDate,toDate,sector,structure]);

//     const fetchAndZipFiles = async () => {
//       setLoading(true); // loading logic
//       const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic
//       setError("");

//       try {
//         // Step 1: Fetch the API response



//           const apiResponse = documentList; // Assuming API response is JSON
//           const files = apiResponse.flatMap((project) => project.documents);

//           if (files.length === 0) {
//             setError("No documents found in the API response.");
//             setLoading(false);
//             return;
//           }

//           // Step 3: Initialize JSZip
//           const zip = new JSZip();

//           // Step 4: Fetch each document and add it to the ZIP
//           const filePromises = files.map(async (file) => {
//             try {
//               const fileResponse = await axios.get(file.document_file, {
//                 responseType: "blob",
//               });
//               const fileName = `${file.document_name}.pdf`;
//               zip.file(fileName, fileResponse.data);
//             } catch (err) {
//               console.error(`Failed to download: ${file.document_name}`, err);
//             }
//           });

//           await Promise.all(filePromises);

//           // Step 5: Generate and save the ZIP file
//           const zipBlob = await zip.generateAsync({ type: "blob" });
//           saveAs(zipBlob, "documents.zip");

//           // Close the modal and show success toast
//           handleClose();
//           toast.dismiss(loadingToastId);
//           toast.success("Documents downloaded successfully!");


//       } catch (err) {
//         console.error("Error fetching data or downloading files:", err);
//         setError("Failed to process the request. Please try again.");
//       } finally {
//         setLoading(false);
//         toast.dismiss(loadingToastId);

//       }

//     };



//     return (
//       <>
//         <button
//           title="Download"
//           className="upload-svg"
//           onClick={handleShow}
//         >
//           <DownloadIcon />
//         </button>
//         <div>

//           <Modal
//             show={show}
//             onHide={handleClose}
//             dialogClassName="request-leave"
//           >
//             <Modal.Header closeButton>
//               <Modal.Title>Download Certificates</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <div>
//                 <div style={{ margin: "30px 20px" }}>
//                   <ol>
//                     {documentList.map((project, index) => (
//                       // <li key={index}>
//                       //   {project.project_short_name}

//                       project.documents.map((document, docIndex) => (
//                         <li key={docIndex}>{document.document_name}</li>
//                       ))

//                       // </li>
//                     ))}
//                   </ol>
//                 </div>
//                 <div className='button-models' >
//                   <button
//                     className="model-button   font-weight500"
//                     onClick={handleClose}
//                   >
//                     Cancel
//                   </button>
//                   <button onClick={fetchAndZipFiles} disabled={loading} className="model-button   font-weight500    model-button-cancel">
//                     {loading ? "Downloading..." : "Download Certificate"}
//                   </button>
//                 </div>
//                 {error && <p style={{ color: "red" }}>{error}</p>}
//               </div>
//             </Modal.Body>
//           </Modal>
//           <ToastContainer
//             position="top-center"
//             autoClose={1000}
//             hideProgressBar={false}
//             newestOnTop={true}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//           />
//         </div>
//       </>
//     );
//   };


const TableDesignclient = () => {
    const [mainData, SetMainData] = useState([]);
    const [originalmainData, SetOriginalMainData] = useState([]);

    const getClientdetails = async () => {
        const result = await axios.get(`${BASE_URL}/detail-design/client/`);
        SetMainData(result.data);
        SetOriginalMainData(result.data)
    };
    const [search1, setSearch1] = useState("");
    const [search2, setSearch2] = useState("");
    const applyFilters = () => {
        let filteredData = originalmainData;

        if (search2 !== "") {
            const lowercasesearch1 = search2?.toString().toLowerCase();

            filteredData = filteredData.filter(
                (employee) => {
                    const projectMatch = employee?.gstin_number
                        ?.toString()
                        .includes(lowercasesearch1);

                    return projectMatch;
                }
            );
            console.log("filteredData")
            console.log(filteredData)
            // SetMainData(filteredData)
        }

        if (search1 !== "") {
            const lowercasesearch1 = search1.toLowerCase();

            filteredData = filteredData.filter((employee) => {
                const desiMatch = employee?.name
                    .toLowerCase()
                    .includes(lowercasesearch1);

                return desiMatch;
            });
        }



        SetMainData(filteredData);

    };
    useEffect(() => {
        applyFilters();
    }, [search1, search2, mainData]);
    useEffect(() => {
        getClientdetails();
    }, []);



    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="content-tabs">
                <div className="attendance-subcont">

                    <div className="field-cont max-70-width">



                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-10vw"
                                placeholder="BY Client Name"
                                type="text"
                                value={search1}
                                onChange={(e) => setSearch1(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field  width-10vw"
                                placeholder="By Gst Number"
                                type="text"
                                value={search2}
                                onChange={(e) => setSearch2(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>


                    </div>
                    <div className='btn-cont'>

                        <Addclientdetails getClientdetails={getClientdetails} />
                    </div>
                </div>
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="table-css-white-background table-box scroll-container-table">
                            <div className="table-heading-flex  field-cont-between table-css-white-background">
                                <div className="repo-heading heading-padding-none font-weight500 font-size-heading">
                                    All Clients
                                </div>

                            </div>
                            <table className="table-css">
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr">
                                        <th className="text-center table-heading-text">
                                            Sr no.
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Name
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Sub Company
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Contact Person
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Client Address
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Client Email
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Contact Number
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Alternate Contact Number
                                        </th>
                                        <th className="text-center table-heading-text">
                                            GSTIN Number
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Place of Supply (POS)
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Actions
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {mainData.map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="text-center table-body">
                                                    {index + 1}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.name}
                                                </td>
                                                {/* subcompany_name */}
                                                <td className="text-center table-body">
                                                    {i.subcompany_name}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.contact_person}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.address}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.email}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.contact_number}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.alt_contact_number}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.gstin_number}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.pos}
                                                </td>
                                                <td className="text-center table-body width-5vw">
                                                    <div className="flex-row">  <Updateclientdetails
                                                        i={i}
                                                        getClientdetails={getClientdetails}
                                                    />
                                                        <Deleteclientdetails
                                                            i={i}
                                                            getClientdetails={getClientdetails}
                                                        /></div>

                                                </td>
                                                <td className='text-center table-body'>

                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableDesignclient;
