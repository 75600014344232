
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableDesignssubcompany from './Tabledesignssettings';
import Structureheadingmain from "../Structureheading/Tablestructureheading"
import TableReview from "../ReviewTeam/TableReview"
const SubCompanymain = () => {

    const [toggleState, setToggleState] = useState(1);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "Sub Company";
            case 2:
                return "Structure Heading";
            case 3:
                return "Review Team";
            default:
                return "Sub Company";
        }
    };
    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="Sub Company" name={getSelectedTitle()} />
            <div className="content-tabs ">
                <div
                    className="toggle-button-area flex-row justify-between"
                    style={{ padding: "15px 30px" }}
                >
                    <div>
                        <button
                            className={
                                toggleState === 1
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                            }
                            onClick={() => setToggleState(1)}
                        >
                            Sub Company
                        </button>

                        <button
                            className={
                                toggleState === 2
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                            }
                            onClick={() => setToggleState(2)}
                        >
                            Structure Heading
                        </button>

                        <button
                            className={
                                toggleState === 3
                                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                            }
                            onClick={() => setToggleState(3)}
                        >
                            Team
                        </button>
                    </div>
                    {/* 
                    <div className="flex-row justify-end">
                        {toggleState === 1 ? (
                            <TableDesignssubcompany />
                        ) : (
                            <></>
                        )}
                    </div> */}
                </div>
            </div>
            <>
                {toggleState === 1 ? <TableDesignssubcompany /> : null}
                {toggleState === 2 ? <Structureheadingmain /> : null}
                {toggleState === 3 ? <TableReview /> : null}

            </>

        </>
    );
};


export default SubCompanymain