import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { useLocation, useParams } from "react-router-dom";
// import { BillingDetailsTable, BillTable, ConsultancyDetails, ContractorDetails, COSTable, EmployeeTable, EOTTable, LetterTracking, MilestoneTable, Progress } from "./SiteProjectManagementComponents";
// import { handleErrorToast, formatCurrencyIndian, formatRoundoffCrores, formatRoundoff3D } from "../custom";
import Tabledesign from "../../components/Designstage/Tabledesign";
import TableReviewfile from "../../components/ReviewFile/TableReviewfile";
import { CircularGrid } from "../AllSvg";
// import usePermission from "../../config/permissions";
// import { UpdateProject } from "../Modals/UpdateProjectDetails";
// import { AddProjectClientDetails, ProjectClientDetailsTable, ProjectInvoicesDetailsTable, ProjectInvoicesPaymentsTable, ProjectSubmissionDocumentTable, ProjectTenderDocumentTable } from "./ProjectDetailsPageComponents"
import { UpdateProject } from "../Modals/UpdateProjectDetails";
// import { formattedDateLong } from "../Date";
// import UpdateProjectDetails from "../Modals/UpdateProjectDetails"
const ProjectDetails = () => {
    const location = useLocation();

    console.log(location.state.projectId);
    const projectID = location.state.projectId;
    // console.log("location.state")
    // console.log(location.state)
    // console.log("projectID")
    console.log(projectID);

    const [buffer, setBuffering] = useState(false);

    const [clientslist, setClientsList] = useState([]);
    const [clientExists, setClientExists] = useState(false);

    const [client, setClient] = useState(null);
    const [clientwiseID, setClientWise] = useState(null);

    const [toggleState, settoggleState] = useState(1);

    const [project, setProject] = useState([]);
    const getProjectDetails = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/detail-design/projectupdate/${projectID}/`
            );
            setProject(ress.data);
        } catch (err) { }
    };

    const getProjectClientList = async () => {
        setBuffering(true);
        try {
            const clientdetails = await axios.get(
                `${BASE_URL}/detail-design/client/${projectID}/`
            );
            setClientsList(clientdetails.data);
            setClientExists(clientdetails.data?.normal_client.length > 0);
            settoggleState(
                clientdetails.data?.normal_client.length > 0 ? 1 : 5
            );

            console.log("clientdetails");
            console.log(clientdetails.data?.normal_client?.length > 0);

            setClient(clientdetails.data?.normal_client[0]?.client);
            setClientWise(clientdetails.data?.normal_client[0]?.id);
            if (clientdetails.data?.normal_client?.length > 0) {
            } else {
                setClient(undefined);
                setClientWise(undefined);
            }
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering(false);
        }
    };

    useEffect(() => {
        console.log(projectID);
        getProjectDetails();
        getProjectClientList();
    }, [location]);

    const [allEmployeeData, setAllEmployeeData] = useState([]);

    useEffect(() => {
        getEmployeeDetails();
    }, []);

    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem(
                    "departmentid"
                )}/`
            );
            setAllEmployeeData(res.data);
        } catch (err) {
            alert(err.message);
        }
    };



    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "Design Stage";
            case 2:
                return "Review File";

            default:
                return "Design Stage";
        }
    };
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Dashboardnavbar name={`Project Details`} url="Project Details" />

            <div className="content-tabs">
                <div className="info-box-div-bg">
                    <div className="flex-row">
                        <div className="personal-detail" style={{ width: "100%" }}>
                            <div className="jd-heading-outer heading-bg-color-white" style={{ marginBottom: "10px" }}>
                                <div className="jd-heading-outer-flex">
                                    <h3 className="jd-heading-main font-weight600  font-size-heading">
                                        {/* {console.log(project)} */}
                                        Project name : {project.project_name}
                                    </h3>
                                    <UpdateProject
                                        id={project.pr_code}
                                        modalData={project}
                                        allEmployeeData={allEmployeeData}
                                        getProjectDetails={getProjectDetails}
                                    />
                                </div>

                            </div>

                            <div>
                                <div className="jd-heading-bottom-bold"></div>
                                <div className="jd-heading-bottom-light"></div>
                            </div>
                            <div className="info-box-div">
                                <table>

                                </table>
                            </div>
                            <div className="info-box-div">
                                <div className="personal-detail">
                                    <div>
                                        <table>
                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Pr. Code
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.pr_code ? project.pr_code : "-"}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Project Description
                                                </td>
                                                <th
                                                    className="personal-detail-2 font-weight600 font-size-text"
                                                    style={{ paddingLeft: "70px" }}
                                                >
                                                    {project.project_description ? project.project_description : "-"}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Project Name
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.project_name ? project.project_name : "-"}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Client Name
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.client_name ? project.client_name : "-"}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Location
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.location ? project.location : "-"}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Length (in Km)
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.length ? parseFloat(project.length).toFixed(2) : "-"}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    WO Amount
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.woamount ? parseFloat(project.woamount).toLocaleString() : "-"}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    Without GST Amount
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.without_gst_amount ? parseFloat(project.without_gst_amount).toLocaleString() : "-"}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td className="personal-detail-1 font-weight500 font-size-text">
                                                    GST Amount
                                                </td>
                                                <th className="personal-detail-2 font-weight500 font-size-text">
                                                    {project.gst_amount ? parseFloat(project.gst_amount).toLocaleString() : "-"}
                                                </th>
                                            </tr>

                                        </table>

                                    </div>

                                </div>
                                <div className="perosnal-detail">
                                    <table>


                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Project Mode
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.project_mode ? project.project_mode : "-"}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Concern Person
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.concern_person_name ? project.concern_person_name : "-"}
                                            </th>
                                        </tr>


                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Confirm By
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.confirm_by ? project.confirm_by : "-"}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Confirmation Mode
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.confirmation_mode ? project.confirmation_mode : "-"}
                                            </th>
                                        </tr>

                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Award Date
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.award_date ? new Date(project.award_date).toLocaleDateString() : "-"}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                WO Date
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.wo_date ? new Date(project.wo_date).toLocaleDateString() : "-"}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Start Date
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.start_date ? new Date(project.start_date).toLocaleDateString() : "-"}
                                            </th>
                                        </tr>



                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Due Date
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.due_date ? new Date(project.due_date).toLocaleDateString() : "-"}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Status
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.status ? project.status : "-"}
                                            </th>
                                        </tr>
                                        {/* <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">
                                                Client
                                            </td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.client_name ? project.client_name : "-"}
                                            </th>
                                        </tr> */}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="filter-outer-box">
                    <div className="field-cont">
                        <div title="client Name" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => {
                                    const selectedClient =
                                        e.target.value === "" ? undefined : e.target.value;
                                    const selectedClientId =
                                        clientslist.normal_client?.find(
                                            (client) => client.client === selectedClient
                                        )?.id ||
                                        clientslist.pr_project?.find(
                                            (client) => client.client === selectedClient
                                        )?.id;
                                    setClient(selectedClient);
                                    setClientWise(selectedClientId);
                                }}
                                value={client || ""}
                                className="attendance-input-field date-field"
                            >
                                {clientslist.normal_client?.map((e) => (
                                    <option key={e.client} value={e.client}>
                                        {e.clientname}
                                    </option>
                                ))}
                                {clientslist.pr_project?.map((e) => (
                                    <option key={e.client} value={e.client}>
                                        PR-{e.clientname}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont">

                    </div>
                </div> */}

                {/* Tabs and Content */}
                {
                    <div className="filter-outer-box">
                        <div
                            className="toggle-button-area"
                            style={{ padding: "15px 10px" }}
                        >
                            <div>
                                <button
                                    className={
                                        toggleState === 1
                                            ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                            : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                    }
                                    onClick={() => settoggleState(1)}
                                >
                                    Design Stage
                                </button>

                                <button
                                    className={
                                        toggleState === 2
                                            ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
                                            : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
                                    }
                                    onClick={() => settoggleState(2)}
                                >
                                    Review File
                                </button>


                            </div>
                            {/* 
                    <div className="flex-row justify-end">
                        {toggleState === 1 ? (
                            <TableDesignssubcompany />
                        ) : (
                            <></>
                        )}
                    </div> */}
                        </div>
                    </div>
                }

                <>
                    {toggleState === 1 ? <Tabledesign /> : null}
                    {toggleState === 2 ? <TableReviewfile /> : null}


                </>
            </div>
        </>
    );
};

export default ProjectDetails;
