import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { AddwithWhiteCircle, DropdownArrow, SearchBig, UpdatePencil, PDFIcon } from "../AllSvg";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { ViewEmployeeList, handleErrorToast } from "../CustomFunctions";
import { toast, ToastContainer } from 'react-toastify';
const AddActionlog = ({ getActionlog }) => {
    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
    const [reviewfiles, SetReviewfiles] = useState([]);
    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
            );

            setAllEmployeeData(res.data);
        } catch (err) {
            handleErrorToast(err);
        }
    };
    const getReviewfilesdetails = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/reviewfile/`);
            SetReviewfiles(result.data);
        } catch (err) {
            handleErrorToast(err);
        }

    };
    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState({
        emp_code: sessionStorage.getItem("employee_id"),
        reviewed_files: "",
        action_datetime: '',
        // updated_data: '',
    });


    const handleShow = () => {
        setShow(true);
        getEmployeeDetails()
        getReviewfilesdetails();
    }

    const handleClose = () => {
        setShow(false);
    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;

        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }

    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = [

            "reviewed_files",
            "action_datetime",
            // "updated_data",
        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateform()) {

            const loadingToastId = toast.loading("Loading: Please wait...");
            try {

                const newData = {
                    ...formData,
                    // previous_data: formData,
                    // updated_data: formData,
                }


                console.log("newData")
                console.log(newData)

                let res = await axios.post(
                    `${BASE_URL}/detail-design/actionlogs/`,
                    JSON.stringify((newData)),
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (res.status === 201 || res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getActionlog();
                } else { }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }


    };
    const options = allEmployeeData
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));



    const selectEmployee = (selected) => {
        // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
        const employee = selected?.map((item) => item.value) || [];
        setFormData((prevState) => ({
            ...prevState,
            employee,
        }));
    };


    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add Log
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Action Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">

                        <div title="reviewed_files" className="flex-column form-group-selectt">
                            <label htmlFor="reviewed_files" className="form-labels font-weight500 font-size-subheading">
                                Review File<span className="required">*</span>
                            </label>
                            <br />
                            {reviewfiles.length === 0 ?
                                <div className="form-loader">
                                    <div className="bar-loader"></div>
                                </div>
                                : <>
                                    <select
                                        id="reviewed_files"
                                        name="reviewed_files"
                                        value={formData.reviewed_files}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.reviewed_files ? "error" : inputState.reviewed_files ? "success" : ""}`}
                                    >
                                        <option value="">Select Review File</option>
                                        {reviewfiles?.sort((a, b) => a.project_name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                            <option key={index} value={i.id}>
                                                {i.project_name} {/* Adjusted to show the Action Log name */}
                                            </option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    <div>
                                        {errors.reviewed_files && (
                                            <span className="error-message font-size-text ">{errors.reviewed_files}</span>
                                        )}
                                    </div></>}

                        </div>


                        <div className="flex-column">
                            <label>Action Date time:<span className="required">*</span></label>
                            <input
                                type="datetime-local"
                                name="action_datetime"
                                value={formData.action_datetime}
                                onChange={handleInputChange}
                                className={`form-input ${errors.action_datetime ? 'error' : inputState.action_datetime ? 'success' : ''}`}
                            />
                            {errors.action_datetime && <span className="error-message">{errors.action_datetime}</span>}
                        </div>







                        <div className="button-models" style={{ width: '100%' }}>
                            <button
                                onClick={handleFormSubmit}
                                className="model-button   font-weight500    model-button-submit"
                            //   disabled={loading}
                            >
                                {/* //loading logic */}
                                Add
                            </button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
const UpdateActionlog = ({
    i,
    getActionlog,
}) => {
    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
            );

            setAllEmployeeData(res.data);
        } catch (err) {
            handleErrorToast(err);
        }
    };
    const [show, setShow] = useState(false);
    const [projectList, setProjectlist] = useState([]);
    const [reviewfiles, SetReviewfiles] = useState([]);
    const [formData, setFormData] = useState({
        emp_code: sessionStorage.getItem("employee_id"),
        reviewed_files: "",
        action_datetime: '',
        updated_data: '',
        previous_data: ''
    });
    const formatToDatetimeLocal = (datetime) => {
        const date = new Date(datetime);
        return date.toISOString().slice(0, 19);
    };

    const getReviewfilesdetails = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/reviewfile/`);
            SetReviewfiles(result.data);
        }
        catch (err) {
            handleErrorToast(err);
        }
    };

    const handleShow = () => {
        setShow(true);
        // const { employee_detail, ...formDataWithoutEmployee } = i;
        console.log(i)
        setFormData(i);


        getEmployeeDetails();
        getReviewfilesdetails();
    }
    const options = allEmployeeData
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));



    const selectEmployee = (selected) => {
        // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
        const employee = selected?.map((item) => item.value) || [];
        setFormData((prevState) => ({
            ...prevState,
            employee
        }));
    };

    const handleClose = () => {
        setShow(false);
        setFormData(i);
        getReviewfilesdetails()
    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(files)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue)
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "name",
            "action_datetime",
            "reviewfiles",

        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}


        if (validateform()) {
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const newData = {
                    ...formData,

                    // updated_data: formData,
                }

                let res = await axios.put(
                    `${BASE_URL}/detail-design/actionlogs/${formData.id}/`,
                    newData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (res.status === 201 || res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getActionlog();
                } else {

                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }


    };
    return (
        <>
            <button onClick={handleShow} className="model-edit-button">
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update  Action Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="reviewed_files" className="flex-column form-group-selectt">
                                <label htmlFor="reviewed_files" className="form-labels font-weight500 font-size-subheading">
                                    Review File<span className="required">*</span>
                                </label>
                                <br />
                                {reviewfiles.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>      <select
                                        id="reviewed_files"
                                        name="reviewed_files"
                                        value={formData.reviewed_files}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.reviewed_files ? "error" : inputState.reviewed_files ? "success" : ""}`}
                                    >
                                        <option value="">Select Review File</option>
                                        {reviewfiles?.sort((a, b) => a.project_name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                            <option key={index} value={i.id}>
                                                {i.project_name} {/* Adjusted to show the Action Log name */}
                                            </option>
                                        ))}
                                    </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.reviewed_files && (
                                                <span className="error-message font-size-text ">{errors.reviewed_files}</span>
                                            )}
                                        </div></>}

                            </div>


                            <div className="flex-column">
                                <label>Action Date time:<span className="required">*</span></label>
                                <input
                                    type="datetime-local"
                                    name="action_datetime"
                                    value={formData.action_datetime != "" ? formatToDatetimeLocal(formData.action_datetime) : ""}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.action_datetime ? 'error' : inputState.action_datetime ? 'success' : ''}`}
                                />
                                {errors.action_datetime && <span className="error-message">{errors.action_datetime}</span>}
                            </div>







                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                //   disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
};


const ActionlogDelete = ({ i, getActionlog }) => {
    const [show, setShow] = useState(false);
    // console.log(i)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait...");

        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/actionlogs/${i.id}/`
            );

            if (res.status === 200 || res.status === 201) {
                await getActionlog();
                setShow(false);
            } else {
                // ;
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Action Log of {i.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Action Log of {i.name}
                        <div className="button-models">
                            <button
                                className="model-button font-weight500 model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
// const DownloadAndZip = ({ projectData, fromDate, toDate, sector, structure }) => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState();
//     const [show, setShow] = useState(false);
//     const [documentList,setDocumentList]=useState([]);
//     const handleShow = () => {
//       setShow(true);
//     }
//     const handleClose = () => {
//       setShow(false);
//     }

//     console.log({ fromDate, toDate, sector, structure });

//     const getDocument=async()=>{
//       const res=await axios.get(`${BASE_URL}/siteproject/certificationdownlaodfilter/${fromDate}/${toDate}/${sector}/${structure}/null/`);
//       setDocumentList(res.data);
//     }

//     useEffect(()=>{
//       getDocument();
//     },[fromDate,toDate,sector,structure]);

//     const fetchAndZipFiles = async () => {
//       setLoading(true); // loading logic
//       const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic
//       setError("");

//       try {
//         // Step 1: Fetch the API response



//           const apiResponse = documentList; // Assuming API response is JSON
//           const files = apiResponse.flatMap((project) => project.documents);

//           if (files.length === 0) {
//             setError("No documents found in the API response.");
//             setLoading(false);
//             return;
//           }

//           // Step 3: Initialize JSZip
//           const zip = new JSZip();

//           // Step 4: Fetch each document and add it to the ZIP
//           const filePromises = files.map(async (file) => {
//             try {
//               const fileResponse = await axios.get(file.document_file, {
//                 responseType: "blob",
//               });
//               const fileName = `${file.document_name}.pdf`;
//               zip.file(fileName, fileResponse.data);
//             } catch (err) {
//               console.error(`Failed to download: ${file.document_name}`, err);
//             }
//           });

//           await Promise.all(filePromises);

//           // Step 5: Generate and save the ZIP file
//           const zipBlob = await zip.generateAsync({ type: "blob" });
//           saveAs(zipBlob, "documents.zip");

//           // Close the modal and show success toast
//           handleClose();
//           toast.dismiss(loadingToastId);
//           toast.success("Documents downloaded successfully!");


//       } catch (err) {
//         console.error("Error fetching data or downloading files:", err);
//         setError("Failed to process the request. Please try again.");
//       } finally {
//         setLoading(false);
//         toast.dismiss(loadingToastId);

//       }

//     };



//     return (
//       <>
//         <button
//           title="Download"
//           className="upload-svg"
//           onClick={handleShow}
//         >
//           <DownloadIcon />
//         </button>
//         <div>

//           <Modal
//             show={show}
//             onHide={handleClose}
//             dialogClassName="request-leave"
//           >
//             <Modal.Header closeButton>
//               <Modal.Title>Download Certificates</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <div>
//                 <div style={{ margin: "30px 20px" }}>
//                   <ol>
//                     {documentList.map((project, index) => (
//                       // <li key={index}>
//                       //   {project.project_short_name}

//                       project.documents.map((document, docIndex) => (
//                         <li key={docIndex}>{document.document_name}</li>
//                       ))

//                       // </li>
//                     ))}
//                   </ol>
//                 </div>
//                 <div className='button-models' >
//                   <button
//                     className="model-button   font-weight500"
//                     onClick={handleClose}
//                   >
//                     Cancel
//                   </button>
//                   <button onClick={fetchAndZipFiles} disabled={loading} className="model-button   font-weight500    model-button-cancel">
//                     {loading ? "Downloading..." : "Download Certificate"}
//                   </button>
//                 </div>
//                 {error && <p style={{ color: "red" }}>{error}</p>}
//               </div>
//             </Modal.Body>
//           </Modal>
//           <ToastContainer
//             position="top-center"
//             autoClose={1000}
//             hideProgressBar={false}
//             newestOnTop={true}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//           />
//         </div>
//       </>
//     );
//   };


const TableActionlog = () => {
    const [mainData, SetMainData] = useState([]);
    const getActionlog = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/actionlogs/`);
            SetMainData(result.data);
        } catch (err) {
            handleErrorToast(err);
        }

    };

    useEffect(() => {
        getActionlog();

    }, []);


    return (
        <>
            <div className="content-tabs">
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="table-css-white-background table-box scroll-container-table">
                            <div className="table-heading-flex  field-cont-between table-css-white-background">
                                <div className="repo-heading heading-padding-none font-weight500 font-size-heading">
                                    Action Log
                                </div>
                                <AddActionlog getActionlog={getActionlog} />
                            </div>
                            <table className="table-css">
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr">
                                        <th className="table-heading-text">
                                            Sr no.
                                        </th>
                                        <th className="table-heading-text">
                                            Employee Code
                                        </th>
                                        <th className="table-heading-text">
                                            Reviewed Files
                                        </th>
                                        <th className="table-heading-text">
                                            Action Date and Time
                                        </th>
                                        <th className="table-heading-text">
                                            Updated Data
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mainData.map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body">
                                                    {index + 1}
                                                </td>
                                                <td className="table-body">
                                                    {i.emp_code}
                                                </td>
                                                <td className="table-body">
                                                    {/* <ul>
                                                        {i.reviewed_files?.map((file, fileIndex) => (
                                                            <li key={fileIndex}>{file}</li>
                                                        ))}
                                                    </ul> */}
                                                    {i.project_name}
                                                </td>
                                                <td className="table-body">
                                                    {new Date(i.action_datetime).toLocaleString()}
                                                </td>
                                                <td className="table-body">
                                                    {/* {i.updated_data} */}
                                                </td>
                                                <td className="text-center table-body width-5vw">
                                                    <div className="flex-row">
                                                        <UpdateActionlog
                                                            i={i}
                                                            getActionlog={getActionlog}
                                                        />
                                                        <ActionlogDelete
                                                            i={i}
                                                            getActionlog={getActionlog}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableActionlog;
