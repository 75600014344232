import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { AddwithWhiteCircle, DropdownArrow, SearchBig, DownloadIcon, PDFIcon, UpdatePencil, Attachment, CircularGrid, DeleteDustbin, Eye } from "../AllSvg";
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getFileNameFromLink } from "../CustomFunctions";
import { handleErrorToast } from "../CustomFunctions";
import { DocumentSection } from "../FormdataComponent";
// import {getFileNameFromLink} 
// import "jspdf-autotable";
const Adddetailsdesign = ({ getDetailsdesign }) => {
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const [projectList, setProjectlist] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        department: '',
        depth_detail: '',
        files: [{ document_name: "", file: "" }],
        external_photo: '',
        height_detail: "",
        is_internal_source: true,
        length: '',
        project: '',
        remark_text: '',
        source_name: '',
        sub_company: '',
        type: '',
        width_detail: '',
        lengthUnit: '',
        widthUnit: '',
        depthUnit: '',
        heightUnit: '',
    })
    const getSubCompany = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setSiteSubCompanyList(sub.data);
        } catch (err) {

        } finally {

        }
    };
    const getAllproject = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/allprojectfilter/null/`);
            setProjectlist(sub.data);
        } catch (err) {

        } finally {

        }
    };
    useEffect(() => {
        if (show) {
            getAllproject(null);
        }
    }, [formData.project, show])
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
        setFileNames(() => {
            return new Array(fileNames.length).fill('');
        });
        setErrors({});
    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;

        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }



        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));

        if (name === "is_internal_source") {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                    source_name: '',
                    external_photo: '',
                };

                return updatedFormData;
            });
        }
        else {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                };

                return updatedFormData;
            });
        }
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "department",
            "type",

        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        const dimensions = [
            { valueField: 'length', unitField: 'lengthUnit' },
            { valueField: "width_detail", unitField: 'widthUnit' },
            { valueField: "height_detail", unitField: 'heightUnit' },
            { valueField: "depth_detail", unitField: 'depthUnit' },
        ];

        dimensions.forEach(({ valueField, unitField }) => {
            if (formData[valueField] && !formData[unitField]) {
                newErrors[unitField] = `${unitField.charAt(0).toUpperCase() + unitField.slice(1)} is required when ${valueField} is provided!`;
            }
        });
        formData?.files?.length > 0 && formData?.files?.forEach((doc, index) => {
            if (!doc.document_name || doc.document_name.trim() === "") {
                newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
            }

            if (!doc.document_file) {
                newErrors[`file_${index}`] = `Document File is required for document ${index + 1}`;
            }
        });
        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };

    const generateRandomString = () => {
        const chars =
            "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from(
            { length: 8 },
            () => chars[Math.floor(Math.random() * chars.length)]
        ).join("");
    };
    const createCustomFile = (originalFile) => {
        const randomCode = generateRandomString();
        const now = new Date();
        const date = now.toISOString().split("T")[0];
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
        const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
        return new File([originalFile], customFileName, {
            type: originalFile.type,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}


        if (validateform()) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            setLoading(true)
            try {
                const formDataToSend = new FormData();
                formData?.files?.forEach((doc, index) => {


                    if (doc.document_file) {
                        // Assuming 'doc.file' is the file object and 'doc.document_name' is the name of the document
                        const customFile = createCustomFile(doc.document_file);  // Assuming this function processes the file

                        // Correcting the syntax and appending the file and document name
                        formDataToSend.append(`documents[${index}][document_name]`, doc.document_name);
                        formDataToSend.append(`documents[${index}][document_file]`, customFile);
                    }
                });


                Object.keys(formData).forEach((key) => {
                    if (key !== "drawing" && key !== "external_photo" && key !== "is_internal_source" && formData[key] && key !== "files" && key !== "length" && key !== "height" && key !== "width_detail" && key !== "depth_detail" && key !== "lengthUnit" && key !== "heightUnit" && key !== "depthUnit" && key !== "widthUnit") {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                const combinedLength = `${formData.length} ${formData.lengthUnit}`;
                const combinedHieght = `${formData.height_detail} ${formData.heightUnit}`;
                const combinedwidth = `${formData.width_detail} ${formData.widthUnit}`;
                const combineddepth = `${formData.depth_detail} ${formData.depthUnit}`

                formDataToSend.append("length", combinedLength)
                formDataToSend.append("height_detail", combinedHieght)
                formDataToSend.append("width_detail", combinedwidth)
                formDataToSend.append("depth_detail", combineddepth)
                const symbols = '!@#$%^&*()_-+=';
                const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
                const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                const numbers = '0123456789';
                const now = new Date();
                const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-');
                const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-');
                const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
                let randomCode = '';
                for (let i = 0; i < 8; i++) {
                    const randomIndex = Math.floor(Math.random() * allChars.length);
                    randomCode += allChars[randomIndex];
                }


                const originalFile2 = formData.external_photo;
                const customFileName2 = `Aimantra ${date} ${time}_${randomCode} ${originalFile2.name}`;
                const customFile2 = new File([originalFile2], customFileName2, { type: originalFile2.type });

                formDataToSend.append("remark_text", formData.remark_text);
                formDataToSend.append("is_internal_source", formData.is_internal_source ? true : false);
                formDataToSend.append("source_name", formData.source_name);



                if (!formData.is_internal_source && formData.external_photo) {
                    formDataToSend.append("external_photo", customFile2);
                }



                let res = await axios.post(
                    `${BASE_URL}/detail-design/innovationbulkdocument/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200) {

                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    setFileNames(() => {
                        return new Array(fileNames.length).fill('');
                    });
                    setErrors({})
                    setLoading(false)
                    await getDetailsdesign();
                } else { }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }


    };

    const handleDocInputChange = (index, e) => {
        const { name, value, files } = e.target;

        setFormData((prevFormData) => {
            const newfiles = [...prevFormData.files];
            newfiles[index] = {
                ...newfiles[index],
                [name]: files ? files[0] : value, // Removed .trim()
            };

            if (files) {
                setFileNames((prevFileNames) => {
                    const newFileNames = [...prevFileNames];
                    newFileNames[index] = files[0]?.name || "";
                    return newFileNames;
                });
            }

            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name === "document_name" && value.trim()) {
                    delete updatedErrors[`document_name_${index}`];
                }
                if (name === "file" && files?.[0]) {
                    delete updatedErrors[`file_${index}`];
                }
                return updatedErrors;
            });
            if (value.trim()) {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[name];
                    return updatedErrors;
                });
            }
            return { ...prevFormData, files: newfiles };
        });
    };

    const handleAddfiles = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: [
                ...prevFormData.files,
                { document_name: "", file: null },
            ],
        }));
    };


    const handleRemoveDocument = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index),
        }));
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, i) => i !== index)
        );
    };

    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add Innovation
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Innovation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="department" className="flex-column">
                                <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                                    Department Name <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="department"
                                    name="department"
                                    placeholder="Department Name"
                                    onChange={handleInputChange}
                                    value={FormData.department}
                                    className={`form-input ${errors.department
                                        ? "error"
                                        : inputState.department
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.department && (
                                    <span className="error-message font-size-text ">{errors.department}</span>
                                )}
                            </div>
                            <div title="type" className="flex-column">
                                <label htmlFor="type" className="form-labels  font-weight500    font-size-subheading">
                                    Type <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="type"
                                    name="type"
                                    placeholder="Type"
                                    onChange={handleInputChange}
                                    value={FormData.type}
                                    className={`form-input ${errors.type
                                        ? "error"
                                        : inputState.type
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.type && (
                                    <span className="error-message font-size-text ">{errors.type}</span>
                                )}
                            </div>
                            <div title="project" className="flex-column form-group-selectt">
                                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                                    Project
                                </label>
                                <br />
                                {projectList.length === 0 ? (
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                ) : (
                                    <>
                                        <select
                                            id="project"
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                                                }`}
                                        >
                                            <option value="">Select a Project</option>
                                            {projectList?.sort((a, b) => a.project_name.localeCompare(b.project_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                                                <option value={i.pr_code}>{i.project_name}</option>
                                            </>))
                                            }
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.project && (
                                                <span className="error-message font-size-text ">{errors.project}</span>
                                            )}
                                        </div>
                                    </>)}
                            </div>
                            <div title="length" className="flex-column">
                                <label htmlFor="length" className="form-labels  font-weight500    font-size-subheading">
                                    Length
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"

                                        id="length"
                                        name="length"
                                        placeholder="Length"
                                        onChange={handleInputChange}
                                        value={formData.length}

                                        className={`form-input ${errors.length
                                            ? "error"
                                            : inputState.length
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="lengthUnit"
                                        name="lengthUnit"
                                        onChange={handleInputChange}
                                        value={formData.lengthUnit}
                                        className={`form-input  ${errors.lengthUnit ? "error" : inputState.lengthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>
                                {errors.lengthUnit && (
                                    <span className="error-message font-size-text ">{errors.lengthUnit}</span>
                                )}
                            </div>
                            <div title="height_detail" className="flex-column">
                                <label htmlFor="height_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Height
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="height_detail"
                                        name="height_detail"
                                        placeholder="Height"
                                        onChange={handleInputChange}
                                        value={formData.height_detail}
                                        className={`form-input ${errors.height_detail
                                            ? "error"
                                            : inputState.height_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="heightUnit"
                                        name="heightUnit"
                                        onChange={handleInputChange}
                                        value={formData.heightUnit}
                                        className={`form-input  ${errors.heightUnit ? "error" : inputState.heightUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>
                                {errors.heightUnit && (
                                    <span className="error-message font-size-text ">{errors.heightUnit}</span>
                                )}
                            </div>
                            <div title="depth_detail" className="flex-column">
                                <label htmlFor="depth_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Depth
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="depth_detail"
                                        name="depth_detail"
                                        placeholder="Depth"
                                        onChange={handleInputChange}
                                        value={formData.depth_detail}
                                        className={`form-input ${errors.depth_detail
                                            ? "error"
                                            : inputState.depth_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="depthUnit"
                                        name="depthUnit"
                                        onChange={handleInputChange}
                                        value={formData.depthUnit}
                                        className={`form-input  ${errors.depthUnit ? "error" : inputState.depthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>

                                {errors.depthUnit && (
                                    <span className="error-message font-size-text ">{errors.depthUnit}</span>
                                )}
                            </div>
                            <div title="width_detail" className="flex-column">
                                <label htmlFor="width_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Width
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="width_detail"
                                        name="width_detail"
                                        placeholder="Width"
                                        onChange={handleInputChange}
                                        value={formData.width_detail}
                                        className={`form-input ${errors.width
                                            ? "error"
                                            : inputState.width_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="widthUnit"
                                        name="widthUnit"
                                        onChange={handleInputChange}
                                        value={formData.widthUnit}
                                        className={`form-input  ${errors.widthUnit ? "error" : inputState.widthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>

                                {errors.widthUnit && (
                                    <span className="error-message font-size-text ">{errors.widthUnit}</span>
                                )}
                            </div>
                            <div title="remark_text" className="flex-column">
                                <label htmlFor="remark_text" className="form-labels  font-weight500    font-size-subheading">
                                    Remarks or Comments
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="remark_text"
                                    name="remark_text"
                                    placeholder="Remarks or Comments"
                                    onChange={handleInputChange}
                                    value={FormData.remark_text}
                                    className={`form-input ${errors.remark_text
                                        ? "error"
                                        : inputState.remark_text
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.remark_text && (
                                    <span className="error-message font-size-text ">{errors.remark_text}</span>
                                )}
                            </div>

                            <div title="is_internal_source" className="flex-column" style={{ width: '100%' }}>
                                <div className="flex-row">
                                    <label htmlFor="is_internal_source" className="form-labels  font-weight500    font-size-subheading">
                                        {formData.is_internal_source ? "Internal" : "External "} Source {" "}
                                    </label>
                                    <hr className="field-cont-hr" />
                                    {/* <hr className="field-cont-hr" /> */}
                                    <div className="checkbox-toggle">
                                        <input
                                            id="toggle1"
                                            name="is_internal_source"
                                            type="checkbox"
                                            value={formData.is_internal_source}
                                            className={`form-checkbox ${errors.is_internal_source ? 'error' : inputState.is_internal_source ? 'success' : ''}`}
                                            onChange={handleInputChange}
                                            checked={formData.is_internal_source}
                                        />
                                        <label htmlFor="toggle1"></label>
                                        {errors.is_internal_source && (
                                            <span className="error-message font-size-text ">{errors.is_internal_source}</span>
                                        )}
                                    </div>
                                </div>

                            </div>

                            {formData.is_internal_source ?
                                <div title="source_name" className="flex-column">
                                    <label htmlFor="source_name" className="form-labels  font-weight500    font-size-subheading">
                                        Name of the source.
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="source_name"
                                        name="source_name"
                                        placeholder="Name of the source."
                                        onChange={handleInputChange}
                                        value={FormData.source_name}
                                        className={`form-input ${errors.source_name
                                            ? "error"
                                            : inputState.source_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.source_name && (
                                        <span className="error-message font-size-text ">{errors.source_name}</span>
                                    )}
                                </div>
                                : (<>
                                    <div title="external_photo" className="flex-column">
                                        <label htmlFor="external_photo" className="form-labels  font-weight500    font-size-subheading">
                                            Upload field for external photos
                                        </label>
                                        <input
                                            type="file"
                                            maxLength={100}
                                            id="external_photo"
                                            name="external_photo"
                                            placeholder="Upload field for external photos"
                                            onChange={handleInputChange}
                                            value={FormData.external_photo}
                                            accept=".png, .jpeg, .jpg"
                                            className={`form-input ${errors.external_photo
                                                ? "error"
                                                : inputState.external_photo
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.external_photo && (
                                            <span className="error-message font-size-text ">{errors.external_photo}</span>
                                        )}
                                    </div></>)}

                            <DocumentSection
                                formData={formData}
                                handleDocInputChange={handleDocInputChange}
                                handleAddfiles={handleAddfiles}
                                handleRemoveDocument={handleRemoveDocument}
                                errors={errors}
                                fileNames={fileNames}
                                inputState={inputState}
                            />
                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                    disabled={loading}
                                >

                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
const Updatedetailsdesign = ({
    i,
    getDetailsdesign,
}) => {

    const [show, setShow] = useState(false);
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [projectList, setProjectlist] = useState([]);
    const [formData, setFormData] = useState({
        department: '',
        depth_detail: '',
        files: [{ document_name: "", file: "" }],
        external_photo: '',
        height_detail: "",
        is_internal_source: '',
        length: '',
        project: '',
        remark_text: '',
        source_name: '',
        sub_company: '',
        type: '',
        width_detail: '',
        lengthUnit: '',
        widthUnit: '',
        depthUnit: '',
        heightUnit: '',
    })
    const getSubCompany = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setSiteSubCompanyList(sub.data);
        } catch (err) {

        } finally {

        }
    };
    useEffect(() => {
        if (show) {
            getSubCompany();
        }

    }, [formData.sub_company, show])
    const getAllproject = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/allprojectfilter/null`);
            setProjectlist(sub.data);
        } catch (err) {

        } finally {

        }
    };
    useEffect(() => {
        if (show) {
            getAllproject(null);
        }
    }, [formData.project, show])
    const handleClose = () => {
        setFileNames(() => {
            return new Array(fileNames.length).fill('');
        });
        setErrors({});
        setShow(false);
        setAddDocumentation(false);
    };

    const handleShow = () => {
        setShow(true);
        const filteredData = { ...i };
        delete filteredData.external_photo;



        const extractUnit = (value) => {

            const regex = /(\d+)\s*(\w+)/;
            const match = value.match(regex);
            if (match) {
                return { value: parseFloat(match[1]), unit: match[2] };
            }
            return { value: '', unit: '' };
        };


        const length = extractUnit(i.length || '');
        const width = extractUnit(i.width_detail || '');
        const depth = extractUnit(i.depth_detail || '');
        const height = extractUnit(i.height_detail || '');

        setFormData({
            ...filteredData,
            files: i.innovation || [],

            length: length.value,
            lengthUnit: length.unit,
            width_detail: width.value,
            widthUnit: width.unit,
            depth_detail: depth.value,
            depthUnit: depth.unit,
            height_detail: height.value,
            heightUnit: height.unit
        });
    };

    const [addDocumentation, setAddDocumentation] = useState(false);
    const [formDataDoc, setFormDataDoc] = useState({
        files: [
            {
                document_name: null,
                file: "",
            },
        ],
    });

    const handleDocInputChange = (index, e) => {
        const { name, value, files } = e.target;

        setFormDataDoc((prevFormData) => {

            const newfiles = [...prevFormData.files];
            newfiles[index] = {
                ...newfiles[index],
                [name]: files ? files[0] : value,
            };

            if (files) {
                setFileNames((prevFileNames) => {
                    const newFileNames = [...prevFileNames];
                    newFileNames[index] = files[0]?.name || "";
                    return newFileNames;
                });
            }

            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name === "document_name" && value.trim()) {
                    delete updatedErrors[`document_name_${index}`];
                }
                if (name === "file" && files?.[0]) {
                    delete updatedErrors[`file_${index}`];
                }
                return updatedErrors;
            });
            if (value.trim()) {
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    delete updatedErrors[name];
                    return updatedErrors;
                });
            }
            return {
                ...prevFormData,
                files: newfiles
            };
        });
    };

    const handleAddfiles = () => {

        if (addDocumentation) {
            setFormDataDoc((prevFormData) => ({
                ...prevFormData,
                files: [
                    ...prevFormData.files,
                    { document_name: "", file: null },
                ],
            }));
        } else {
            setAddDocumentation(true)
        }

    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;

        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }


        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "department",
            "type",

        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });
        const dimensions = [
            { valueField: 'length', unitField: 'lengthUnit' },
            { valueField: "width_detail", unitField: 'widthUnit' },
            { valueField: "height_detail", unitField: 'heightUnit' },
            { valueField: "depth_detail", unitField: 'depthUnit' },
        ];

        dimensions.forEach(({ valueField, unitField }) => {
            if (formData[valueField] && !formData[unitField]) {
                newErrors[unitField] = `${unitField.charAt(0).toUpperCase() + unitField.slice(1)} is required when ${valueField} is provided!`;
            }
        });

        formDataDoc?.files.length > 0 && addDocumentation &&
            formDataDoc?.files?.forEach((doc, index) => {
                if (!doc.document_name || doc.document_name.trim() === "") {
                    newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
                }
                console.log(doc)
                if (!doc.file) {
                    newErrors[`file_${index}`] = `Document File is required for document ${index + 1}`;
                }
            });


        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleRemoveDocument = (index) => {
        if (formDataDoc.length === 1) {
            setAddDocumentation(false)
        }
        setFormDataDoc((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index),
        }));
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, i) => i !== index)
        );
    };

    const generateRandomString = () => {
        const chars =
            "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from(
            { length: 8 },
            () => chars[Math.floor(Math.random() * chars.length)]
        ).join("");
    };
    const createCustomFile = (originalFile) => {
        const randomCode = generateRandomString();
        const now = new Date();
        const date = now.toISOString().split("T")[0];
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
        const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
        return new File([originalFile], customFileName, {
            type: originalFile.type,
        });
    };

    const [loading, setLoading] = useState(false)
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}

        if (validateform()) {

            const loadingToastId = toast.loading("Loading: Please wait...");
            setLoading(true);
            try {
                const formDataToSend = new FormData();





                Object.keys(formData).forEach((key) => {
                    if (key !== "drawing" && key !== "external_photo" && key !== "is_internal_source" && formData[key] && key !== "files" && key !== "length" && key !== "height" && key !== "width_detail" && key !== "depth_detail" && key !== "widthUnit " && key !== "heightUnit" && key !== "depthUnit" && key !== "lengthUnit" && key !== "innovation") {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                formDataDoc?.files?.forEach((doc, index) => {



                    if (doc.file) {

                        const customFile = createCustomFile(doc.file);


                        formDataToSend.append(`documents[${index}][document_name]`, doc.document_name);
                        formDataToSend.append(`documents[${index}][document_file]`, customFile);
                    }
                });



                const symbols = '!@#$%^&*()_-+=';
                const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
                const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                const numbers = '0123456789';
                const now = new Date();
                const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-');
                const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-');
                const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
                let randomCode = '';
                for (let i = 0; i < 8; i++) {
                    const randomIndex = Math.floor(Math.random() * allChars.length);
                    randomCode += allChars[randomIndex];
                }

                let originalFile2 = ""
                if (formData.external_photo) {
                    originalFile2 = formData?.external_photo;
                }

                const customFileName2 = `Aimantra ${date} ${time}_${randomCode} ${originalFile2.name}`;
                const customFile2 = new File([originalFile2], customFileName2, { type: originalFile2.type });

                formDataToSend.append("remark_text", formData.remark_text);
                formDataToSend.append("is_internal_source", formData.is_internal_source === "" ? true : false);
                formDataToSend.append("source_name", formData.source_name);
                const combinedLength = `${formData.length} ${formData.lengthUnit}`;
                const combinedHieght = `${formData.height_detail} ${formData.heightUnit}`;
                const combinedwidth = `${formData.width_detail} ${formData.widthUnit}`;
                const combineddepth = `${formData.depth_detail} ${formData.depthUnit}`

                formDataToSend.append("length", combinedLength)
                formDataToSend.append("height_detail", combinedHieght)
                formDataToSend.append("width_detail", combinedwidth)
                formDataToSend.append("depth_detail", combineddepth)


                if (!formData.is_internal_source && formData.external_photo) {
                    formDataToSend.append("external_photo", customFile2);
                }

                let res = await axios.put(
                    `${BASE_URL}/detail-design/documentbulkinnovationupdate/${formData.id}/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    setFileNames(() => {
                        return new Array(fileNames.length).fill('');
                    });
                    setFormDataDoc(() => ({

                        files: [

                            { document_name: "", file: null },
                        ],
                    }));
                    setAddDocumentation(false);
                    await getDetailsdesign();
                } else { }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }


    };


    const handlePicDelete = async (e, i, getProjects) => {
        e.preventDefault();

        // setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait...");

        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/documentupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getProjects();
                setFormData((prevFormData) => {
                    const updatedFiles = prevFormData.files.filter((doc) => doc.id !== i.id);
                    return { ...prevFormData, files: updatedFiles };
                })
                // toast.success("Document deleted Successfully");
                // toast.dismiss(loadingToastId);

            } else {

            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            //   setLoading(false); // loading logic
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button onClick={handleShow} className="upload-svg">
                View/Edit
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update Innovation  Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="department" className="flex-column">
                                <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                                    Department Name <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="department"
                                    name="department"
                                    placeholder="Department Name"
                                    onChange={handleInputChange}
                                    value={formData.department}
                                    className={`form-input ${errors.department
                                        ? "error"
                                        : inputState.department
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.department && (
                                    <span className="error-message font-size-text ">{errors.department}</span>
                                )}
                            </div>
                            <div title="type" className="flex-column">
                                <label htmlFor="type" className="form-labels  font-weight500    font-size-subheading">
                                    Type <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="type"
                                    name="type"
                                    placeholder="Type"
                                    onChange={handleInputChange}
                                    value={formData.type}
                                    className={`form-input ${errors.type
                                        ? "error"
                                        : inputState.type
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.type && (
                                    <span className="error-message font-size-text ">{errors.type}</span>
                                )}
                            </div>
                            <div title="project" className="flex-column form-group-selectt">
                                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                                    Project
                                </label>
                                <br />
                                {projectList.length === 0 ? (
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                ) : (
                                    <>
                                        <select
                                            id="project"
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                                                }`}
                                        >
                                            <option value="">Select a Project</option>
                                            {projectList?.sort((a, b) => a.project_name.localeCompare(b.project_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                                                <option value={i.pr_code}>{i.project_name}</option>
                                            </>))
                                            }
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.project && (
                                                <span className="error-message font-size-text ">{errors.project}</span>
                                            )}
                                        </div>
                                    </>)}


                            </div>
                            <div title="length" className="flex-column">
                                <label htmlFor="length" className="form-labels  font-weight500    font-size-subheading">
                                    Length
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"

                                        id="length"
                                        name="length"
                                        placeholder="Length"
                                        onChange={handleInputChange}
                                        value={formData.length}

                                        className={`form-input ${errors.length
                                            ? "error"
                                            : inputState.length
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="lengthUnit"
                                        name="lengthUnit"
                                        onChange={handleInputChange}
                                        value={formData.lengthUnit}
                                        className={`form-input  ${errors.lengthUnit ? "error" : inputState.lengthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>
                                {errors.lengthUnit && (
                                    <span className="error-message font-size-text ">{errors.lengthUnit}</span>
                                )}
                            </div>

                            <div title="height_detail" className="flex-column">
                                <label htmlFor="height_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Hieght
                                </label>

                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="height_detail"
                                        name="Height"
                                        placeholder="Height"
                                        onChange={handleInputChange}
                                        value={formData.height_detail}
                                        className={`form-input ${errors.height_detail
                                            ? "error"
                                            : inputState.height_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="heightUnit"
                                        name="heightUnit"
                                        onChange={handleInputChange}
                                        value={formData.heightUnit}
                                        className={`form-input  ${errors.heightUnit ? "error" : inputState.heightUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>
                                {errors.heightUnit && (
                                    <span className="error-message font-size-text ">{errors.heightUnit}</span>
                                )}
                            </div>
                            <div title="depth_detail" className="flex-column">
                                <label htmlFor="depth_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Depth
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="depth_detail"
                                        name="depth_detail"
                                        placeholder="Depth"
                                        onChange={handleInputChange}
                                        value={formData.depth_detail}
                                        className={`form-input ${errors.depth_detail
                                            ? "error"
                                            : inputState.depth_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="depthUnit"
                                        name="depthUnit"
                                        onChange={handleInputChange}
                                        value={formData.depthUnit}
                                        className={`form-input  ${errors.depthUnit ? "error" : inputState.depthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>

                                {errors.depthUnit && (
                                    <span className="error-message font-size-text ">{errors.depthUnit}</span>
                                )}
                            </div>
                            <div title="width_detail" className="flex-column">
                                <label htmlFor="width_detail" className="form-labels  font-weight500    font-size-subheading">
                                    Width
                                </label>
                                <div className="flex flexchooseunit">
                                    <input
                                        type="text"
                                        id="width"
                                        name="width_detail"
                                        placeholder="Width"
                                        onChange={handleInputChange}
                                        value={formData.width_detail}
                                        className={`form-input ${errors.width_detail
                                            ? "error"
                                            : inputState.width_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    <select
                                        id="widthUnit"
                                        name="widthUnit"
                                        onChange={handleInputChange}
                                        value={formData.widthUnit}
                                        className={`form-input  ${errors.widthUnit ? "error" : inputState.widthUnit ? "success" : ""}`}
                                    >
                                        <option value="">Select Unit</option>
                                        <option value="kilometer">Kilo meter</option>
                                        <option value="meter">Meter</option>
                                    </select>
                                </div>

                                {errors.widthUnit && (
                                    <span className="error-message font-size-text ">{errors.widthUnit}</span>
                                )}
                            </div>
                            <div title="remark_text" className="flex-column">
                                <label htmlFor="remark_text" className="form-labels  font-weight500    font-size-subheading">
                                    Remarks or Comments
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="remark_text"
                                    name="remark_text"
                                    placeholder="Remarks or Comments"
                                    onChange={handleInputChange}
                                    value={formData.remark_text}
                                    className={`form-input ${errors.remark_text
                                        ? "error"
                                        : inputState.remark_text
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.remark_text && (
                                    <span className="error-message font-size-text ">{errors.remark_text}</span>
                                )}
                            </div>
                            {/* <div title="drawing" className="flex-column">
                                <label htmlFor="drawing" className="form-labels  font-weight500    font-size-subheading">
                                    Upload field for drawing files
                                </label>
                                {formData.drawing && (
                                    <div>
                                        <span>{formData.drawing.name}</span>

                                        {formData.drawing && (
                                            <img src={formData.drawing} alt="Drawing Preview" width={100} />
                                        )}
                                    </div>
                                )}
                                <input
                                    type="file"
                                    maxLength={100}
                                    id="drawing"
                                    name="drawing"
                                    placeholder="Drawing"
                                    onChange={handleInputChange}
                                    // value={formData.drawing}
                                    className={`form-input ${errors.drawing
                                        ? "error"
                                        : inputState.drawing
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.drawing && (
                                    <span className="error-message font-size-text ">{errors.drawing}</span>
                                )}
                            </div> */}
                            <div title="is_internal_source" className="flex-column" style={{ width: '100%' }}>
                                <div className="flex-row">
                                    <label htmlFor="is_internal_source" className="form-labels  font-weight500    font-size-subheading">
                                        {/* Indicates if the source is internal  {" "} */}
                                        {formData.is_internal_source ? "Internal" : "External "} Source {" "}
                                    </label>
                                    <hr className="field-cont-hr" />
                                    <div className="checkbox-toggle">
                                        <input
                                            id="toggle1"
                                            name="is_internal_source"
                                            type="checkbox"
                                            value={formData.is_internal_source}
                                            className={`form-checkbox ${errors.is_internal_source ? 'error' : inputState.is_internal_source ? 'success' : ''}`}
                                            onChange={handleInputChange}
                                            checked={formData.is_internal_source}
                                        />
                                        <label htmlFor="toggle1"></label>
                                    </div>
                                </div>

                            </div>
                            {formData.is_internal_source ?
                                <div title="source_name" className="flex-column">
                                    <label htmlFor="source_name" className="form-labels  font-weight500    font-size-subheading">
                                        Name of the source.
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="source_name"
                                        name="source_name"
                                        placeholder="Name of the source."
                                        onChange={handleInputChange}
                                        value={formData.source_name}
                                        className={`form-input ${errors.source_name
                                            ? "error"
                                            : inputState.source_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.source_name && (
                                        <span className="error-message font-size-text ">{errors.source_name}</span>
                                    )}
                                </div>
                                : (<>


                                    <div title="external_photo" className="flex-column">
                                        <label htmlFor="external_photo" className="form-labels  font-weight500    font-size-subheading">
                                            Upload field for external photos
                                        </label>

                                        <input
                                            type="file"
                                            maxLength={100}
                                            id="external_photo"
                                            name="external_photo"
                                            placeholder="Upload field for external photos"
                                            onChange={handleInputChange}

                                            accept="image/*"
                                            className={`form-input ${errors.external_photo
                                                ? "error"
                                                : inputState.external_photo
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.external_photo && (
                                            <span className="error-message font-size-text ">{errors.external_photo}</span>
                                        )}
                                    </div>
                                </>)}

                            <div style={{ width: '100%' }}>
                                <div className="jd-heading-outer heading-bg-color-white">
                                    <div className="jd-heading-outer-flex">
                                        <h2 className="jd-heading-main font-weight600 font-size-heading">Documents</h2>
                                        <button
                                            title="Add Documents"
                                            onClick={handleAddfiles}
                                            className="upload-svg"
                                            style={{ float: "right" }}
                                            type="button" // Set the button type to "button"
                                        >
                                            <AddwithWhiteCircle /> Add Document
                                        </button>
                                    </div>
                                    <div>
                                        <div className="jd-heading-bottom-bold"></div>
                                        <div className="jd-heading-bottom-light"></div>
                                    </div>
                                </div>
                                {addDocumentation && formDataDoc?.files?.map((doc, index) => (
                                    <div key={index} className="form-flex-wrap" style={{ width: "100%" }}>
                                        <div className="flex-column">
                                            <label
                                                htmlFor={`document_name_${index}`}
                                                className="form-labels announce-date font-weight500 font-size-heading"
                                            >
                                                File Name <span className="required">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id={`document_name_${index}`}
                                                name={`document_name`}
                                                placeholder="Document Name"
                                                value={doc.document_name}
                                                onChange={(e) => handleDocInputChange(index, e)}
                                                className={`form-input ${errors[`document_name_${index}`]
                                                    ? "error"
                                                    : inputState[`document_name`]
                                                        ? "success"
                                                        : ""
                                                    }`}
                                            />
                                            {errors[`document_name_${index}`] && (
                                                <span className="error-message font-size-text">
                                                    {errors[`document_name_${index}`]}
                                                </span>
                                            )}
                                        </div>

                                        <div className="flex-column field-cont-div">
                                            <label
                                                htmlFor=""
                                                className="form-labels announce-date font-weight500 font-size-heading"
                                            >
                                                Upload File <span className="required">*</span>
                                            </label>
                                            <label
                                                htmlFor={`file_${index}`}
                                                className={`form-inputss custom-file-upload ${errors[`file_${index}`]
                                                    ? "error"
                                                    : inputState[`file`]
                                                        ? "success"
                                                        : ""
                                                    }`}
                                            >
                                                <div className={`text-center pdf-input  ${errors[`file_${index}`] ? 'error' : ''}`}>
                                                    <Attachment />
                                                </div>
                                            </label>
                                            <input
                                                id={`file_${index}`}
                                                name={`file`}
                                                type="file"
                                                onChange={(e) => handleDocInputChange(index, e)}
                                                style={{ display: "none", position: "relative" }}
                                            />
                                            {fileNames[index] && (
                                                <p className="file-name text-center">
                                                    {fileNames[index]}
                                                </p>
                                            )}

                                            {errors[`file_${index}`] && (
                                                <span className="error-message font-size-text" style={{ paddingTop: "40px" }}>
                                                    {errors[`file_${index}`]}
                                                </span>
                                            )}
                                        </div>


                                        <button
                                            type="button"
                                            className="model-button-cancel"
                                            onClick={() => handleRemoveDocument(index)}
                                        >
                                            Remove
                                        </button>

                                    </div>
                                ))}
                            </div>
                            {/* <hr className="line" /> */}
                            <div className="form-flex-wrap" style={{ width: "100%" }}>
                                <table className="table-css">
                                    <thead className="table-heading">
                                        <tr className="custom-table-head-tr">
                                            <th className="table-heading-text align-left">S. No.</th>
                                            <th className="table-heading-text text-center">Name</th>
                                            <th className="table-heading-text text-center">File</th>
                                            <th className="table-heading-text align-right width-5vw">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {formData.files != undefined && formData.files.length > 0 && formData?.files?.map((i, index) => (
                                            <React.Fragment key={index}>

                                                <tr className="custom-table-head-td">
                                                    <td className="table-body align-left">{index + 1}</td>
                                                    <td className="table-body text-center">{i.document_name}</td>
                                                    { }
                                                    <td className="table-body text-center">
                                                        {i.file ? (
                                                            // <a href={i.document_file} target="blank">
                                                            //   View
                                                            // </a>
                                                            <a href={i.file} target="blank">
                                                                <Eye />
                                                            </a>
                                                        ) : (
                                                            "Document Not Attached"
                                                        )}
                                                    </td>

                                                    <td className="table-body align-right width-5vw ">
                                                        <button
                                                            className="model-delete-button"
                                                            onClick={(e) =>
                                                                handlePicDelete(e, i, getDetailsdesign)
                                                            }
                                                        >
                                                            <DeleteDustbin />
                                                        </button>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                    disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >

        </>
    );
};


const DetailsdesignDelete = ({ i, getDetailsdesign }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    const [loading, setLoading] = useState(false)
    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait...");
        setLoading(true)
        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/innovation/${i.id}/`
            );

            if (res.status === 200) {
                await getDetailsdesign();
                setShow(false);
            } else {

            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false)
            toast.dismiss(loadingToastId)
        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Innovation of {i.department}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Certificate of {i.department}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500  model-button-cancel  "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                                disabled={loading}
                            >
                                Delete
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
const DownloadAndZip = ({
    mainData }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [show, setShow] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const handleShow = () => {
        setShow(true);
        setError("")
    }
    const handleClose = () => {
        setShow(false);
        setSelectedOption('');
    }



    useEffect(() => {
        if (show) {
            setDocumentList(mainData)
        }

    }, [show]);

    const fetchAndZipFiles = async () => {
        setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic
        setError("");

        try {
            // Step 1: Fetch the API response



            const apiResponse = documentList;
            const files = apiResponse.flatMap((project) =>
                project?.innovation?.map((file) => file?.file).filter(Boolean)
            );


            if (files.length === 0) {
                setError("No documents found in the API response.");
                setLoading(false);
                return;
            }


            const zip = new JSZip();


            const filePromises = files.map(async (file) => {
                try {
                    const fileResponse = await axios.get(file, {
                        responseType: "blob",
                    });

                    const fileName = getFileNameFromLink(file);
                    // const fileName = `drawing.pdf`;
                    // console.log(fileResponse.data)
                    zip.file(fileName, fileResponse.data);
                } catch (err) {
                    console.error(`Failed to download: ${file.document_name}`, err);
                }
            });

            await Promise.all(filePromises);


            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, "documents.zip");


            handleClose();
            toast.dismiss(loadingToastId);
            toast.success("Documents downloaded successfully!");


        } catch (err) {
            console.error("Error fetching data or downloading files:", err);
            setError("Failed to process the request. Please try again.");
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);

        }

    };

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const downloadTableAsExcel = () => {
        const table = document.querySelector('table');
        const tableHTML = table.outerHTML;

        const htmlContent = `
            <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel">
                <head>
                    <xml>
                        <x:ExcelWorkbook xmlns:x="urn:schemas-microsoft-com:office:excel">
                            <x:ExcelWorksheets>
                                <x:ExcelWorksheet>
                                    <x:Name>Sheet1</x:Name>
                                    <x:WorksheetOptions>
                                        <x:DisplayGridlines/>
                                    </x:WorksheetOptions>
                                </x:ExcelWorksheet>
                            </x:ExcelWorksheets>
                        </x:ExcelWorkbook>
                    </xml>
                </head>
                <body>
                    ${tableHTML}
                </body>
            </html>
        `;

        const blob = new Blob([htmlContent], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Innovation.xls';
        link.click();
    };



    return (
        <>
            <button
                title="Download"
                className="upload-svg"
                onClick={handleShow}
            >
                <DownloadIcon />
            </button>
            <div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="request-leave"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Download Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ overflowX: 'scroll' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <label>
                                <input
                                    type="radio"
                                    value="certificates"
                                    checked={selectedOption === 'certificates'}
                                    onChange={handleOptionChange}
                                />
                                Download Document
                            </label>
                            <label style={{ marginLeft: '20px' }}>
                                <input
                                    type="radio"
                                    value="data"
                                    checked={selectedOption === 'data'}
                                    onChange={handleOptionChange}
                                />
                                Download Data
                            </label>
                        </div>
                        {selectedOption === 'certificates' &&
                            <div>
                                <div style={{ margin: "30px 20px" }}>
                                    <table className="custom-table">
                                        <thead className="table-heading">
                                            <tr className="custom-table-head-tr font-weight400 font-size-subheading">

                                                <th className="table-heading-text">
                                                    Document
                                                </th>
                                                <th className="table-heading-text">
                                                    File
                                                </th>




                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentList.map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {i?.innovation?.map((doc, docIndex) => (
                                                        <React.Fragment key={docIndex}>
                                                            {console.log(doc)}
                                                            <tr className="custom-table-head-td">

                                                                <td className="table-body">
                                                                    {doc?.document_name}{" "}
                                                                </td>
                                                                <td className="table-body">
                                                                    {doc.file ? (

                                                                        <a href={doc.file} target="blank">
                                                                            <Eye />
                                                                        </a>
                                                                    ) : (
                                                                        "Document Not Attached"
                                                                    )}
                                                                </td>


                                                            </tr>
                                                        </React.Fragment>
                                                    ))}

                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                                <div className='button-models' >
                                    <button
                                        className="model-button   font-weight500"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                    <button onClick={fetchAndZipFiles} disabled={loading} className="model-button   font-weight500    model-button-cancel">
                                        {loading ? "Downloading..." : "Download Document"}
                                    </button>
                                </div>
                                {error && <p style={{ color: "red" }}>{error}</p>}
                            </div>
                        }

                        {selectedOption === 'data' &&
                            <div style={{ marginTop: '20px' }}>
                                <table className="custom-table">
                                    <thead className="table-heading">
                                        <tr className="custom-table-head-tr font-weight400 font-size-subheading">
                                            <th className="text-center table-heading-text">
                                                Sr no.
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Department
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Type
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Project Code
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Length
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Height
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Depth
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Width
                                            </th>
                                            <th className="text-center table-heading-text">
                                                Remark
                                            </th>


                                            <th className="text-center table-heading-text">
                                                Source Name
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mainData.map((i, index) => (
                                            <React.Fragment key={index}>

                                                <tr className="custom-table-head-td">
                                                    <td className="table-body">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.department}{" "}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.type}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.project}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.length}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.height_detail}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.depth_detail}{" "}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.width_detail}
                                                    </td>
                                                    <td className="text-center table-body">
                                                        {i.remark_text}
                                                    </td>


                                                    {i.is_internal_source ?
                                                        <td className="text-center table-body">
                                                            {i.source_name}
                                                        </td>
                                                        :
                                                        <td className="text-center table-body">
                                                            {i.external_photo ?
                                                                <a href={i.external_photo} target="_blank"><Attachment /></a>

                                                                : "-"}
                                                        </td>}







                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>


                                <div className="button-models">
                                    <button className="model-button font-weight500" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button
                                        onClick={downloadTableAsExcel}
                                        disabled={loading}
                                        className="model-button font-weight500 model-button-cancel"
                                    >
                                        {loading ? 'Downloading...' : 'Download Data'}
                                    </button>
                                </div>
                            </div>}

                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
};


const TableDesigns = () => {
    const [originalmainData, SetOriginalMainData] = useState([]);
    const [mainData, SetMainData] = useState([]);
    const getDetailsdesign = async () => {
        const result = await axios.get(`${BASE_URL}/detail-design/innovation/`);
        SetMainData(result.data);
        SetOriginalMainData(result.data)
    };
    const [search1, setSearch1] = useState("");
    const [search2, setSearch2] = useState("");
    const [search3, setSearch3] = useState("");
    const [selectedlength, setlength] = useState(
        ""
    );
    const [selectedheight, setheight] = useState(
        ""
    );
    const [selecteddepth, setdepth] = useState(
        ""
    );
    const [selectedwidth, setwidth] = useState(
        ""
    );


    const extractLengthValueAndUnit = (lengthString) => {
        const regex = /(\d+(\.\d+)?)\s*(\w+)/;
        const match = lengthString.match(regex);
        if (match) {
            return {
                value: parseFloat(match[1]),
                unit: match[3].toLowerCase(),
            };
        }
        return { value: 0, unit: '' };
    };

    const applyFilters = () => {
        let filteredData = originalmainData;


        if (search1 !== "") {
            const lowercasesearch1 = search1?.toString().toLowerCase();

            filteredData = filteredData.filter((employee) => {
                const projectMatch = employee?.project?.toString().includes(lowercasesearch1);
                return projectMatch;
            });
        }

        if (search2 !== "") {
            const lowercasesearch1 = search2.toLowerCase();

            filteredData = filteredData.filter((employee) => {
                const desiMatch = employee?.department.toLowerCase().includes(lowercasesearch1);
                return desiMatch;
            });
        }

        if (search3 !== "") {
            const lowercasesearch1 = search3.toLowerCase();

            filteredData = filteredData.filter((employee) => {
                const desiMatch = employee?.type.toLowerCase().includes(lowercasesearch1);
                return desiMatch;
            });
        }


        if (selectedlength) {
            const [rangeStart, rangeEnd] = selectedlength.split('-').map(Number);


            filteredData = filteredData.filter(item => {

                const { value: itemLength, unit: itemUnit } = extractLengthValueAndUnit(item.length);


                let itemLengthInMeters = itemLength;
                if (itemUnit === 'kilometer') {
                    itemLengthInMeters *= 1000;
                }

                const matchedData = itemLengthInMeters >= rangeStart * 1000 && itemLengthInMeters <= rangeEnd * 1000;
                return matchedData;
            });
        }


        if (selectedheight) {
            const [rangeStart, rangeEnd] = selectedheight.split('-').map(Number);


            filteredData = filteredData.filter(item => {
                const { value: itemHeight, unit: itemUnit } = extractLengthValueAndUnit(item.height_detail);

                let itemHeightInMeters = itemHeight;
                if (itemUnit === 'kilometer') {
                    itemHeightInMeters *= 1000;
                }

                const matchedData = itemHeightInMeters >= rangeStart * 1000 && itemHeightInMeters <= rangeEnd * 1000;
                return matchedData;
            });
        }


        if (selecteddepth) {
            const [rangeStart, rangeEnd] = selecteddepth.split('-').map(Number);


            filteredData = filteredData.filter(item => {
                const { value: itemDepth, unit: itemUnit } = extractLengthValueAndUnit(item.depth_detail);

                let itemDepthInMeters = itemDepth;
                if (itemUnit === 'kilometer') {
                    itemDepthInMeters *= 1000;
                }

                const matchedData = itemDepthInMeters >= rangeStart * 1000 && itemDepthInMeters <= rangeEnd * 1000;
                return matchedData;
            });
        }


        if (selectedwidth) {
            const [rangeStart, rangeEnd] = selectedwidth.split('-').map(Number);


            filteredData = filteredData.filter(item => {
                const { value: itemWidth, unit: itemUnit } = extractLengthValueAndUnit(item.width_detail);

                let itemWidthInMeters = itemWidth;
                if (itemUnit === 'kilometer') {
                    itemWidthInMeters *= 1000;
                }

                const matchedData = itemWidthInMeters >= rangeStart * 1000 && itemWidthInMeters <= rangeEnd * 1000;
                return matchedData;
            });
        }

        SetMainData(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [search1, search2, search3, mainData, selectedlength, selectedheight, selecteddepth, selectedwidth]);

    useEffect(() => {
        getDetailsdesign();
    }, []);

    const lengthRanges = [];
    for (let i = 0; i <= 1000; i += 10) {
        const rangeStart = i;
        const rangeEnd = i + 10;
        lengthRanges.push(`${rangeStart}-${rangeEnd}`);
    }
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="content-tabs">
                <div className="attendance-subcont">

                    <div className="field-cont max-70-width">


                        <div className="field-cont-div">
                            <CircularGrid />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedlength}
                                onChange={(e) => setlength(e.target.value)}
                            >
                                <option value="">Select Length</option>
                                {lengthRanges.map((range) => (
                                    <option key={range} value={range}>
                                        {range} km
                                    </option>
                                ))}

                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <CircularGrid />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedwidth}
                                onChange={(e) => setwidth(e.target.value)}
                            >
                                <option value="">Select Width</option>
                                {lengthRanges.map((range) => (
                                    <option key={range} value={range}>
                                        {range} km
                                    </option>
                                ))}

                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <CircularGrid />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedheight}
                                onChange={(e) => setheight(e.target.value)}
                            >
                                <option value="">Select Height</option>
                                {lengthRanges.map((range) => (
                                    <option key={range} value={range}>
                                        {range} km
                                    </option>
                                ))}

                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <CircularGrid />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selecteddepth}
                                onChange={(e) => setdepth(e.target.value)}
                            >
                                <option value="">Select Depth</option>
                                {lengthRanges.map((range) => (
                                    <option key={range} value={range}>
                                        {range} km
                                    </option>
                                ))}

                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-10vw"
                                placeholder="BY Project Code"
                                type="text"
                                value={search1}
                                onChange={(e) => setSearch1(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field  width-10vw"
                                placeholder="By Department Name"
                                type="text"
                                value={search2}
                                onChange={(e) => setSearch2(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-10vw"
                                placeholder="By Structure Type"
                                type="text"
                                value={search3}
                                onChange={(e) => setSearch3(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>

                    </div>
                    <div className='btn-cont'>
                        <DownloadAndZip mainData={mainData} />
                        <Adddetailsdesign getDetailsdesign={getDetailsdesign} />
                    </div>
                </div>
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="table-css-white-background table-box scroll-container-table">

                            <table className="custom-table">
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr font-weight400 font-size-subheading">
                                        <th className="text-center table-heading-text ">
                                            Sr no.
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Department
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Type
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Project Code
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Length
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Height
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Depth
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Width
                                        </th>
                                        <th className="text-center table-heading-text">
                                            Remark
                                        </th>


                                        <th className="text-center table-heading-text">
                                            Source Name
                                        </th>

                                        <th className="text-center table-heading-text">
                                            View Details
                                        </th>

                                        <th className="text-center table-heading-text">
                                            Delete
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {mainData.map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-center">
                                                    {index + 1}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.department}{" "}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.type}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.project ? i.project : "-"}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.length}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.height_detail}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.depth_detail}{" "}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.width_detail}
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.remark_text}
                                                </td>


                                                {i.is_internal_source ?
                                                    <td className="text-center table-body">
                                                        {i.source_name}
                                                    </td>
                                                    :
                                                    <td className="text-center table-body">
                                                        {i.external_photo ?
                                                            <a href={i.external_photo} target="_blank"><Attachment /></a>

                                                            : "-"}
                                                    </td>}

                                                <td className="text-center table-body width-5vw">
                                                    <div className="flex-row">
                                                        <Updatedetailsdesign
                                                            i={i}
                                                            getDetailsdesign={getDetailsdesign}
                                                        />

                                                    </div>

                                                </td>




                                                <td className="text-center table-body width-5vw">


                                                    <DetailsdesignDelete
                                                        i={i}
                                                        getDetailsdesign={getDetailsdesign}
                                                    />


                                                </td>


                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableDesigns;
