import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../App"


// const SidebarDropDown = (props) => {
//   let { data, index, currentPath, isexpand } = props;
//   currentPath = currentPath.split("/");
//   let paths = currentPath[2];
//   const [open, setOpen] = useState(data.subList.includes(paths));
//   console.log("PROPS -----> " + data.name, index, currentPath, isexpand);

//   const handleDocumentClick = (e) => {
//     if (e.target.closest(".dashboard-sidebar-middle-menu-split") !== null) {
//       return;
//     }
//     setOpen(false);
//   };

//   useEffect(() => {
//     document.addEventListener("click", handleDocumentClick);
//     return () => {
//       document.removeEventListener("click", handleDocumentClick);
//     };
//   }, []);

//   return (
//     <>
//       <div
//         className={isexpand ? "dashboard-sidebar-middle-menu-split" : "dashboard-sidebar-middle-menu-split display-flex-style"}
//         // className={isexpand? "dashboard-sidebar-middle-menu-split" :"dashboard-sidebar-middle-menu-split-closed"}
//         key={index}
//         onClick={(e) => setOpen(!open)}
//       >
//         <div className={isexpand ? "dashboard-sidebar-middle-menu" : "dashboard-sidebar-middle-menu-closed"}>
//           <div dangerouslySetInnerHTML={{ __html: data.userId === paths ? data.svgfill : data.svg }} />
//           {isexpand ? (
//             <h2 style={{
//               color: data.subList.includes(paths) ? "#2576BC" : "#707070",
//               marginLeft: "10px", marginTop: "10px"
//             }} className="sidebarname">
//               {data.name}
//             </h2>
//           ) : ""}
//         </div>

//         {isexpand ? (open ? (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//           <path d="M6 9L12 15L18 9" stroke="#2576BC" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
//         </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//           <path d="M6 9L12 15L18 9" stroke="#707070" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
//         </svg>))
//           : ""}
//       </div>

//       {open ? (
//         <div className={isexpand ? "" : "sidebarpopup"}>
//           <div className={isexpand ? "" : "dashboard-sidebar-middle-menu-split"}>
//             <div className={isexpand ? "" : "dashboard-sidebar-middle-menu"}>
//               {isexpand ? "" : (
//                 <h2
//                   style={{ color: data.subList.includes(paths) ? "#2576BC" : "#707070" }} className="sidebarname">
//                   {data.name}
//                 </h2>
//               )}
//             </div>

//             {isexpand ? "" : (open ? (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//               <path d="M6 9L12 15L18 9" stroke="#2576BC" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
//             </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//               <path d="M6 9L12 15L18 9" stroke="#707070" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
//             </svg>))
//             }
//           </div>
//           {data.subMenu.map((e, index) => {
//             return (
//               <Link to={`${e.id}`} className="dashboard-sidebar-middle-menu-sublist" key={index}>
//                 {/* {e.id === paths ? (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="2" viewBox="0 0 14 2" fill="none">
//                 <path d="M1 1H13" stroke="#2576BC" strokeWidth="2" strokeLinecap="round"/>
//               </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="2" viewBox="0 0 14 2" fill="none">
//                 <path d="M1 1H13" stroke="#707070" strokeWidth="2" strokeLinecap="round"/>
//               </svg>)} */}
//                 <div style={{ color: e.id === paths ? "#2576BC" : "#707070", }} className="dropdownheading">
//                   {e.name}
//                 </div>
//               </Link>
//             );
//           })}
//         </div>
//       ) : null}
//     </>
//   );
// };



const SidebarLink = (props) => {
  const { data, index, currentPath, isexpand } = props;
  let paths = currentPath.split("/");
  paths = paths[2];

  return (
    <Link
      to={`${data.userId}`}
      className="dashboard-sidebar-middle-menu-closed tooltipp-container"
      key={index}
    >
      <div className="tooltippp" dangerouslySetInnerHTML={{ __html: data.userId === paths ? data.svgfill : data.svg }} />
      <div className="tooltipp">
        {data.name}
      </div>
    </Link>
  );
};

const DashboardSidebar = (props) => {
  const { sidebarData, isexpand, setExpanded } = props;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [location]);

  const handleSpanClick = () => {
    setExpanded(false);
    sessionStorage.setItem("isExpanded", false);
  };


  const handleSpanClicked = () => {
    setExpanded(true);
    sessionStorage.setItem("isExpanded", true);
  };


  return (
    <div className={isexpand ? "dashboard-main-sidebar-section" : "dashboard-main-sidebar-section-closed"}>
      <div className="fixed-nav">
        <div>
          <img className="sidebar-closed-logo-cipl" src="https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png" alt="logo" />

        </div>

        <div className="sidebar-title">
          Menu
        </div>
        <div>
          {sidebarData.map((data, index) => {
            // console.log(data.userId + " " + data.subMenu);
            return data.subMenu ? (
              // <SidebarDropDown
              //   data={data}
              //   currentPath={currentPath}
              //   index={index}
              //   isexpand={isexpand}
              // />
              ""
            ) : (
              <SidebarLink data={data} currentPath={currentPath} index={index} isexpand={isexpand} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar