
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableReview from "./TableReview"

const Reviewteammain = () => {

    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="ReviewTeam" name={"Review Team"} />



            <TableReview />


        </>
    );
};


export default Reviewteammain