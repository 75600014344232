// ************ ==== >>>>Root Routes <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword";
import Resetpassword from "../pages/website/Resetpassword";

// import ViewActivities from "../components/Modals/ViewSurvay";

// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmpDashboard from "../pages/prebid/Main/Dashboard";
import Employeedashboard from "../pages/prebid/PrebidDashboard";

// new details page

import DeatilsDashboard from "../pages/Employeedashboard/Main/Dashboard";
import EmployeeDeatilsDashboard from "../pages/Employeedashboard/EmployeeDashboard";

// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AccountDashboard from "../pages/account/Main/dashboard";
import AccountMainDashboard from "../pages/account/AccountDashboard";
import ClientsPage from "../pages/account/Main/clientDetails/ClientsPage";
import CriticalIssues from "../components/Criticalissue/CriticalIssue";
import InvoicePage from "../components/Project/Invoice";
import DueProject from "../components/Project/DueProject";

import Newprojectsdetails from "../components/Project/Newproject";
import Allclient from "../pages/account/Main/Allclient";
import Projectbyclient from "../pages/account/Main/Projectbyclient";
import Invoicebyproject from "../pages/account/Main/Invoicebyproject";
import AllProject from "../pages/account/Main/Allprojectdetails";
import AllProjectDetails from "../components/Project/Allprojectdetails";
import ViewActivities from "../components/Modals/ViewActivities";
import ProjectDetails from "../components/Modals/ProjectDetaillsMain";
import Detaildesignmain from "../components/Detaildesign/detaildesignmain";
import Subcompanymain from "../components/SubCompany/Settingsmain";
import Structureheadingmain from "../components/Structureheading/Structureheadingmain";
import Clientmain from "../components/Client/Clientmain";
import Designmain from "../components/Designstage/Designmain";
import Reviewteammain from "../components/ReviewTeam/Reviewteammain";
import ReviewFilemain from "../components/ReviewFile/Reviewfilemain";
import Actionlogmain from "../components/Actionlog/Actionlogmain";
export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },
      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },
    ],
  },

  // Employee Management old
  // {
  //   role: "Employee",
  //   department: "Pre Engineering",
  //   isProtected: true,
  //   defaultRoute: "ed/edDashboard",
  //   nestedRoutes: [
  //     {
  //       routes: [
  //         {
  //           path: "",
  //           component: <EmpDashboard />,
  //         },
  //         {
  //           path: "edDashboard",
  //           component: <EmpDashboard />,
  //         },
  //         {
  //           path: "criticalIssues",
  //           component: <CriticalIssues />,
  //         },
  //         {
  //           path: "AllProjectDetails",
  //           component: <AllProjectDetails />,
  //         },
  //         {
  //           path: "dueprojectdetails",
  //           component: <DueProject/>
  //         },
  //         {
  //           path: "clients",
  //           component : <ClientsPage/>
  //         },
  //         {
  //           path: "viewactivities/:id/",
  //           component: <ViewActivities />,
  //         },
  //         {
  //           path: "newprojectdetails",
  //           component: <Newprojectsdetails/>
  //         }
  //       ],
  //       path: "ed",
  //       component: <Employeedashboard />,
  //     },
  //   ],
  // },

  //  Highway Routing
  {
    role: "Employee",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "ed/AllProjectdetails/",
    nestedRoutes: [
      {
        routes: [
          // {
          //   path: "",
          //   component: <DeatilsDashboard />,
          // },

          // {
          //   path: "edDashboard",
          //   component: <DeatilsDashboard />,
          // },
          {
            path: "Innovation",
            component: <Detaildesignmain />,
          },
          {
            path: "Settings",
            component: <Subcompanymain />,
          },
          {
            path: "Structureheading",
            component: <Structureheadingmain />,
          },
          {
            path: "Designstage",
            component: <Designmain />,
          },
          {
            path: "ReviewTeam",
            component: <Reviewteammain />,
          },

          {
            path: "ActionLog",
            component: <Actionlogmain />,
          },
          // ActionLog
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "Projectdetails/",
            component: <ProjectDetails />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },

          {
            path: "clients",
            component: <Clientmain />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },
        ],
        path: "ed",
        component: <EmployeeDeatilsDashboard />,
      },
    ],
  },

  // Structure Routing
  {
    role: "Employee",
    department: "Structure",
    isProtected: true,
    defaultRoute: "ed/AllProjectdetails/",
    nestedRoutes: [
      {
        routes: [
          // {
          //   path: "",
          //   component: <DeatilsDashboard />,
          // },

          // {
          //   path: "edDashboard",
          //   component: <DeatilsDashboard />,
          // },
          {
            path: "Innovation",
            component: <Detaildesignmain />,
          },
          {
            path: "Settings",
            component: <Subcompanymain />,
          },
          {
            path: "Structureheading",
            component: <Structureheadingmain />,
          },
          {
            path: "Designstage",
            component: <Designmain />,
          },
          {
            path: "ReviewTeam",
            component: <Reviewteammain />,
          },

          {
            path: "ActionLog",
            component: <Actionlogmain />,
          },
          // ActionLog
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "Projectdetails/",
            component: <ProjectDetails />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },

          {
            path: "clients",
            component: <Clientmain />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },
        ],
        path: "ed",
        component: <EmployeeDeatilsDashboard />,
      },
    ],
  },
  // Acccounts Management
  {
    role: "Employee",
    department: "Accounts",
    isProtected: true,
    defaultRoute: "account/accountDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <AccountDashboard />,
          },
          {
            path: "accountDashboard",
            component: <AccountDashboard />,
          },
          {
            path: "clients",
            component: <ClientsPage />, // Client Detail Page Not useful here
          },
          {
            path: "invoiceSlip/:id/",
            component: <InvoicePage />,
          },
          {
            path: "allclient",
            component: <Allclient />, // Useful
          },
          {
            path: "projectbyclient/:id/",
            component: <Projectbyclient />,
          },
          {
            path: "invoicebyproject/:id/",
            component: <Invoicebyproject />,
          },
          {
            path: "allprojectdetails/",
            component: <AllProject />,
          },
          {
            path: "projectDetails/:id/",
            component: <ProjectDetails />,
          },
        ],
        path: "account",
        component: <AccountMainDashboard />,
      },
    ],
  },

  {
    role: "Admin",
    department: "Information Technology",
    isProtected: true,
    defaultRoute: "admin/AllProjectdetails/",
    nestedRoutes: [
      {
        routes: [
          // {
          //   path: "",
          //   component: <DeatilsDashboard />,
          // },

          // {
          //   path: "edDashboard",
          //   component: <DeatilsDashboard />,
          // },
          {
            path: "Innovation",
            component: <Detaildesignmain />,
          },
          {
            path: "Settings",
            component: <Subcompanymain />,
          },
          {
            path: "Structureheading",
            component: <Structureheadingmain />,
          },
          {
            path: "Designstage",
            component: <Designmain />,
          },
          {
            path: "ReviewTeam",
            component: <Reviewteammain />,
          },

          {
            path: "ActionLog",
            component: <Actionlogmain />,
          },
          // ActionLog
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "Projectdetails/",
            component: <ProjectDetails />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },

          {
            path: "clients",
            component: <Clientmain />,
          },
          // {
          //   path: "newprojectdetails",
          //   component: <Newprojectsdetails />,
          // },
        ],
        path: "admin",
        component: <EmployeeDeatilsDashboard />,
      },
    ],
  },
];
