
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';
import TableActionlog from './TableActionlog';

const Actionlogmain = () => {

    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="ActionLog" name={"Action Log"} />



            <TableActionlog />


        </>
    );
};


export default Actionlogmain