import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import {
    AddwithWhiteCircle,
    DropdownArrow,
    DropdownArrowOption,
    DropdownArrowClosed,
    SearchBig,
    DownloadIcon,
    PDFIcon,
    UpdatePencil,
    Attachment,
} from "../AllSvg";

import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast } from "../CustomFunctions";
const Adddetailsdesign = ({ getDetailsdesign, mainData }) => {
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const [structureHeadingList, setStructureHeadingList] = useState([]);
    const [projectnameList, setProjectnameList] = useState([]);
    const [show, setShow] = useState(false);
    const [parentData, SetparentData] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        project: "",
        structure_heading: "",
        parent: "",
        percentage: "",
        completed: false,
        invoice_generated: false,
    });

    const getSubCompany = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setSiteSubCompanyList(sub.data);
        } catch (err) {
        } finally {
        }
    };

    const getAllSubheadings = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/structureheading`);
            setStructureHeadingList(sub.data);
        } catch (err) {
        } finally {
        }
    };
    const getAllProjectname = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/designstagesname/`);
            setProjectnameList(sub.data);
        } catch (err) {
        } finally {
        }
    };
    useEffect(() => {
        if (show) {

            getAllSubheadings();
            getAllProjectname();
            console.log(mainData, "maindata");
        }
    }, [formData.project, show]);
    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        // console.log(value)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }

        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));

        if (name === "is_internal_source") {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                    source_name: "",
                    external_photo: "",
                };

                return updatedFormData;
            });
        } else {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                };

                return updatedFormData;
            });
        }

        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = ["name", "structure_heading"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required!`;
            }
        });

        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {};

        // if (formData.is_internal_source) {
        //     newerror.is_internal_source = "Is internal source is required "
        // }
        // setErrors(newerror)
        if (validateform()) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (
                        formData[key] &&
                        key !== "id" &&
                        key !== "invoice_generated" &&
                        key !== "completed" && key != "child_stages"
                    ) {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                formDataToSend.append(
                    "invoice_generated",
                    formData?.invoice_generated === "Yes" ? true : false
                );
                formDataToSend.append("completed", formData?.completed === "Yes" ? true : false);

                let res = await axios.post(
                    `${BASE_URL}/detail-design/designstage/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200 || res.status === 201) {
                    setShow(false);
                    setFormData(() =>
                        Object.keys(formData).reduce((acc, key) => {
                            acc[key] = "";
                            return acc;
                        }, {})
                    );
                    await getDetailsdesign();
                } else {
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add Design Stage
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Design Stage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="name" className="flex-column">
                                <label
                                    htmlFor="name"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Name <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="name"
                                    name="name"
                                    placeholder="Enter name"
                                    onChange={handleInputChange}
                                    value={formData.name}
                                    className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                                        }`}
                                />
                                {errors.name && (
                                    <span className="error-message font-size-text">
                                        {errors.name}
                                    </span>
                                )}
                            </div>



                            <div
                                title="structure_heading"
                                className="flex-column form-group-selectt"
                            >
                                <label
                                    htmlFor="structure_heading"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Structure Heading <span className="required">*</span>
                                </label>
                                <br />
                                {structureHeadingList.length === 0 ? (
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                ) : (
                                    <>
                                        <select
                                            id="structure_heading"
                                            name="structure_heading"
                                            value={formData.structure_heading}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.structure_heading
                                                ? "error"
                                                : inputState.structure_heading
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select Structure Heading</option>
                                            {structureHeadingList
                                                ?.sort((a, b) =>
                                                    a.heading_name.localeCompare(b.name, "en", {
                                                        ignorePunctuation: true,
                                                    })
                                                )
                                                .map((i, index) => (
                                                    <option key={index} value={i.id}>
                                                        {i.heading_name}
                                                    </option>
                                                ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.structure_heading && (
                                            <span
                                                className="error-message font-size-text"
                                                style={{ display: "block" }}
                                            >
                                                {errors.structure_heading}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>

                            {projectnameList.length > 0 ? (
                                <div title="parent" className="flex-column form-group-selectt">
                                    <label
                                        htmlFor="parent"
                                        className="form-labels font-weight500 font-size-subheading"
                                    >
                                        Parent
                                    </label>
                                    <br />
                                    <select
                                        id="parent"
                                        name="parent"
                                        value={formData.parent}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.parent
                                            ? "error"
                                            : inputState.parent
                                                ? "success"
                                                : ""
                                            }`}
                                    >
                                        <option value="">Select Parent</option>
                                        {projectnameList
                                            // ?.sort((a, b) =>
                                            //     a.name.localeCompare(b.name, "en", {
                                            //         ignorePunctuation: true,
                                            //     })
                                            // )
                                            .map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.name}
                                                </option>
                                            ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.parent && (
                                        <span className="error-message font-size-text">
                                            {errors.parent}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                ""
                            )}

                            <div title="percentage" className="flex-column">
                                <label
                                    htmlFor="percentage"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Percentage
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    id="percentage"
                                    name="percentage"
                                    placeholder="Enter completion percentage"
                                    onChange={handleInputChange}
                                    value={formData.percentage}
                                    className={`form-input ${errors.percentage
                                        ? "error"
                                        : inputState.percentage
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.percentage && (
                                    <span className="error-message font-size-text">
                                        {errors.percentage}
                                    </span>
                                )}
                            </div>
                            {console.log(formData.completed, formData.invoice_generated)}
                            <div title="completed" className="flex-column form-group-selectt">
                                <label
                                    htmlFor="completed"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Complete
                                </label>
                                <br />
                                <select
                                    id="completed"
                                    name="completed"
                                    value={formData.completed}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.completed
                                        ? "error"
                                        : inputState.completed
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">{formData?.completed ? formData.completed : "Select Complete status"}</option>
                                    <option value="Yes">Yes </option>
                                    <option value="No">No</option>
                                </select>
                                <DropdownArrow />
                                {errors.completed && (
                                    <span className="error-message font-size-text">
                                        {errors.completed}
                                    </span>
                                )}
                            </div>
                            <div title="invoice_generated" className="flex-column form-group-selectt">
                                <label
                                    htmlFor="invoice_generated"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Invoice Generated
                                </label>
                                <br />
                                <select
                                    id="invoice_generated"
                                    name="invoice_generated"
                                    value={formData.invoice_generated}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.invoice_generated
                                        ? "error"
                                        : inputState.invoice_generated
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">Select Invoice Generated Status</option>
                                    <option value="Yes">Yes </option>
                                    <option value="No">No</option>
                                </select>
                                <DropdownArrow />
                                {errors.completed && (
                                    <span className="error-message font-size-text">
                                        {errors.completed}
                                    </span>
                                )}
                            </div>
                            {/* <div
                title="completed"
                className="flex-column"
                style={{ width: "100%" }}
              >
                <div className="flex-row">
                  <label
                    htmlFor="completed"
                    className="form-labels font-weight500 font-size-subheading"
                  >
                    {formData.completed ? "Completed" : "Not Completed"}
                  </label>
                  <hr className="field-cont-hr" />
                  <div className="checkbox-toggle">
                    <input
                      id="completed"
                      name="completed"
                      type="checkbox"
                      value={formData.completed}
                      className={`form-checkbox ${
                        errors.completed
                          ? "error"
                          : inputState.completed
                          ? "success"
                          : ""
                      }`}
                      onChange={handleInputChange}
                      checked={formData.completed}
                    />
                    <label htmlFor="completed"></label>
                  </div>
                </div>
              </div> */}



                            <div className="button-models" style={{ width: "100%" }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button font-weight500 model-button-submit"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
const Updatedetailsdesign = ({ i, getDetailsdesign, mainData }) => {
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const [structureHeadingList, setStructureHeadingList] = useState([]);
    const [projectnameList, setProjectnameList] = useState([]);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        project: "",
        structure_heading: "",
        parent: "",
        percentage: "",
        completed: false,
        invoice_generated: false,
    });

    const getSubCompany = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setSiteSubCompanyList(sub.data);
        } catch (err) {
        } finally {
        }
    };

    const getAllSubheadings = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/structureheading`);
            setStructureHeadingList(sub.data);
        } catch (err) {
        } finally {
        }
    };
    const getAllProjectname = async () => {
        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/designstagesname/`);
            setProjectnameList(sub.data);
        } catch (err) {
        } finally {
        }
    };
    useEffect(() => {
        if (show) {

            getAllSubheadings();
            getAllProjectname();
            setFormData(i);
        }
    }, [formData.project, show]);
    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        // console.log(value)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }
        console.log(newValue);

        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));

        if (name === "is_internal_source") {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                    source_name: "",
                    external_photo: "",
                };

                return updatedFormData;
            });
        } else {
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: newValue,
                };

                return updatedFormData;
            });
        }
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = ["name", "structure_heading"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required!`;
            }
        });

        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {};

        // if (formData.is_internal_source) {
        //     newerror.is_internal_source = "Is internal source is required "
        // }
        // setErrors(newerror)
        if (validateform()) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (
                        formData[key] &&
                        key !== "id" &&
                        key !== "invoice_generated" &&
                        key !== "completed" && key !== "child_stages"
                    ) {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                formDataToSend.append(
                    "invoice_generated",
                    formData?.invoice_generated === "Yes" ? true : false
                );
                formDataToSend.append("completed", formData?.completed === "Yes" ? true : false);

                let res = await axios.put(
                    `${BASE_URL}/detail-design/designstage/${formData.id}/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200 || res.status === 201) {
                    setShow(false);
                    setFormData(() =>
                        Object.keys(formData).reduce((acc, key) => {
                            acc[key] = "";
                            return acc;
                        }, {})
                    );
                    await getDetailsdesign();
                } else {
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };
    return (
        <>
            <button onClick={handleShow} className="model-edit-button">
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update Design Stage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="name" className="flex-column">
                                <label
                                    htmlFor="name"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Name <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="name"
                                    name="name"
                                    placeholder="Enter name"
                                    onChange={handleInputChange}
                                    value={formData.name}
                                    className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                                        }`}
                                />
                                {errors.name && (
                                    <span className="error-message font-size-text">
                                        {errors.name}
                                    </span>
                                )}
                            </div>



                            <div
                                title="structure_heading"
                                className="flex-column form-group-selectt"
                            >
                                <label
                                    htmlFor="structure_heading"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Structure Heading <span className="required">*</span>
                                </label>
                                <br />
                                {structureHeadingList.length === 0 ? (
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                ) : (
                                    <>
                                        <select
                                            id="structure_heading"
                                            name="structure_heading"
                                            value={formData.structure_heading}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.structure_heading
                                                ? "error"
                                                : inputState.structure_heading
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select Structure Heading</option>
                                            {structureHeadingList
                                                ?.sort((a, b) =>
                                                    a.heading_name.localeCompare(b.name, "en", {
                                                        ignorePunctuation: true,
                                                    })
                                                )
                                                .map((i, index) => (
                                                    <option key={index} value={i.id}>
                                                        {i.heading_name}
                                                    </option>
                                                ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.structure_heading && (
                                            <span
                                                className="error-message font-size-text"
                                                style={{ display: "block" }}
                                            >
                                                {errors.structure_heading}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>

                            {projectnameList.length > 0 ? (
                                <div title="parent" className="flex-column form-group-selectt">
                                    <label
                                        htmlFor="parent"
                                        className="form-labels font-weight500 font-size-subheading"
                                    >
                                        Parent
                                    </label>
                                    <br />
                                    <select
                                        id="parent"
                                        name="parent"
                                        value={formData.parent}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.parent
                                            ? "error"
                                            : inputState.parent
                                                ? "success"
                                                : ""
                                            }`}
                                    >
                                        <option value="">Select Parent</option>
                                        {projectnameList

                                            .map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.name}
                                                </option>
                                            ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.parent && (
                                        <span className="error-message font-size-text">
                                            {errors.parent}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                            <div title="percentage" className="flex-column">
                                <label
                                    htmlFor="percentage"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Percentage
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    id="percentage"
                                    name="percentage"
                                    placeholder="Enter completion percentage"
                                    onChange={handleInputChange}
                                    value={formData.percentage}
                                    className={`form-input ${errors.percentage
                                        ? "error"
                                        : inputState.percentage
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.percentage && (
                                    <span className="error-message font-size-text">
                                        {errors.percentage}
                                    </span>
                                )}
                            </div>

                            <div title="completed" className="flex-column form-group-selectt">
                                <label
                                    htmlFor="completed"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Complete
                                </label>
                                <br />
                                <select
                                    id="completed"
                                    name="completed"
                                    value={formData.completed}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.completed
                                        ? "error"
                                        : inputState.completed
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">{formData.completed ? formData.completed ? "Yes" : "No" : "Select Complete Status"}</option>
                                    <option value="Yes">Yes </option>
                                    <option value="No">No</option>
                                </select>
                                <DropdownArrow />
                                {errors.completed && (
                                    <span className="error-message font-size-text">
                                        {errors.completed}
                                    </span>
                                )}
                            </div>
                            <div title="invoice_generated" className="flex-column form-group-selectt">
                                <label
                                    htmlFor="invoice_generated"
                                    className="form-labels font-weight500 font-size-subheading"
                                >
                                    Invoice Generated
                                </label>
                                <br />
                                <select
                                    id="invoice_generated"
                                    name="invoice_generated"
                                    value={formData.invoice_generated}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.invoice_generated
                                        ? "error"
                                        : inputState.invoice_generated
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">{formData.invoice_generated ? formData.invoice_generated ? "Yes" : "No" : "Select Invoice Generated Status"}</option>
                                    <option value="Yes">Yes </option>
                                    <option value="No">No</option>
                                </select>
                                <DropdownArrow />
                                {errors.invoice_generated && (
                                    <span className="error-message font-size-text">
                                        {errors.invoice_generated}
                                    </span>
                                )}
                            </div>

                            <div className="button-models" style={{ width: "100%" }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button font-weight500 model-button-submit"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const DetailsdesignDelete = ({ i, getDetailsdesign }) => {
    const [show, setShow] = useState(false);
    // console.log(i)
    const handleClose = () => {
        setShow(false)

    };
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait...");
        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/designstage/${i.id}/`
            );

            if (res.status === 200 || res.status === 201) {
                await getDetailsdesign();
                setShow(false);
            } else {
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Design Stage of {i.structure_heading_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Certificate of{" "}
                        {i.structure_heading_name}
                        <div className="button-models">
                            <button
                                className="model-button font-weight500 model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const DownloadAndZip = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [show, setShow] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    // const getDocument=async()=>{
    //   const res=await axios.get(`${BASE_URL}/siteproject/certificationdownlaodfilter/${fromDate}/${toDate}/${sector}/${structure}/null/`);
    //   setDocumentList(res.data);
    // }

    // useEffect(()=>{
    //   getDocument();
    // },[fromDate,toDate,sector,structure]);

    const fetchAndZipFiles = async () => {
        setLoading(true); // loading logic
        //   const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic
        //   setError("");

        //   try {
        //     // Step 1: Fetch the API response

        //       const apiResponse = documentList; // Assuming API response is JSON
        //       const files = apiResponse.flatMap((project) => project.documents);

        //       if (files.length === 0) {
        //         setError("No documents found in the API response.");
        //         setLoading(false);
        //         return;
        //       }

        //       // Step 3: Initialize JSZip
        //       const zip = new JSZip();

        //       // Step 4: Fetch each document and add it to the ZIP
        //       const filePromises = files.map(async (file) => {
        //         try {
        //           const fileResponse = await axios.get(file.document_file, {
        //             responseType: "blob",
        //           });
        //           const fileName = `${file.document_name}.pdf`;
        //           zip.file(fileName, fileResponse.data);
        //         } catch (err) {
        //           console.error(`Failed to download: ${file.document_name}`, err);
        //         }
        //       });

        //       await Promise.all(filePromises);

        //       // Step 5: Generate and save the ZIP file
        //       const zipBlob = await zip.generateAsync({ type: "blob" });
        //       saveAs(zipBlob, "documents.zip");

        //       // Close the modal and show success toast
        //       handleClose();
        //       toast.dismiss(loadingToastId);
        //       toast.success("Documents downloaded successfully!");

        //   } catch (err) {
        //     console.error("Error fetching data or downloading files:", err);
        //     setError("Failed to process the request. Please try again.");
        //   } finally {
        //     setLoading(false);
        //     toast.dismiss(loadingToastId);

        //   }
    };

    return (
        <>
            {/* <button
                title="Download"
                className="upload-svg"
                onClick={handleShow}
            >
                <DownloadIcon />
            </button> */}
            <div>
                <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                    <Modal.Header closeButton>
                        <Modal.Title>Download Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ margin: "30px 20px" }}>
                                <ol>
                                    {documentList.map(
                                        (project, index) =>
                                            // <li key={index}>
                                            //   {project.project_short_name}

                                            project.documents.map((document, docIndex) => (
                                                <li key={docIndex}>{document.document_name}</li>
                                            ))

                                        // </li>
                                    )}
                                </ol>
                            </div>
                            <div className="button-models">
                                <button
                                    className="model-button   font-weight500"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={fetchAndZipFiles}
                                    disabled={loading}
                                    className="model-button   font-weight500    model-button-cancel"
                                >
                                    {loading ? "Downloading..." : "Download Document"}
                                </button>
                            </div>
                            {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>
                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
};

const TableDesignsstage = () => {
    const [originalmainData, SetOriginalMainData] = useState([]);
    const [mainData, SetMainData] = useState([]);
    const getDetailsdesign = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/designstage/`);
            SetMainData(result.data);
            SetOriginalMainData(result.data);
        } catch (err) {
            // handleErrorToast(err);
        }
    };
    const [search1, setSearch1] = useState("");
    const [search2, setSearch2] = useState("");
    const [search3, setSearch3] = useState("");
    const applyFilters = () => {
        let filteredData = originalmainData;

        if (search1 !== "") {
            const lowercasesearch1 = search1?.toString().toLowerCase();
            filteredData = filteredData.filter((employee) => {
                const projectMatch = employee?.structure_heading_name
                    ?.toString()
                    .includes(lowercasesearch1);

                return projectMatch;
            });
            console.log("filteredData");
            console.log(filteredData);
            // SetMainData(filteredData)
        }

        // if (search2 !== "") {
        //     const lowercasesearch1 = search2.toLowerCase();

        //     filteredData = filteredData.filter((employee) => {
        //         const desiMatch = employee?.department
        //             .toLowerCase()
        //             .includes(lowercasesearch1);

        //         return desiMatch;
        //     });
        // }

        // if (search3 !== "") {
        //     const lowercasesearch1 = search3.toLowerCase();

        //     filteredData = filteredData.filter((employee) => {
        //         const desiMatch = employee?.type
        //             .toLowerCase()
        //             .includes(lowercasesearch1);

        //         // const desiMatch = employee.position_name.toLowerCase().includes(lowercasesearch1);
        //         return desiMatch;
        //     });
        // }
        SetMainData(filteredData);
    };
    useEffect(() => {
        applyFilters();
    }, [search1, mainData]);

    useEffect(() => {
        getDetailsdesign();
    }, []);

    const sourceDataObject = [
        {
            id: "ac51fcb3-18a8-4abc-ab28-9b8bc9d89290",
            name: "prarent 1",
            project: 23,
            project_name: "test2",
            structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
            structure_heading_name: "testi",
            parent: null,
            percentage: "",
            completed: true,
            invoice_generated: true,
            child_stages: [
                {
                    id: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                    name: "child1",
                    project: 23,
                    project_name: "test",
                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                    structure_heading_name: "testi",
                    parent: "ac51fcb3-18a8-4abc-ab28-9b8bc9d89290",
                    percentage: 70,
                    completed: true,
                    invoice_generated: true,
                    child_stages: [
                        {
                            id: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                            name: "grandchild1",
                            project: 23,
                            project_name: "test",
                            structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                            structure_heading_name: "testi",
                            parent: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                            percentage: 50,
                            completed: false,
                            invoice_generated: false,
                            child_stages: [
                                {
                                    id: "newChildId-001",
                                    name: "greatgrandchild1",
                                    project: 23,
                                    project_name: "test",
                                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                                    structure_heading_name: "testi",
                                    parent: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                                    percentage: 50,
                                    completed: false,
                                    invoice_generated: false,
                                    child_stages: [],
                                },
                            ],
                        },
                        {
                            id: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                            name: "grandchild2",
                            project: 23,
                            project_name: "test",
                            structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                            structure_heading_name: "testi",
                            parent: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                            percentage: 40,
                            completed: false,
                            invoice_generated: false,
                            child_stages: [
                                {
                                    id: "newChildId-001",
                                    name: "greatgrandchild2",
                                    project: 23,
                                    project_name: "test",
                                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                                    structure_heading_name: "testi",
                                    parent: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                                    percentage: 70,
                                    completed: false,
                                    invoice_generated: false,
                                    child_stages: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                    name: "child2",
                    project: 23,
                    project_name: "test",
                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                    structure_heading_name: "testi",
                    parent: "ac51fcb3-18a8-4abc-ab28-9b8bc9d89290",
                    percentage: 90,
                    completed: true,
                    invoice_generated: true,
                    child_stages: [
                        {
                            id: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                            name: "2grandchild1",
                            project: 23,
                            project_name: "test",
                            structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                            structure_heading_name: "testi",
                            parent: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                            percentage: 80,
                            completed: false,
                            invoice_generated: false,
                            child_stages: [
                                {
                                    id: "newChildId-001",
                                    name: "greatgrandchild1",
                                    project: 23,
                                    project_name: "test",
                                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                                    structure_heading_name: "testi",
                                    parent: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                                    percentage: 70,
                                    completed: false,
                                    invoice_generated: false,
                                    child_stages: [],
                                },
                            ],
                        },
                        {
                            id: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                            name: "grandchild2",
                            project: 23,
                            project_name: "test",
                            structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                            structure_heading_name: "testi",
                            parent: "aeea10ed-2573-44ee-81b6-373ebb61dd1e",
                            percentage: 80,
                            completed: false,
                            invoice_generated: false,
                            child_stages: [
                                {
                                    id: "newChildId-001",
                                    name: "greatgrandchild2",
                                    project: 23,
                                    project_name: "test",
                                    structure_heading: "62db1f91-0c08-400a-8c32-5a4a6f1011ce",
                                    structure_heading_name: "testi",
                                    parent: "5589734a-a2b3-4d56-8684-e7389bb84a3e",
                                    percentage: 70,
                                    completed: false,
                                    invoice_generated: false,
                                    child_stages: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    const [openRows, setOpenRows] = useState({});

    const handleToggle = (id) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const renderRows = (rows, level = 0, isChild = false) => {
        return rows.map((row, index) => {
            const hasParent = row.parent !== null;
            const hasChildren =
                Array.isArray(row.child_stages) && row.child_stages.length > 0;
            const isParent = hasChildren;
            const isChildOnly = !isChild && !isParent;

            let rowClass = "";
            if (hasParent && isParent) {
                rowClass = "parent-child";
            } else if (isParent) {
                rowClass = "parent";
            } else if (isChildOnly) {
                rowClass = "child";
            }

            const paddingLeft = `${level / 10 + (isChild ? 15 : 5)}px`;

            const arrowIcon =
                isParent && hasChildren ? (
                    openRows[row.id] ? (

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="25"
                            fill="currentColor"
                            class="bi bi-caret-down-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            id="right-arrow"
                        >
                            <g>
                                <g>
                                    <rect
                                        opacity="0" transform="rotate(180 12 12)"
                                    />
                                    <path
                                        d="M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46z"
                                    />
                                </g>
                            </g>
                        </svg>
                    )
                ) : (
                    ""
                );
            // const calculateWeightage = (row) => {
            //     console.log(row, hasChildren)
            //     if (!hasChildren) {
            //         return row.percentage || 0;
            //     }
            //     console.log(row.child_stages.reduce((total, child) => total + calculateWeightage(child), 0))
            //     return row.child_stages.reduce((total, child) => total + calculateWeightage(child), 0);
            // };
            // const parentWeightage = isParent && !hasParent ? calculateWeightage(row) : row.percentage;
            return (
                <React.Fragment key={row.id || index}>

                    {((isParent && !hasParent) || (!hasChildren && !hasParent)) && (
                        <>
                            {/* <tr className={`custom-table-head-td ${rowClass}`}>
                                <td className="table-body text-center">{row.index || ""}</td>
                                <td className="table-body text-center">
                                    {row.structure_heading_name || ""}
                                </td>
                                <td className="table-body text-center"></td>
                                <td className="table-body text-center"></td>
                                <td className="table-body text-center"></td>
                                <td className="table-body text-center"></td>
                                <td className="table-body text-center"></td>
                                <td className="table-body text-center"></td>
                            </tr> */}
                            <tr className={`custom-table-head-td ${rowClass}`}>
                                <td className="table-body text-center border-top-bottom">{row.index || ""}</td>
                                <td className="table-body text-center border-top-bottom"> {row.structure_heading_name || ""}</td>
                                <td
                                    className="table-body text-center border-top-bottom"
                                    onClick={() => handleToggle(row.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {arrowIcon}
                                    {row.name || ""}
                                </td>
                                {/* <td className="table-body text-center">{row.project_name || ''}</td> */}
                                {/* <td className="table-body text-center">{row.parent_name || ''}</td> */}
                                <td className="table-body text-center border-top-bottom">
                                    {row.percentage || ""}
                                </td>
                                <td className="table-body text-center border-top-bottom">
                                    {row.completed ? "Yes" : row.completed === false ? "No" : ""}
                                </td>
                                <td className="table-body text-center border-top-bottom">
                                    {row.invoice_generated
                                        ? "Yes"
                                        : row.invoice_generated === false
                                            ? "No"
                                            : ""}
                                </td>
                                <td className="table-body text-center border-top-bottom">
                                    <Updatedetailsdesign
                                        i={row}
                                        getDetailsdesign={getDetailsdesign}
                                        mainData={rows}
                                    />
                                    <DetailsdesignDelete
                                        i={row}
                                        getDetailsdesign={getDetailsdesign}
                                        mainData={rows}
                                    />
                                </td>
                            </tr>

                        </>
                    )}
                    {/* 1px solid #e8e8e8 */}
                    {hasParent && (
                        <tr className={`custom-table-head-td ${rowClass}`} >
                            {console.log(isChildOnly)}
                            <td className="table-body text-center border-top-bottom">{row.index || ""}</td>
                            <td className="table-body text-center border-top-bottom"></td>
                            <td
                                className="table-body text-center border-top-bottom"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleToggle(row.id)}
                            >
                                <span style={{ paddingLeft }}>
                                    {arrowIcon}
                                    {row.name || ""}
                                </span>
                            </td>
                            {/* 
                            <td className="table-body text-center">{row.parent_name || ''}</td> */}
                            <td className="table-body text-center border-top-bottom">{row.percentage || ""}</td>
                            <td className="table-body text-center border-top-bottom">
                                {row.completed ? "Yes" : row.completed === false ? "No" : ""}
                            </td>
                            <td className="table-body text-center border-top-bottom">
                                {row.invoice_generated
                                    ? "Yes"
                                    : row.invoice_generated === false
                                        ? "No"
                                        : ""}
                            </td>
                            <td className="table-body text-center border-top-bottom">
                                <Updatedetailsdesign
                                    i={row}
                                    getDetailsdesign={getDetailsdesign}
                                    mainData={rows}
                                />
                                <DetailsdesignDelete
                                    i={row}
                                    getDetailsdesign={getDetailsdesign}
                                    mainData={rows}
                                />
                            </td>
                        </tr>
                    )}

                    {hasChildren &&
                        openRows[row.id] &&
                        renderRows(row.child_stages, level + 1, true)}
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* <div className="attendance-subcont">
                <div className="field-cont">
                    <div className="field-cont-div">
                        <SearchBig />
                        <input
                            className="attendance-input-field width-10vw"
                            placeholder="BY Structure Heading Name"
                            type="text"
                            value={search1}
                            onChange={(e) => setSearch1(e.target.value)}
                        />
                        <hr className="field-cont-hr" />
                    </div>


                </div>
                <div className="btn-cont">
                    <DownloadAndZip />

                </div>
            </div> */}
            <div className="info-box-div-without-bg">
                <div className="info-box-div-bg">
                    <div>
                        <div className="jd-heading-outer-flex">
                            <h2 className="jd-heading-main font-weight600 font-size-heading">
                                Design Stage
                            </h2>
                            <div className="field-cont">
                                <div className="field-cont-div">
                                    <SearchBig />
                                    <input
                                        className="attendance-input-field width-10vw"
                                        placeholder="BY Structure Heading Name"
                                        type="text"
                                        value={search1}
                                        onChange={(e) => setSearch1(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <Adddetailsdesign
                                    getDetailsdesign={getDetailsdesign}
                                    mainData={mainData}
                                />
                                <div className="Toastify"></div>
                            </div>
                        </div>
                        <div>
                            <div className="jd-heading-bottom-bold"></div>
                            <div className="jd-heading-bottom-light"></div>
                        </div>
                    </div>
                    <div className="table-css-white-background table-box scroll-container-table">
                        {/* <div className="table-heading-flex  field-cont-between table-css-white-background">
                            <div className="repo-heading heading-padding-none font-weight500 font-size-heading">
                                Design Stage
                            </div>
                        </div> */}
                        <table className="table-css">
                            <thead className="table-heading">
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-lefttt">Sr no.</th>
                                    <th className="text-center table-heading-text">
                                        Structure Heading
                                    </th>
                                    <th className="table-heading-text text-center">name</th>
                                    {/* <th className="text-center table-heading-text">
                                        Project
                                    </th> */}
                                    {/* <th className="text-center table-heading-text">
                                        Parent
                                    </th> */}
                                    <th className="text-center table-heading-text">Percentage</th>
                                    <th className="text-center table-heading-text">Completed</th>
                                    <th className="text-center table-heading-text">
                                        Invoice Generated
                                    </th>
                                    <th className="text-center table-heading-text">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {renderRows(sourceDataObject, "0")} */}
                                {renderRows(mainData, "0")}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableDesignsstage;
