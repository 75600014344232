import axios from "axios"
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { AddwithWhiteCircle, UpdatePencil, DropdownArrow, SearchBig, UpdatePencilWhite, PDFIcon } from "../AllSvg";
import { handleErrorToast } from "../CustomFunctions"
import { toast, ToastContainer } from 'react-toastify';
const AssignTask = () => {

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;


    const [formData, setFormData] = useState({
        task: '',
        project: '',
        concern_person: '',
        priority: '',
        due_date: '',
        assigndate: formattedDate,
        assignedby: '',
        status: "pending",
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "task",
            "project",
            "concern_person",
            "priority",
            "due_date",

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formData,
            task: `${formData.project}-${formData.task}`,
            assignedby: sessionStorage.getItem("employee_id"),
            assignedtocc: [sessionStorage.getItem("employee_id")]
        }

        if (validateForm()) {
            try {
                let res = await axios.post(`${BASE_URL}/wfm/tasks/`, updatedFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                alert(err);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    // get request Client Data
    useEffect(() => {
        getProjectDetails();
    }, []);

    const [allProjectData, setAllProjectData] = useState([]);
    const getProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/highway/allproject/`,
            );
            setAllProjectData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }



    useEffect(() => {
        getAllempdata();
    }, []);
    const [allemployeedata, setAllemployee] = useState([]);

    const getAllempdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`,);
            setAllemployee(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    return (
        <div className='modal-body-padding'>
            <form className="register-form" >

                <div className='form-flex-wrap'>


                    <div className="flex-column">
                        <label>Task:</label>
                        <input
                            type="text"
                            name="task"
                            value={formData.task}
                            onChange={handleInputChange}
                            className={`form-input form-control-field-long ${errors.task ? 'error' : inputState.task ? 'success' : ''}`}
                        />
                        {errors.task && (
                            <span className="error-message">{errors.task}</span>
                        )}
                    </div>


                    <div className="flex-column">
                        <label>Due Date:</label>
                        <input
                            type="date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleInputChange}
                            className={`form-input ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                        />
                        {errors.due_date && (
                            <span className="error-message">{errors.due_date}</span>
                        )}
                    </div>
                    <div className="flex-column form-group-select">
                        <label>Project Name:</label>
                        <select
                            name="project"
                            value={formData.project}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.project ? 'error' : inputState.project ? 'success' : ''}`}
                        >
                            <option value="">Select a Project</option>
                            {
                                allProjectData.length > 0 ?
                                    (allProjectData.map((e, index) => (
                                        <option key={index} value={`${e.prebid_prcode}-${e.project_name}`}>{e.prebid_prcode}-{e.project_name}</option>
                                    ))) : (
                                        <option value="">No Clients Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.project && (
                            <span className="error-message">{errors.project}</span>
                        )}

                    </div>
                    <div className="flex-column form-group-select">
                        <label>Priority:</label>
                        <select
                            name="priority"
                            value={formData.priority}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.priority ? 'error' : inputState.priority ? 'success' : ''}`}
                        >
                            <option value="">Select a priority</option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.priority && (
                            <span className="error-message">{errors.priority}</span>
                        )}
                    </div>





                    <div className="flex-column form-group-select">
                        <label>Concern Person:</label>
                        <select
                            name="concern_person"
                            value={formData.concern_person}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                        >
                            <option value="">Select a Employee</option>
                            {
                                allemployeedata.length > 0 ?
                                    (allemployeedata.map((e, index) => (
                                        <option key={index} value={e.emp_code}>{e.emp_code} - {e.name}</option>
                                    ))) : (
                                        <option value="">No Employee Available</option>
                                    )
                            }
                        </select>
                        <div className="form-group-selection-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.concern_person && (
                            <span className="error-message">{errors.concern_person}</span>
                        )}

                    </div>
                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const AddProjectDetails = () => {
    const [sub_CompanyList, setsub_CompanyList] = useState([]);
    const getAllsub_Company = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany`);
            setsub_CompanyList(sub.data);
        } catch (err) {

        } finally {

        }
    };
    const [formData, setFormData] = useState({
        pr_code: '',
        sub_Company: '',
        project_name: '',
        client: '',
        location: '',
        concern_person: '',
        concern_person_name: '',
        length: '',
        award_date: '',
        wo_date: '',
        start_date: '',
        due_date: '',
        project_mode: '',
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
        confirm_by: '',
        confirmation_mode: '',
        status: 'pending',
        project_description: '',
    });




    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            'pr_code',
            'client',
            "sub_Company",
            "concern_person",
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };

    console.log(formData)

    console.log(errors)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const filteredFormData = Object.keys(formData).reduce((acc, key) => {
                    if (formData[key] !== '') {
                        acc[key] = formData[key];
                    }
                    return acc;
                }, {});
                let res = await axios.post(`${BASE_URL}/detail-design/project/`, filteredFormData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                // setShow(false);
                toast.error(err)
                handleErrorToast(err)
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // get request Client Data
    useEffect(() => {
        getClientDetails();
        getAllsub_Company()
    }, []);

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/detail-design/client/`,
            );
            setAllClientData(res.data);
        } catch (err) {

        } finally {

        }

    }


    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>
                    <div className="flex-column">
                        <label className="form-labels">PR. Code <span className="required">*</span></label>
                        <input
                            type="text"
                            name="pr_code"
                            value={formData.pr_code}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                        />
                        {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Project Description </label>
                        <input
                            type="text"
                            name="project_description"
                            value={formData.project_description}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_description ? 'error' : inputState.project_description ? 'success' : ''}`}
                        />
                        {errors.project_description && <span className="error-message">{errors.project_description}</span>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Project Name <span className="required">*</span></label>
                        <input
                            type="text"
                            name="project_name"
                            value={formData.project_name}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                        />
                        {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                    </div>
                    <div title="sub_Company" className="flex-column form-group-selectt">
                        <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                            Sub Company <span className="required">*</span>
                        </label>
                        <br />
                        {sub_CompanyList.length === 0 ?
                            <div className="form-loader">
                                <div className="bar-loader"></div>
                            </div> :
                            <>
                                <select
                                    id="sub_Company"
                                    name="sub_Company"
                                    value={formData.sub_Company}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                        }`}
                                >
                                    <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                                    {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                                        <option value={i.id}>{i.title}</option>
                                    </>))
                                    }
                                </select>
                                <DropdownArrow />
                                <div>
                                    {errors.sub_Company && (
                                        <span className="error-message font-size-text ">{errors.sub_Company}</span>
                                    )}
                                </div></>}
                    </div>
                    <div className="flex-column form-group-selectt">
                        <label className="form-labels"> Client Name<span className="required">*</span></label>
                        <br />
                        {allClientData.length === 0 ?
                            <div className="form-loader">
                                <div className="bar-loader"></div>
                            </div> : <>
                                <select
                                    name="client"
                                    value={formData.client}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                >
                                    <option value="">Select a Client</option>
                                    {
                                        allClientData.length > 0 ?
                                            (allClientData.map((e, index) => (
                                                <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                            ))) : (
                                                <option value="">No Clients Available</option>
                                            )
                                    }

                                </select>
                                <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                <br />
                                {errors.client && (
                                    <span className="error-message">{errors.client}</span>
                                )}
                            </>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Location</label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                        />
                        {errors.location && <span className="error-message">{errors.location}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Length</label>
                        <input
                            type="text"
                            name="length"
                            value={formData.length}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                        />
                        {errors.length && <span className="error-message">{errors.length}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">WO Amount</label>
                        <input
                            type="number"
                            name="woamount"
                            value={formData.woamount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                        />
                        {errors.woamount && <span className="error-message">{errors.woamount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Without GST Amount</label>
                        <input
                            type="number"
                            name="without_gst_amount"
                            value={formData.without_gst_amount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                        />
                        {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> GST Amount</label>
                        <input
                            type="numbeer"
                            name="gst_amount"
                            value={formData.gst_amount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                        />
                        {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Project Mode</label>
                        <input
                            type="text"
                            name="project_mode"
                            value={formData.project_mode}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                        />
                        {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Concern Person<span className="required">*</span></label>
                        <input
                            type="text"
                            name="concern_person"
                            value={formData.concern_person}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                        />
                        {errors.concern_person && <span className="error-message">{errors.concern_person}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Confirm By</label>
                        <input
                            type="text"
                            name="confirm_by"
                            value={formData.confirm_by}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                        />
                        {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">  Confirmation Mode</label>
                        <input
                            type="text"
                            name="confirmation_mode"
                            value={formData.confirmation_mode}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                        />
                        {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Award Date</label>
                        <input
                            type="date"
                            name="award_date"
                            value={formData.award_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                        />
                        {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">WO Date</label>
                        <input
                            type="date"
                            name="wo_date"
                            value={formData.wo_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                        />
                        {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Start Date</label>
                        <input
                            type="date"
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                        />
                        {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Due Date</label>
                        <input
                            type="date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                        />
                        {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                    </div>

























                    <div className="flex-column form-group-selectt">
                        <label className="form-labels">Status:</label>
                        <br />
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleInputChange}
                            className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                        >
                            <option value="">Select a Status</option>
                            <option value="pending">Pending</option>
                            <option value="inprogress">In Progeress</option>
                            <option value="cancelled">Cancelled</option>
                            <option value="completed">Completed</option>
                            <option value="hold">Hold</option>
                            <option value="cancelled">Cancelled</option>


                        </select>
                        <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.status && (
                            <span className="error-message ">{errors.status}</span>
                        )}

                    </div>







                </div>


                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};


const AddClientDetails = () => {
    const [show, setShow] = useState(false);
    const [sub_CompanyList, setsub_CompanyList] = useState([]);
    const [formData, setFormData] = useState({

        name: '',
        contact_person: '',
        sub_Company: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: ''
    });

    const getAllsub_Company = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
            setsub_CompanyList(sub.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {

        }
    };


    const handleShow = () => {
        setShow(true);

    }
    useEffect(() => {

        getAllsub_Company(null);

    }, [])
    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(files)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue)
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "gstin_number",
            "pos",
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}


        if (validateForm()) {

            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();



                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                let res = await axios.post(
                    `${BASE_URL}/detail-design/client/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(res)
                if (res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    window.location.reload();
                } else {
                    // toast.error(`Error: `);
                }
            } catch (err) {
                // setShow(false);
                toast.error(err)
                handleErrorToast(err)
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }


    };



    return (
        <div className='modal-body-padding'>
            <form>
                <div className="form-flex-wrap">
                    <div title="name" className="flex-column">
                        <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                            Client Name
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="name"
                            name="name"
                            placeholder="Client Name"
                            onChange={handleInputChange}
                            value={formData.name}
                            className={`form-input ${errors.name
                                ? "error"
                                : inputState.name
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.name && (
                            <span className="error-message font-size-text ">{errors.name}</span>
                        )}
                    </div>

                    <div title="contact_person" className="flex-column">
                        <label htmlFor="contact_person" className="form-labels font-weight500 font-size-subheading">
                            Contact Person
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="contact_person"
                            name="contact_person"
                            placeholder="Contact Person"
                            onChange={handleInputChange}
                            value={formData.contact_person}
                            className={`form-input ${errors.contact_person
                                ? "error"
                                : inputState.contact_person
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.contact_person && (
                            <span className="error-message font-size-text ">{errors.contact_person}</span>
                        )}
                    </div>
                    <div title="sub_Company" className="flex-column form-group-selectt">
                        <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                            Sub Company <span className="required">*</span>
                        </label>
                        <br />
                        <select
                            id="sub_Company"
                            name="sub_Company"
                            value={formData.sub_Company}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                }`}
                        >
                            <option value="">Select a Sub Company</option>
                            {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.company_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                {console.log(i)}
                                <option value={i.id}>{i.title}</option>
                            </>))
                            }
                        </select>
                        <DropdownArrow />
                        <div>
                            {errors.sub_Company && (
                                <span className="error-message font-size-text ">{errors.sub_Company}</span>
                            )}
                        </div>
                    </div>
                    <div title="address" className="flex-column">
                        <label htmlFor="address" className="form-labels font-weight500 font-size-subheading">
                            Client Address
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="address"
                            name="address"
                            placeholder="Client Address"
                            onChange={handleInputChange}
                            value={formData.address}
                            className={`form-input ${errors.address
                                ? "error"
                                : inputState.address
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.address && (
                            <span className="error-message font-size-text ">{errors.address}</span>
                        )}
                    </div>

                    <div title="email" className="flex-column">
                        <label htmlFor="email" className="form-labels font-weight500 font-size-subheading">
                            Client Email
                        </label>
                        <input
                            type="email"
                            maxLength={100}
                            id="email"
                            name="email"
                            placeholder="Client Email"
                            onChange={handleInputChange}
                            value={formData.email}
                            className={`form-input ${errors.email
                                ? "error"
                                : inputState.email
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.email && (
                            <span className="error-message font-size-text ">{errors.email}</span>
                        )}
                    </div>

                    <div title="contact_number" className="flex-column">
                        <label htmlFor="contact_number" className="form-labels font-weight500 font-size-subheading">
                            Contact Number
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="contact_number"
                            name="contact_number"
                            placeholder="Contact Number"
                            onChange={handleInputChange}
                            value={formData.contact_number}
                            className={`form-input ${errors.contact_number
                                ? "error"
                                : inputState.contact_number
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.contact_number && (
                            <span className="error-message font-size-text ">{errors.contact_number}</span>
                        )}
                    </div>

                    <div title="alt_contact_number" className="flex-column">
                        <label htmlFor="alt_contact_number" className="form-labels font-weight500 font-size-subheading">
                            Alternate Contact Number
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="alt_contact_number"
                            name="alt_contact_number"
                            placeholder="Alternate Contact Number"
                            onChange={handleInputChange}
                            value={formData.alt_contact_number}
                            className={`form-input ${errors.alt_contact_number
                                ? "error"
                                : inputState.alt_contact_number
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.alt_contact_number && (
                            <span className="error-message font-size-text ">{errors.alt_contact_number}</span>
                        )}
                    </div>

                    <div title="gstin_number" className="flex-column">
                        <label htmlFor="gstin_number" className="form-labels font-weight500 font-size-subheading">
                            Client GST Number <span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="gstin_number"
                            name="gstin_number"
                            placeholder="Client GST Number"
                            onChange={handleInputChange}
                            value={formData.gstin_number}
                            className={`form-input ${errors.gstin_number
                                ? "error"
                                : inputState.gstin_number
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.gstin_number && (
                            <span className="error-message font-size-text ">{errors.gstin_number}</span>
                        )}
                    </div>

                    <div title="pos" className="flex-column">
                        <label htmlFor="pos" className="form-labels font-weight500 font-size-subheading">
                            Place of Supply (POS) <span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            maxLength={100}
                            id="pos"
                            name="pos"
                            placeholder="Place of Supply"
                            onChange={handleInputChange}
                            value={formData.pos}
                            className={`form-input ${errors.pos
                                ? "error"
                                : inputState.pos
                                    ? "success"
                                    : ""
                                }`}
                        />
                        {errors.pos && (
                            <span className="error-message font-size-text ">{errors.pos}</span>
                        )}
                    </div>

                    <div className="button-models" style={{ width: '100%' }}>
                        <button
                            onClick={handleFormSubmit}
                            className="model-button font-weight500 model-button-submit"
                        >
                            Add
                        </button>
                    </div>
                </div>


            </form>
        </div>
    );
};


const Createnewall = () => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [toggleState, setToggleState] = useState(2);

    let getDialogClassName = {}

    if (toggleState === 1) {
        getDialogClassName = 'dialog-modal-assign-task';
    } else if (toggleState === 2) {
        getDialogClassName = 'dialog-modal-add-client-details';
    } else if (toggleState === 3) {
        getDialogClassName = 'dialog-modal-add-client-details';
    }

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button>

            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Create New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="bloc-tabs">
                            {/* <button
                                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(1)}
                            >
                                Assign Task
                            </button> */}
                            <button
                                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(2)}
                            >
                                Add Poject Details
                            </button>
                            <button
                                className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                                onClick={() => setToggleState(3)}
                            >
                                Add Client Details
                            </button>
                        </div>
                        <div className="content-tabs dialogbox-div ">
                            {/* {toggleState === 1 ? <AssignTask /> : null} */}
                            {toggleState === 2 ? <AddProjectDetails /> : null}
                            {toggleState === 3 ? <AddClientDetails /> : null}
                        </div>
                    </>
                </Modal.Body>
                {/* <Modal.Footer>
                            <Button className="modal-cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="modal-button"
                                style={{ width: "140px" }}
                            >
                                Submit
                            </Button>
                        </Modal.Footer> */}
            </Modal>
        </>
    )
}




export default Createnewall