import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL_PREBID } from "../../../config/axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { formattedDate } from "../../../components/Criticalissue/Date";

const Invoice = ({ id }) => {

  //   ****************   Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getClientDetails();
  };



  const [formData, setFormData] = useState({
    billing_date: '',
    invoice_number: '',
    billamount: '',
    gst_amount: '',
    wihoutgst_amount: '',
    bill_percentage: '',
    design_stages: '',
    project: ''

  });


  const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);


  const [filteredWorkStagesNames, setFilteredWorkStagesNames] = useState([])
  const [totalPercentage, setTotalPercentage] = useState([])


  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/pendingforInvoiceforaccount/${id}/`
      );

      const ress = await axios.get(`${BASE_URL_PREBID}/highway/projectdetails/${id}/`)

      const workStages = res.data

      setAllWorkstagesStatus(res.data)


      const workStageNames = workStages.map(entry => entry.name).join(", ");
      setFilteredWorkStagesNames(workStageNames);



      // Calculate the sum of total percentage
      const totalPercentage = workStages.reduce((acc, entry) => acc + entry.percentage, 0);

      setTotalPercentage(totalPercentage);

      const woamount = ress.data.without_gst_amount; // Assuming total amount is stored in res.data[0].woamount

      const billPercentageDecimal = totalPercentage / 100;

      const billAmount = woamount * billPercentageDecimal;

      const gstPercentage = 0.18; // 18% GST rate in decimal form

      const gstAmount = billAmount * gstPercentage;

      setFormData({
        billamount: billAmount + gstAmount,
        gst_amount: gstAmount,
        wihoutgst_amount: billAmount,
        bill_percentage: totalPercentage,
        design_stages: workStageNames,
        project: id,

      })
    } catch (err) {
      alert(err.message);
    }
  };



  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      'invoice_number',


    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });




    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log(formData)


  const handleSubmit = async (e) => {
    e.preventDefault();


    const updatedFormData = {
      ...formData,
      billing_date: formattedDate

    }


    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL_PREBID}/highway/generateinvoice/${id}/`, updatedFormData,)

        if (res.status === 200) {
          window.location.reload();
        } else {
          ;
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };









  return (
    <>

      {/*     
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button> */}

      <button
        title="Generate Invoice"
        onClick={handleShow}
        className="upload-svg "

      >Generate Invoice{" "}

        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white' id="add"><path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path></svg>

      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='modal-body-padding'>
            <form >

              <div className='form-flex-wrap'>

                <div className="flex-column">
                  <label>Invoice Number:<span className="required">*</span></label>
                  <input
                    type="text"
                    name="invoice_number"
                    value={formData.invoice_number}
                    onChange={handleInputChange}
                    className={`form-input ${errors.invoice_number ? 'error' : inputState.invoice_number ? 'success' : ''}`}
                  />
                  {errors.invoice_number && <span className="error-message">{errors.without_gst_invoice_numbermount}</span>}
                </div>












              </div>

              <div className="button-models">
                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
              </div>
            </form>
          </div>




        </Modal.Body>
      </Modal>
    </>
  );
};

export default Invoice;
