import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";


const AddStage = ({ project, parent, getClientDetails, handleClose }) => {
  const [formData, setFormData] = useState({ project, name: "", parent });
  const [showInput, setShowInput] = useState(true);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = await axios.post(`${BASE_URL_PREBID}/highway/designstage/`, formData);
        if (res.status === 200) {
          if (typeof getClientDetails === 'function') {
            await getClientDetails();
          }
          handleClose();
        } else {
          setErrors({ apiError: "Failed to add stage. Please try again." });
        }
      } catch (err) {
        setErrors({ apiError: `Failed to add stage: ${err.message}` });
      }
    }
  };

  const handleRemoveInputField = () => {
    setShowInput(false);
  };

  return (
    <form>
      <div className="flex-row" style={{ marginLeft: parent ? "50px" : "0" }}>
        {showInput && (
          <>
            <div className="flex-column">
              <input
                type="text"
                name="name"
                placeholder="Enter Stage Name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-input-modal form-control-field"
              />
              {errors.name && <div className="error-message">{errors.name}</div>}
            </div>
            <div className="form-group stages-button">
              <button type="submit" className="add-stages-button" onClick={handleFormSubmit}>
                Add
              </button>
            </div>
            <div className="form-group stages-button">
              <button type="button" className="remove-stages-button" onClick={handleRemoveInputField}>
                Remove
              </button>
            </div>
          </>
        )}
      </div>
      {errors.apiError && <div className="error-message" style={{ color: 'red' }}>{errors.apiError}</div>}
    </form>
  );
};



const StageComponent = ({
  stage,
  onAddSubStage,
  onPercentageChange,
  onStageNameChange,
  onCompleteChange,
  calculateTotalPercentage,
  allWorkStagesStatus,
  getClientDetails
}) => {
  const { id, name, project, sub_stages, percentage, completed, invoice_generated } = stage;
  const [showAddStageForm, setShowAddStageForm] = useState(false);
  const [currentStageName, setCurrentStageName] = useState(name);
  const [currentPercentage, setCurrentPercentage] = useState(percentage || 0);
  const [isCompleted, setIsCompleted] = useState(completed);
  const [percentageError, setPercentageError] = useState("");

  const handleAddSubStage = (newSubStage) => {
    onAddSubStage(id, newSubStage);
    setShowAddStageForm(false);
  };

  const handleStageNameChange = (e) => {
    const newStageName = e.target.value;
    setCurrentStageName(newStageName);
    onStageNameChange(id, newStageName);
  };

  const handlePercentageChange = (e) => {
    const newPercentage = parseFloat(e.target.value);
    if (isNaN(newPercentage) || newPercentage < 0 || newPercentage > 100) return;

    const totalWithoutCurrent = calculateTotalPercentage(allWorkStagesStatus.filter(stage => stage.id !== id));
    if (totalWithoutCurrent + newPercentage > 100) {
      setPercentageError("Total percentage exceeds 100%");
      return;
    }

    setPercentageError("");
    setCurrentPercentage(newPercentage);
    onPercentageChange(id, newPercentage);
  };

  const handleCompletedChange = (e) => {
    const newCompleted = e.target.checked;
    setIsCompleted(newCompleted);
    onCompleteChange(id, newCompleted);
  };

  const handleShowAddStageForm = () => {
    setShowAddStageForm(false);
    setTimeout(() => setShowAddStageForm(true), 0);
  };

  return (
    <div className="stage">
      <li>
        <div className='field-cont'>
          <div className="flex-column">
            <label className="form-labels">Stage Name:</label>
            <input
              type="text"
              value={currentStageName}
              disabled={invoice_generated}
              className="form-input-modal"
              onChange={handleStageNameChange}
            />
          </div>
          {sub_stages.length === 0 && (
            <>
              <div className="flex-column">
                <label className="form-labels">Percentage:</label>
                <input
                  type="number"
                  value={currentPercentage}
                  onChange={handlePercentageChange}
                  max="100"
                  min="0"
                  disabled={invoice_generated}
                  className="form-input-modal-percentage"
                />
                {percentageError && <div className="error-message width-10vw" style={{ color: 'red', marginTop: '5px' }}>{percentageError}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels">Completed:</label>
                <input
                  type="checkbox"
                  checked={isCompleted}
                  onChange={handleCompletedChange}
                  disabled={invoice_generated}
                  className="form-input-modal-checkbox"
                />
              </div>
              <div className="flex-column">
                <label className="form-labels">Invoice Generated:</label>
                <input
                  type="checkbox"
                  checked={invoice_generated}
                  disabled
                  className="form-input-modal-checkbox"
                />
              </div>
            </>
          )}
        </div>
      </li>
      {sub_stages.length > 0 && (
        <ol className="sub-stages">
          {sub_stages.map((subStage) => (
            <StageComponent
              key={subStage.id}
              stage={subStage}
              onAddSubStage={onAddSubStage}
              onPercentageChange={onPercentageChange}
              onStageNameChange={onStageNameChange}
              onCompleteChange={onCompleteChange}
              calculateTotalPercentage={calculateTotalPercentage}
              allWorkStagesStatus={allWorkStagesStatus}
              getClientDetails={getClientDetails}
            />
          ))}
        </ol>
      )}
      {showAddStageForm && (
        <AddStage
          project={project}
          parent={id}
          getClientDetails={getClientDetails}
          handleClose={() => setShowAddStageForm(false)}
        />
      )}
      {/* Hide button if percentage is added for a substage, but keep root stage button visible */}
      {currentPercentage === 0 && (
        <button
          onClick={handleShowAddStageForm}
          className="add-stages-button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#2576BC" />
          </svg>
          Substage inside {name}
        </button>
      )}
    </div>
  );
};


const StageComponentt = ({ stage, onPercentageChange }) => {
  const { id, name, sub_stages, percentage } = stage;

  const handleInputChange = (e) => {
    const newPercentage = e.target.value;
    onPercentageChange(id, newPercentage);
  };

  const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);

  const [inputFields, setInputFields] = useState([{}]);
  const handleChange = (index, e) => {
    const { name, checked } = e.target;
    const updatedFields = [...inputFields]; // Assuming inputFields is your state holding the array of objects
    updatedFields[index][name] = checked; // Update only the completed field of the object at the specified index
    setInputFields(updatedFields);
  };

  return (
    <div className="stage">
      <li className="list-style">
        <div className="stage-list">
          <h3 className="stage-div" style={{ marginRight: "20px" }}>
            {name}
          </h3>
          {/* {sub_stages.length === 0 && (
                         <input
                         name="completed"
                         type="checkbox"
                         value={sub_stages.completed}
                         checked={sub_stages.completed ? true : false}
                         readOnly
                           onChange={(e) => handleChange(index, e)}
                         className="form-checkbox"
                       />
                    )} */}

          <>
            <div className="form-flex">
              <div className="form-group">
                <label>Completed:</label>
                {sub_stages.completed ? (
                  <input
                    name="completed"
                    type="checkbox"
                    value={sub_stages.completed}
                    checked={sub_stages.completed ? true : false}
                    readOnly
                    //   onChange={(e) => handleChange(index, e)}
                    className="form-checkbox"
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="completed"
                    value={sub_stages.completed}
                    checked={sub_stages.completed ? true : false}
                    onChange={(e) => handleChange(e)}
                    className="form-checkbox"
                  />
                )}
                {/* <input
                                                type="checkbox"
                                                value={data.completed}
                                                // checked={data.completed ? true : false}
                                                // readOnly={data.completed ? true : false}
                                                onChange={(e) => handleChange(index, e)}
                                                className="form-checkbox" /> */}
              </div>
            </div>
          </>
        </div>
      </li>
      {sub_stages.length > 0 && (
        <ol className="sub-stages">
          {sub_stages.map((subStage) => (
            <StageComponentt
              key={subStage.id}
              stage={subStage}
              onPercentageChange={onPercentageChange}
            />
          ))}
        </ol>
      )}
    </div>
  );
};



const UpdateStages = ({ id, woamount }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getClientDetails()
  };
  const [allWorkStagesStatus, setAllWorkStagesStatus] = useState([]);
  const [showAddRootStageForm, setShowAddRootStageForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [percentageError, setPercentageError] = useState(""); // New state

  // useEffect(() => {
  //   getClientDetails();
  // }, []);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/designstagefilter/${id}/`
      );
      setAllWorkStagesStatus(res.data);
    } catch (err) {
      setErrorMessage(`Failed to fetch client details: ${err.message}`);
    }
  };

  const calculateTotalPercentage = (stages) => {
    let total = 0;
    stages.forEach((stage) => {
      total += stage.percentage || 0;
      if (stage.sub_stages && stage.sub_stages.length > 0) {
        total += calculateTotalPercentage(stage.sub_stages);
      }
    });
    return total;
  };

  const updateStageField = (stages, id, field, value) => {
    return stages.map((stage) => {
      if (stage.id === id) {
        return { ...stage, [field]: value };
      }
      if (stage.sub_stages && stage.sub_stages.length > 0) {
        return { ...stage, sub_stages: updateStageField(stage.sub_stages, id, field, value) };
      }
      return stage;
    });
  };

  const handlePercentageChange = (id, newPercentage) => {


    const totalWithoutCurrent = calculateTotalPercentage(
      allWorkStagesStatus.filter(stage => stage.id !== id)
    );

    if (totalWithoutCurrent + newPercentage > 100) {
      setErrorMessage("Adding this percentage would exceed 100%. Please adjust the value.");
      setIsSubmitDisabled(true);
      setPercentageError("Total percentage exceeds 100%");
      return;
    }

    setErrorMessage("");
    setIsSubmitDisabled(false);
    setPercentageError(""); // Clear percentageError if no issue

    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'percentage', newPercentage)
    );
  };


  const handleStageNameChange = (id, newStageName) => {
    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'name', newStageName)
    );
  };

  const handleCompletedChange = (id, newCompleted) => {
    setAllWorkStagesStatus((prevStages) =>
      updateStageField(prevStages, id, 'completed', newCompleted)
    );
  };

  const handleSubmit = async () => {
    const totalPercentage = calculateTotalPercentage(allWorkStagesStatus);

    // Check if any stage has a percentage of 0 or less
    const hasInvalidPercentages = allWorkStagesStatus.some((stage) => {
      return stage.percentage <= 0 || stage.percentage === null;
    });



    if (totalPercentage > 100) {
      setErrorMessage("The total percentage exceeds 100%. Please adjust the stages.");
      setIsSubmitDisabled(true);
      return;
    }

    try {
      await axios.put(
        `${BASE_URL_PREBID}/highway/designstage-hierarchy/${id}/`,
        allWorkStagesStatus
      );
      alert("Data updated successfully.");
      setErrorMessage(""); // Clear any existing errors on success
      setIsSubmitDisabled(false); // Enable the submit button after successful update
      handleClose()
    } catch (err) {
      setErrorMessage(`Failed to update stages: ${err.message}`);
    }
  };

  const handleShowAddStageForm = () => {
    setShowAddRootStageForm(false);
    setTimeout(() => setShowAddRootStageForm(true), 0);
  }


  return (
    <>
      <button title="Add Stages" className="upload-svga" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          enable-background="new 0 0 32 32"
          viewBox="0 0 32 32"
          fill="white"
          id="add"
        >
          <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-add-client-details"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Manage Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ol>
              {allWorkStagesStatus.map((stage) => (
                <StageComponent
                  key={stage.id}
                  stage={stage}
                  getClientDetails={getClientDetails}
                  onPercentageChange={handlePercentageChange}
                  onStageNameChange={handleStageNameChange}
                  onCompleteChange={handleCompletedChange}
                  calculateTotalPercentage={calculateTotalPercentage}
                  allWorkStagesStatus={allWorkStagesStatus}
                  setPercentageError={setPercentageError}
                />
              ))}
            </ol>
            <button
              onClick={handleShowAddStageForm}
              className="add-stages-button"
              style={{ marginTop: "10px" }}
            >
              Add Root Stage
            </button>
            {showAddRootStageForm && (
              <AddStage
                project={id}
                parent={null}
                getClientDetails={getClientDetails}
                handleClose={() => setShowAddRootStageForm(false)}
              />
            )}

            <button
              onClick={handleSubmit}
              className="model-button model-button-submit"
              disabled={isSubmitDisabled}
            >
              Submit
            </button>
            {errorMessage && (
              <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
                {errorMessage}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};



const WorkorderamountAddUpdate = ({ id, woamount }) => {
  const [formData, setFormData] = useState({
    woamount: "",
    gst_amount: "",
    without_gst_amount: "",
  });

  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = ["without_gst_amount"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitWo = async (e) => {
    e.preventDefault();

    const gstPercentage = 0.18; // 18% GST rate in decimal form
    const withoutGstAmount = parseFloat(formData.without_gst_amount);
    // Calculate the GST amount
    const gstAmount = formData.without_gst_amount * gstPercentage;
    const woAmount = withoutGstAmount + gstAmount;

    console.log(withoutGstAmount + gstAmount);

    const updatedFormData = {
      ...formData,
      gst_amount: gstAmount,
      woamount: woAmount,
    };

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL_PREBID}/highway/projectupdate/${id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          window.location.reload();
        } else {
          ;
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  console.log(woamount)


  return (
    <>
      {woamount === null ? (
        <div className="form-flex">
          <div className="flex-column">
            <label>
              Work Order Amount Without Gst:
              <span className="required">*</span>
            </label>
            <input
              type="text"
              name="without_gst_amount"
              value={formData.without_gst_amount}
              onChange={handleInputChange}
              className={`form-input ${errors.without_gst_amount
                ? "error"
                : inputState.without_gst_amount
                  ? "success"
                  : ""
                }`}
            />
            {errors.without_gst_amount && (
              <span className="error-message">
                {errors.without_gst_amount}
              </span>
            )}
          </div>

          <div className="form-group stages-button">
            <div>
              <button
                className="add-stages-button"
                type="button"
                onClick={handleSubmitWo}
              >
                Add WorkOrder Amount
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

const UpdateStagess = ({ id, woamount }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [allWorkStagesStatus, setAllWorkStagesStatus] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [showAddRootStageForm, setShowAddRootStageForm] = useState(false);

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/designstagefilter/${id}/`
      );
      setAllWorkStagesStatus(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const calculateTotalPercentage = (stages) => {
    let total = 0;
    const calculate = (stages) => {
      stages.forEach((stage) => {
        if (stage.percentage) {
          total += parseFloat(stage.percentage);
        }
        if (stage.sub_stages.length > 0) {
          calculate(stage.sub_stages);
        }
      });
    };
    calculate(stages);
    console.log(total)
    return total;
  };



  const handlePercentageChange = (id, newPercentage) => {
    const updateStages = (stages) => {
      return stages.map((stage) => {
        if (stage.id === id) {
          return { ...stage, percentage: newPercentage };
        }
        if (stage.sub_stages.length > 0) {
          return { ...stage, sub_stages: updateStages(stage.sub_stages) };
        }
        return stage;
      });
    };
    setAllWorkStagesStatus((prevStages) => updateStages(prevStages));

    const updatedStages = updateStages(allWorkStagesStatus);
    const newTotalPercentage = calculateTotalPercentage(updatedStages);

    if (newTotalPercentage <= 100) {
      setAllWorkStagesStatus(updatedStages);
      setTotalPercentage(newTotalPercentage);
    } else {
      alert("Total percentage cannot exceed 100.");
    }
  };

  const handleSubmit = async () => {
    try {
      await axios.put(
        `${BASE_URL_PREBID}/highway/designstage-hierarchy/${id}/`,
        allWorkStagesStatus
      );
      alert("Percentage values updated successfully.");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <button title="Something" className="upload-svga" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          enable-background="new 0 0 32 32"
          viewBox="0 0 32 32"
          fill="white"
          id="add"
        >
          <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Manage Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <ol>
                {allWorkStagesStatus.map((stage) => (
                  <StageComponent
                    key={stage.id}
                    stage={stage}
                    getClientDetails={getClientDetails}
                    onPercentageChange={handlePercentageChange}
                  />
                ))}
              </ol>
              <button
                onClick={() => setShowAddRootStageForm(true)}
                className="add-stages-button"

              >
                Add Root Stage
              </button>
              {showAddRootStageForm && (
                <AddStage
                  project={id}
                  parent={null}
                  getClientDetails={getClientDetails}
                  handleClose={() => setShowAddRootStageForm(false)}
                />
              )}
            </div>
            <button onClick={handleSubmit} className="model-button model-button-submit">
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateStagesaa = ({ id, woamount }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [allWorkStagesStatus, setAllWorkStagesStatus] = useState([]);
  const [showAddRootStageForm, setShowAddRootStageForm] = useState(false);

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/highway/designstagefilter/${id}/`
      );
      setAllWorkStagesStatus(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handlePercentageChange = (id, newPercentage) => {
    const updateStages = (stages) => {
      return stages.map((stage) => {
        if (stage.id === id) {
          return { ...stage, percentage: newPercentage };
        }
        if (stage.sub_stages.length > 0) {
          return { ...stage, sub_stages: handlePercentageChange(stage.sub_stages) };
        }
        return stage;
      });
    };
    setAllWorkStagesStatus((prevStages) => updateStages(prevStages));

    const updatedStages = updateStages(allWorkStagesStatus);

    setAllWorkStagesStatus(updatedStages)

  };



  const handleSubmit = async () => {
    try {
      await axios.put(
        `${BASE_URL_PREBID}/highway/designstage-hierarchy/${id}/`,
        allWorkStagesStatus
      );
      alert("Percentage values updated successfully.");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <button title="Add Stages" className="upload-svga" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          enable-background="new 0 0 32 32"
          viewBox="0 0 32 32"
          fill="white"
          id="add"
        >
          <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-add-client-details"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Manage Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* Displaying all work stages */}
            <div>
              <ol>
                {allWorkStagesStatus.map((stage) => (
                  <StageComponent
                    key={stage.id}
                    stage={stage}
                    getClientDetails={getClientDetails}
                    onPercentageChange={handlePercentageChange}

                  />
                ))}
              </ol>
              <button
                onClick={() => setShowAddRootStageForm(true)}
                className="add-stages-button"
                style={{ marginTop: "10px" }}
              >
                Add Root Stage
              </button>
              {showAddRootStageForm && (
                <AddStage
                  project={id}
                  parent={null}
                  getClientDetails={getClientDetails}
                  handleClose={() => setShowAddRootStageForm(false)}
                />
              )}
            </div>
            <button onClick={handleSubmit} className="model-button model-button-submit">
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};





export default UpdateStages;