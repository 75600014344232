
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableDesigns from './tabledetails';

const Detaildesignmain = () => {


    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="Innovation" name={"Innovation"} />
            <TableDesigns />
        </>
    );
};


export default Detaildesignmain