import axios from "axios";
import React, { useState } from "react";
import { BASE_URL_PREBID } from "../../../config/axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ReceivedAmount = ({ id, maxlimitreceive }) => {
  // Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    rec_date: "",
    amount_recived: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['rec_date', 'amount_recived'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });


    // Check if received amount exceeds the max limit
    if (formData.amount_recived > maxlimitreceive) {
      newErrors['amount_recived'] = `Received amount cannot exceed ${maxlimitreceive}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(`${BASE_URL_PREBID}/highway/invoiceUpdate/${id}/`, formData);

        if (res.status === 200) {
          window.location.reload();
        } else {
          ;
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Recieved Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-body-padding'>
            <form>
              <div className='form-flex-wrap'>
                <div className="flex-column">
                  <label>Received Date:<span className="required">*</span></label>
                  <input
                    type="date"
                    name="rec_date"
                    value={formData.rec_date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.rec_date ? 'error' : inputState.rec_date ? 'success' : ''}`}
                  />
                  {errors.rec_date && <span className="error-message">{errors.rec_date}</span>}
                </div>

                <div className="flex-column">
                  <label>Received Amount:<span className="required">*</span></label>
                  <input
                    type="number"
                    name="amount_recived"
                    value={formData.amount_recived}
                    onChange={handleInputChange}
                    max={maxlimitreceive}  // Set the max limit here
                    className={`form-input ${errors.amount_recived ? 'error' : inputState.amount_recived ? 'success' : ''}`}
                  />
                  {errors.amount_recived && <span className="error-message">{errors.amount_recived}</span>}
                </div>
              </div>

              <div className="button-models">
                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReceivedAmount;
