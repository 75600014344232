import { AddwithWhiteCircle, Attachment, DropdownArrow } from "./AllSvg";
import CreatableSelect from 'react-select/creatable';


const statesAndUTs = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka",
    "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram",
    "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
    "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
    "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi",
    "Puducherry", "Ladakh", "Jammu and Kashmir"
];


const toTitleCase = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};



const toCamelCase = (str) => {
    if (!str) return '';
    return str.charAt(0).toLowerCase() + str.slice(1);
};

const DocumentSection = ({ formData, handleDocInputChange, handleAddfiles, handleRemoveDocument, errors, fileNames, inputState }) => (
    <>
        <div style={{ width: '100%' }}>
            <div className="jd-heading-outer heading-bg-color-white">
                <div className="jd-heading-outer-flex">
                    <h2 className="jd-heading-main font-weight600 font-size-heading">Documents</h2>
                    <button
                        title="Add Documents"
                        onClick={handleAddfiles}
                        className="upload-svg"
                        style={{ float: "right" }}
                        type="button" // Set the button type to "button"
                    >
                        <AddwithWhiteCircle /> Add Document
                    </button>
                </div>
                <div>
                    <div className="jd-heading-bottom-bold"></div>
                    <div className="jd-heading-bottom-light"></div>
                </div>
            </div>
            {console.log(formData.files)}
            {formData.files.length > 0 && formData?.files?.map((doc, index) => (
                <div key={index} className="form-flex-wrap">
                    <div className="flex-column">
                        <label htmlFor={`document_name_${index}`} className="form-labels font-weight500 font-size-subheading">
                            File Name <span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            id={`document_name_${index}`}
                            name="document_name"
                            placeholder="Document Name"
                            value={doc.document_name}
                            onChange={(e) => handleDocInputChange(index, e)}
                            className={`form-input ${errors[`document_name_${index}`] ? 'error' : inputState[`document_name`] ? 'success' : ''}`}
                        />
                        {errors[`document_name_${index}`] && (
                            <span className="error-message font-size-text">{errors[`document_name_${index}`]}</span>
                        )}
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor={`document_file_${index}`} className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File <span className="required">*</span>
                        </label>
                        <label
                            htmlFor={`document_file_${index}`}
                            className={` custom-file-upload ${errors[`document_file_${index}`] ? 'error' : inputState[`document_file`] ? 'success' : ''}`}
                        >
                            <div className={`align-center pdf-input  ${errors[`document_file_${index}`] ? 'error' : ''}`}>
                                <Attachment />
                            </div>
                        </label>
                        <input
                            id={`document_file_${index}`}
                            name="document_file"
                            type="file"
                            onChange={(e) => handleDocInputChange(index, e)}
                            style={{ display: 'none', position: 'relative' }}
                        />
                        {fileNames[index] && <p className="file-name align-center">{fileNames[index]}</p>}
                        <div>
                            {errors[`file_${index}`] && (
                                <span className="error-message font-size-text">{errors[`file_${index}`]}</span>
                            )}
                        </div>
                    </div>

                    {index > 0 && (
                        <button type="button" className="model-button-cancel" onClick={() => handleRemoveDocument(index)}>
                            Remove
                        </button>
                    )}
                </div>
            ))}
        </div>
    </>
);



const RenderInputField = ({ title, name, required, type = 'text', placeholder, max, formData, errors, inputState, handleInputChange }) => (
    <div title={title} className="flex-column">
        <label htmlFor={name} className="form-labels font-weight500 font-size-subheading">
            {title}{required ? <span className="required">*</span> : null}
        </label>
        <input
            type={type}
            id={name}
            name={name}
            max={max}
            placeholder={placeholder}
            value={formData[name]}
            onChange={handleInputChange}
            className={`form-input ${errors[name] ? 'error' : inputState[name] ? 'success' : ''}`}
        />
        {/* {errors[name] && <span className="error-message font-size-text">{errors[name]}</span>} */}
    </div>
);


const RenderInputTextareaField = ({
    title,
    name,
    required,
    placeholder,
    max, // Optional: max length
    formData,
    errors,
    inputState,
    handleInputChange,
    rows = 5, // Default value
    cols = 50 // Default value
}) => (
    <div title={title} className="flex-column">
        <label htmlFor={name} className="form-labels font-weight500 font-size-subheading">
            {title}
            {required ? <span className="required">*</span> : null}
        </label>
        <textarea
            id={name}
            name={name}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            value={formData[name]}
            onChange={handleInputChange}
            maxLength={max} // Optional max length attribute
            className={`form-textarea ${errors[name] ? 'error' : inputState[name] ? 'success' : ''}`}
            style={{ whiteSpace: 'pre-wrap' }} // Preserve new lines and spaces
        />
        {/* {errors[name] && <span className="error-message font-size-text">{errors[name]}</span>} */}
    </div>
);




const RenderSelectField = ({ title, name, required, options, placeholder, disabled, formData, errors, inputState, handleInputChange, multiple = false }) => (
    <div title={title} className="flex-column form-group-selectt">
        <label htmlFor={name} className="form-labels font-weight500 font-size-subheading">
            {title}{required ? <span className="required">*</span> : null}
        </label>
        <br />
        <select
            id={name}
            name={name}
            value={formData[name]}
            onChange={handleInputChange}
            className={`form-input form-group-selection ${errors[name] ? 'error' : inputState[name] ? 'success' : ''}`}
            disabled={disabled}
            multiple={multiple}
        >
            <option value="null" disabled>{placeholder}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
            ))}
        </select>
        <DropdownArrow />
        {/* {errors[name] && <span className="error-message font-size-text">{errors[name]}</span>} */}
    </div>
);


// const ReusableCreatableSelect = ({
//   title, name, options, placeholder, disabled, formData, errors, inputState, handleInputChange, multiple = false,

//   options,
//   value,
//   onChange,
//   placeholder = "Select...",
//   isClearable = true,
//   error,
//   displayFormat = (item) => item.label,
//   valueFormat = (item) => item.value,
//   styles = {},
// }) => {
//   // Transform the options into the expected format for CreatableSelect
//   const formattedOptions = options?.map(item => ({
//     value: valueFormat(item),
//     label: displayFormat(item),
//   }));

//   const selectedValue = value
//     ? {
//       value: valueFormat(value),
//       label: displayFormat(value),
//     }
//     : null;

//   return (
//     <div>
//       <CreatableSelect
//         options={formattedOptions}
//         value={selectedValue}
//         onChange={(option) => {
//           onChange(option ? valueFormat(option) : "");
//         }}
//         placeholder={placeholder}
//         isClearable={isClearable}
//         styles={{
//           control: (provided) => ({
//             ...provided,
//             width: '33vw',
//             height: '50px',
//             borderRadius: '10px',
//             border: '1px solid #e3e3e3',
//             margin: '10px 0px 20px 0px',
//             color: '#4a4a4a',
//             backgroundColor: '#f7f8fa',
//             padding: '0px 10px',
//           }),
//           placeholder: (provided) => ({
//             ...provided,
//             color: '#4a4a4a',
//           }),
//           menu: (provided) => ({
//             ...provided,
//             width: '33vw',
//             borderRadius: '10px',
//             marginTop: '0',
//           }),
//           singleValue: (provided) => ({
//             ...provided,
//             color: '#4a4a4a',
//           }),
//           input: (provided) => ({
//             ...provided,
//             color: '#4a4a4a',
//           }),
//           ...styles, // Spread any additional styles passed in
//         }}
//       />
//       {error && <span className="error-message font-size-text">{error}</span>}
//     </div>
//   );
// };


const InputTextField = ({
    title,
    htmlFor,
    name,
    type = "text",
    placeholder,
    onChange,
    value,
    errors,
    inputState
}) => {

    console.log(inputState[name])
    return (
        <div title={title} className="flex-column">
            <label htmlFor={htmlFor} className="form-labels font-weight500 font-size-subheading">
                {title}<span className="required">*</span>
            </label>
            <input
                type={type}
                id={htmlFor}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={`form-input ${errors[name] ? "error" : inputState[name] ? "success" : ""}`}
            />
            {errors[name] && <span className="error-message font-size-text">{errors[name]}</span>}
        </div>
    );
};




export { statesAndUTs, toTitleCase, toCamelCase, InputTextField, DocumentSection, RenderInputField, RenderSelectField, RenderInputTextareaField }