import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { AddwithWhiteCircle, DropdownArrow, SearchBig, UpdatePencil, PDFIcon, DeleteDustbin, Attachment } from "../AllSvg";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { ViewEmployeeList } from "../CustomFunctions";
import { DocumentSection } from "../FormdataComponent";
import { handleErrorToast } from "../CustomFunctions";
import { toast, ToastContainer } from 'react-toastify';
const AddReviewteam = ({ getReviewteam }) => {
    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
    const [structureHeadingList, setStructureHeadingList] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [reviewTeam, SetReviewTeam] = useState([]);

    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
            );

            setAllEmployeeData(res.data);
        } catch (err) {
            alert(err.message);
        }
    };
    const getReviewteamdetails = async () => {
        const result = await axios.get(`${BASE_URL}/detail-design/reviewteam/`);
        SetReviewTeam(result.data);
    };


    const getAllSubheadings = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/structureheading`);
            setStructureHeadingList(sub.data);
        } catch (err) {

        } finally {

        }
    };
    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState({
        date: '',
        uploaded_by: '',
        project: '',
        structure_heading: '',
        files: [{ document_name: "", file: "" }],
        review_team: '',
    });
    const handleDocInputChange = (index, e) => {
        const { name, value, files } = e.target;

        setFormData((prevFormData) => {
            const newfiles = [...prevFormData.files];
            newfiles[index] = {
                ...newfiles[index],
                [name]: files ? files[0] : value, // Removed .trim()
            };

            if (files) {
                setFileNames((prevFileNames) => {
                    const newFileNames = [...prevFileNames];
                    newFileNames[index] = files[0]?.name || "";
                    return newFileNames;
                });
            }

            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name === "document_name" && value.trim()) {
                    delete updatedErrors[`document_name_${index}`];
                }
                if (name === "file" && files?.[0]) {
                    delete updatedErrors[`file_${index}`];
                }
                return updatedErrors;
            });

            return { ...prevFormData, files: newfiles };
        });
    };

    const handleAddfiles = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: [
                ...prevFormData.files,
                { document_name: "", file: null },
            ],
        }));
    };


    const handleRemoveDocument = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index),
        }));
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, i) => i !== index)
        );
    };
    const handleShow = () => {
        setShow(true);
        getEmployeeDetails()
        getAllSubheadings()

        getReviewteamdetails()
    }

    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
        setFileNames(() => {
            return new Array(fileNames.length).fill('');
        });
        setErrors({});

    }
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;

        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const validateform = () => {
        const newErrors = {};
        const requiredFields = [
            "date",
            "structure_heading",
            "uploaded_by",
            "review_team"
        ];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });
        formData.files.forEach((doc, index) => {
            if (!doc.document_name || doc.document_name.trim() === "") {
                newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
            }

            if (!doc.document_file) {
                newErrors[`file_${index}`] = `Document File is required for document ${index + 1}`;
            }
        });
        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };
    const generateRandomString = () => {
        const chars =
            "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from(
            { length: 8 },
            () => chars[Math.floor(Math.random() * chars.length)]
        ).join("");
    };
    const createCustomFile = (originalFile) => {
        const randomCode = generateRandomString();
        const now = new Date();
        const date = now.toISOString().split("T")[0];
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
        const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
        return new File([originalFile], customFileName, {
            type: originalFile.type,
        });
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // const newerror = {}

        console.log("errors")
        console.log(errors)
        if (validateform()) {
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const formDataToSend = new FormData();
                Object.keys(formData).forEach((key) => {
                    if (formData[key] && key !== "files") {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                formData.files.forEach((doc, index) => {


                    if (doc.document_file) {
                        // Assuming 'doc.file' is the file object and 'doc.document_name' is the name of the document
                        const customFile = createCustomFile(doc.document_file);  // Assuming this function processes the file

                        // Correcting the syntax and appending the file and document name
                        formDataToSend.append(`documents[${index}][document_name]`, doc.document_name);
                        formDataToSend.append(`documents[${index}][document_file]`, customFile);
                    }
                });
                let res = await axios.post(
                    `${BASE_URL}/detail-design/reviewfileBulk/`,
                    // `${BASE_URL}/detail-design/reviewfileBulk/`     reviewbulkapi url,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200 || res.status === 201) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getReviewteam();
                } else { }
            } catch (err) {
                // handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }


    };
    const options = allEmployeeData
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));



    const selectEmployee = (selected) => {
        // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
        const uploaded_by = selected?.map((item) => item.value) || [];
        if (uploaded_by.length > 0) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                console.log(updatedErrors, updatedErrors.uploaded_by)
                delete updatedErrors.uploaded_by;
                return updatedErrors;
            });
        }
        setFormData((prevState) => ({
            ...prevState,
            uploaded_by,
        }));
    };


    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add File
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Review File Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div className="flex-column">
                                <label> Date:<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.date ? 'error' : inputState.date ? 'success' : ''}`}
                                />
                                {errors.date && <span className="error-message">{errors.date}</span>}
                            </div>
                            <div className="flex-column form-group-select">
                                <label className="form-labels">
                                    Uploaded by:<span className="required">*</span>
                                </label>

                                <Select
                                    options={options}
                                    isMulti
                                    onChange={selectEmployee}
                                    value={formData.assignedto?.map((code) => ({
                                        value: code,
                                        label: options.find((opt) => opt.value === code)?.label || code,
                                    }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '33vw',
                                            minHeight: '50px',
                                            borderRadius: '10px',
                                            border: '1px solid #e3e3e3',
                                            margin: '10px 0px 20px 0px',
                                            color: '#4a4a4a',
                                            backgroundColor: '#f7f8fa',
                                            padding: '0px 10px',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            marginTop: '0',
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                    }}
                                />

                                {errors.uploaded_by && (
                                    <span className="error-message font-size-text ">{errors.uploaded_by}</span>
                                )}
                            </div>


                            <div title="structure_heading" className="flex-column form-group-selectt">
                                <label htmlFor="structure_heading" className="form-labels font-weight500 font-size-subheading">
                                    Structure Heading <span className="required">*</span>
                                </label>
                                <br />
                                {structureHeadingList.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>
                                        <select
                                            id="structure_heading"
                                            name="structure_heading"
                                            value={formData.structure_heading} // Changed from `project` to `structure_heading`
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.structure_heading ? "error" : inputState.structure_heading ? "success" : ""}`}
                                        >
                                            <option value="">Select a Structure Heading</option>
                                            {structureHeadingList?.sort((a, b) => a.heading_name.localeCompare(b.heading_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.heading_name} {/* Adjusted to show the structure name */}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.structure_heading && (
                                                <span className="error-message font-size-text ">{errors.structure_heading}</span>
                                            )}
                                        </div>
                                    </>}
                            </div>

                            <div title="review_team" className="flex-column form-group-selectt">
                                <label htmlFor="review_team" className="form-labels font-weight500 font-size-subheading">
                                    Review Team <span className="required">*</span>
                                </label>
                                <br />
                                {reviewTeam.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>
                                        <select
                                            id="review_team"
                                            name="review_team"
                                            value={formData.review_team} // Changed from `structure_heading` to `review_team`
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.review_team ? "error" : inputState.review_team ? "success" : ""}`}
                                        >
                                            <option value="">Select a Review Team</option>
                                            {reviewTeam?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.name} {/* Adjusted to show the review team name */}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.review_team && (
                                                <span className="error-message font-size-text ">{errors.review_team}</span>
                                            )}
                                        </div>
                                    </>}
                            </div>
                            <DocumentSection
                                formData={formData}
                                handleDocInputChange={handleDocInputChange}
                                handleAddfiles={handleAddfiles}
                                handleRemoveDocument={handleRemoveDocument}
                                errors={errors}
                                fileNames={fileNames}
                                inputState={inputState}
                            />

                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                //   disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
const UpdateReviewfile = ({
    i,
    getReviewteam,
}) => {

    const [errors, setErrors] = useState({});
    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
    const [reviewTeam, SetReviewTeam] = useState([]);
    const [structureHeadingList, setStructureHeadingList] = useState([]);
    const [addDocumentation, setAddDocumentation] = useState(false);
    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
            );

            setAllEmployeeData(res.data);
        } catch (err) {
            handleErrorToast(err);
        }
    };
    const getReviewteamdetails = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/reviewteam/`);
            SetReviewTeam(result.data);
        } catch (err) {
            handleErrorToast(err);
        }

    };


    const getAllSubheadings = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/structureheading`);
            setStructureHeadingList(sub.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {

        }
    };


    const [show, setShow] = useState(false);


    const [formData, setFormData] = useState({
        date: '',
        uploaded_by: '',
        uploaded_by_detail: [],
        project: '',
        structure_heading: '',
        documents: [{ document_name: "", document_file: "" }],
        review_team: [],
    });

    const [fileNames, setFileNames] = useState([]);
    const handleDocInputChange = (index, e) => {
        const { name, value, files } = e.target;

        setFormDataDoc((prevFormData) => {
            const newfiles = [...prevFormData.files];
            newfiles[index] = {
                ...newfiles[index],
                [name]: files ? files[0] : value, // Removed .trim()
            };

            if (files) {
                setFileNames((prevFileNames) => {
                    const newFileNames = [...prevFileNames];
                    newFileNames[index] = files[0]?.name || "";
                    return newFileNames;
                });
            }

            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name === "document_name" && value.trim()) {
                    delete updatedErrors[`document_name_${index}`];
                }
                if (name === "file" && files?.[0]) {
                    delete updatedErrors[`file_${index}`];
                }
                return updatedErrors;
            });

            return { ...prevFormData, files: newfiles };
        });
    };

    const handleAddfiles = () => {
        if (addDocumentation) {
            setFormDataDoc((prevFormData) => ({
                ...prevFormData,
                files: [
                    ...prevFormData.files,
                    { document_name: "", file: null },
                ],
            }));
        } else {
            setAddDocumentation(true)
        }
    };

    const handleRemoveDocument = (index) => {
        if (formDataDoc.length === 1) {
            setAddDocumentation(false)
        }
        setFormDataDoc((prevFormData) => ({
            ...prevFormData,
            files: prevFormData.files.filter((_, i) => i !== index),
        }));
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, i) => i !== index)
        );
    };
    const [formDataDoc, setFormDataDoc] = useState({
        files: [
            {
                document_name: null,
                file: "",
            },
        ],
    });

    const handleShow = () => {
        setShow(true);
        // setFormData(i);
        const filteredData = { ...i };
        setFormData({
            ...filteredData,
            files: i.documents || [],
        });
        // console.log(i, 'i')
        getEmployeeDetails()
        getAllSubheadings()

        getReviewteamdetails()
    }
    const options = allEmployeeData
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));



    const selectEmployee = (selected) => {
        const uploaded_by = selected?.map((item) => item.value) || [];
        setFormData((prevState) => ({
            ...prevState,
            uploaded_by,
        }));
    };

    const handleClose = () => {
        setShow(false);
        setFileNames(() => {
            return new Array(fileNames.length).fill('');
        });
        setAddDocumentation(false);
    }

    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        // console.log(files)
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        // console.log(newValue)
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));


        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    useEffect(() => {
        // console.log("useffect running", formData?.review_team_details?.employee_detail)
        if (formData?.review_team_details?.employee_detail.length > 0) {

            const uploaded_by = formData?.review_team_details?.employee_detail?.map((item) => item.emp_code) || [];
            // console.log(uploaded_by)
            setFormData((prevState) => ({
                ...prevState,
                uploaded_by,
            }));

        }

    }, [formData])
    const generateRandomString = () => {
        const chars =
            "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from(
            { length: 8 },
            () => chars[Math.floor(Math.random() * chars.length)]
        ).join("");
    };
    const createCustomFile = (originalFile) => {
        const randomCode = generateRandomString();
        const now = new Date();
        const date = now.toISOString().split("T")[0];
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
        const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
        return new File([originalFile], customFileName, {
            type: originalFile.type,
        });
    };
    const validateform = () => {
        // const newErrors = {};
        const requiredFields = [
            "date",

            "structure_heading",
            "uploaded_by",
            "review_team"

        ];
        const newErrors = requiredFields.reduce((acc, field) => {
            if (
                !formData[field] ||
                (Array.isArray(formData[field]) && formData[field].length === 0)
            ) {
                acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required!`;
            }
            return acc;
        }, {});

        formDataDoc?.files?.forEach((doc, index) => {
            if (!doc.document_name || doc.document_name.trim() === "") {
                newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
            }
            // console.log(doc)
            if (!doc.file) {
                newErrors[`file_${index}`] = `Document File is required for document ${index + 1}`;
            }
        });
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });


        setErrors(newErrors);
        // setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {}


        if (validateform()) {

            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();



                Object.keys(formData).forEach((key) => {
                    if (formData[key] && key !== "id" && key !== "uploaded_by_detail" && key !== "review_team_employees" && key !== "review_team_details" && key !== "structure_heading_name" && key !== "project_name" && key !== "files") {
                        if (key === "uploaded_by" && Array.isArray(formData[key])) {

                            formData[key].forEach((emp) => {
                                formDataToSend.append(key, emp);
                            });
                        } else {
                            formDataToSend.append(key, formData[key]);
                        }
                    }
                });
                formDataDoc?.files?.forEach((doc, index) => {



                    if (doc.file) {

                        const customFile = createCustomFile(doc.file);


                        formDataToSend.append(`documents[${index}][document_name]`, doc.document_name);
                        formDataToSend.append(`documents[${index}][document_file]`, customFile);
                    }
                });

                let res = await axios.put(
                    `${BASE_URL}/detail-design/reviewfilebulkupdate/${formData.id}/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200 || res.status === 201) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    setFileNames(() => {
                        return new Array(fileNames.length).fill('');
                    });
                    setFormDataDoc(() => ({

                        files: [

                            { document_name: "", file: null },
                        ],
                    }));
                    setAddDocumentation(false);
                    await getReviewteam();
                } else { }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }


    };

    const handlePicDelete = async (e, i, getProjects) => {
        e.preventDefault();

        // setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait...");

        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/documentupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getProjects();
                setFormData((prevFormData) => {
                    const updatedFiles = prevFormData.files.filter((doc) => doc.id !== i.id);
                    return { ...prevFormData, files: updatedFiles };
                })


            } else {

            }
        } catch (err) {
            handleErrorToast(err);
        } finally {

            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button onClick={handleShow} className="model-edit-button">
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update  Review File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div className="flex-column">
                                <label> Date:<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.date ? 'error' : inputState.date ? 'success' : ''}`}
                                />
                                {errors.date && <span className="error-message">{errors.date}</span>}
                            </div>
                            <div className="flex-column form-group-select">
                                <label className="form-labels">
                                    Uploaded by:<span className="required">*</span>
                                </label>
                                {/* {console.log(formData)} */}
                                <Select
                                    options={options}
                                    isMulti
                                    onChange={selectEmployee}

                                    name="uploaded_by"
                                    value={show && formData?.
                                        review_team_details?.employee_detail


                                        ?.map((emp) => ({

                                            value: emp.emp_code,
                                            label: options.find((option) => option.value === emp.emp_code)?.label || emp.emp_code,
                                        })) || []}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '33vw',
                                            minHeight: '50px',
                                            borderRadius: '10px',
                                            border: '1px solid #e3e3e3',
                                            margin: '10px 0px 20px 0px',
                                            color: '#4a4a4a',
                                            backgroundColor: '#f7f8fa',
                                            padding: '0px 10px',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            marginTop: '0',
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                    }}

                                />

                                {errors.uploaded_by && (
                                    <span className="error-message font-size-text ">{errors.uploaded_by}</span>
                                )}
                            </div>


                            <div title="structure_heading" className="flex-column form-group-selectt">
                                <label htmlFor="structure_heading" className="form-labels font-weight500 font-size-subheading">
                                    Structure Heading <span className="required">*</span>
                                </label>
                                <br />
                                {structureHeadingList.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>
                                        <select
                                            id="structure_heading"
                                            name="structure_heading"
                                            value={formData.structure_heading} // Changed from `project` to `structure_heading`
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.structure_heading ? "error" : inputState.structure_heading ? "success" : ""}`}
                                        >
                                            <option value="">Select a Structure Heading</option>
                                            {structureHeadingList?.sort((a, b) => a.heading_name.localeCompare(b.heading_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.heading_name} {/* Adjusted to show the structure name */}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.structure_heading && (
                                                <span className="error-message font-size-text ">{errors.structure_heading}</span>
                                            )}
                                        </div></>}
                            </div>



                            <div title="review_team" className="flex-column form-group-selectt">
                                <label htmlFor="review_team" className="form-labels font-weight500 font-size-subheading">
                                    Review Team <span className="required">*</span>
                                </label>
                                <br />
                                {reviewTeam.length === 0 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div> :
                                    <>
                                        <select
                                            id="review_team"
                                            name="review_team"
                                            value={formData.review_team}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.review_team ? "error" : inputState.review_team ? "success" : ""}`}
                                        >
                                            <option value="">Select a Review Team</option>
                                            {reviewTeam?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.name} {/* Adjusted to show the review team name */}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        <div>
                                            {errors.review_team && (
                                                <span className="error-message font-size-text ">{errors.review_team}</span>
                                            )}
                                        </div></>}
                            </div>



                            <div className="form-flex-wrap" style={{ width: "100%" }}>
                                <table className="table-css">
                                    <thead className="table-heading">
                                        <tr className="custom-table-head-tr">
                                            <th className="table-heading-text align-left">S. No.</th>
                                            <th className="table-heading-text text-center">Name</th>
                                            <th className="table-heading-text text-center">File</th>
                                            <th className="table-heading-text align-right width-5vw">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.documents != undefined && formData.documents.length > 0 && formData?.documents?.map((i, index) => (
                                            <React.Fragment key={index}>

                                                <tr className="custom-table-head-td">
                                                    <td className="table-body align-left">{index + 1}</td>
                                                    <td className="table-body text-center">{i.document_name}</td>
                                                    { }
                                                    <td className="table-body text-center">
                                                        {i.file ? (
                                                            // <a href={i.document_file} target="blank">
                                                            //   View
                                                            // </a>
                                                            <a href={i.file} target="blank">
                                                                {/* <DocumentIcon /> */}
                                                            </a>
                                                        ) : (
                                                            "Document Not Attached"
                                                        )}
                                                    </td>

                                                    <td className="table-body align-right width-5vw ">
                                                        <button
                                                            className="model-delete-button"
                                                            onClick={(e) =>
                                                                handlePicDelete(e, i, getReviewteam)
                                                            }
                                                        >
                                                            <DeleteDustbin />
                                                        </button>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ width: '100%' }}>
                                <div className="jd-heading-outer  heading-bg-color-white">
                                    <div className="jd-heading-outer-flex ">
                                        <h2 className="jd-heading-main font-weight600  font-size-heading">
                                            Documents{" "}
                                        </h2>
                                        <div>

                                            <button title="Add Documents" onClick={handleAddfiles}
                                                className="upload-svg" type="button" style={{ float: 'right' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_1415_3177)"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white"></path></g><defs><clipPath id="clip0_1415_3177"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg> Add Document</button>
                                            <br />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="jd-heading-bottom-bold"></div>
                                        <div className="jd-heading-bottom-light"></div>
                                    </div>
                                </div>
                            </div>
                            <hr className="line" />
                            {addDocumentation && formDataDoc?.files?.map((doc, index) => (
                                <div key={index} className="form-flex-wrap" style={{ width: "100%" }}>
                                    <div className="flex-column">
                                        <label
                                            htmlFor={`document_name_${index}`}
                                            className="form-labels announce-date font-weight500 font-size-heading"
                                        >
                                            File Name
                                        </label>
                                        <input
                                            type="text"
                                            id={`document_name_${index}`}
                                            name={`document_name`}
                                            placeholder="Document Name"
                                            value={doc.document_name}
                                            onChange={(e) => handleDocInputChange(index, e)}
                                            className={`form-input ${errors[`document_name_${index}`]
                                                ? "error"
                                                : inputState[`document_name`]
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors[`document_name_${index}`] && (
                                            <span className="error-message font-size-text">
                                                {errors[`document_name_${index}`]}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex-column field-cont-div">
                                        <label
                                            htmlFor=""
                                            className="form-labels announce-date font-weight500 font-size-heading"
                                        >
                                            Upload File
                                        </label>
                                        <label
                                            htmlFor={`file_${index}`}
                                            className={`form-inputss custom-file-upload ${errors[`file_${index}`]
                                                ? "error"
                                                : inputState[`file`]
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <div className={`text-center pdf-input  ${errors[`file_${index}`] ? 'error' : ''}`}>
                                                <Attachment />
                                            </div>
                                        </label>
                                        <input
                                            id={`file_${index}`}
                                            name={`file`}
                                            type="file"
                                            onChange={(e) => handleDocInputChange(index, e)}
                                            style={{ display: "none", position: "relative" }}
                                        />
                                        {fileNames[index] && (
                                            <p className="file-name text-center">
                                                {fileNames[index]}
                                            </p>
                                        )}

                                        {errors[`file_${index}`] && (
                                            <span className="error-message font-size-text">
                                                {errors[`file_${index}`]}
                                            </span>
                                        )}
                                    </div>

                                    {index === 0 ? null : (
                                        <button
                                            type="button"
                                            className="model-button-cancel"
                                            onClick={() => handleRemoveDocument(index)}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}








                            <div className="button-models" style={{ width: '100%' }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                //   disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Update
                                </button>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
};


const ReviewteamDelete = ({ i, getReviewteam }) => {
    const [show, setShow] = useState(false);
    // console.log(i)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();


        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/reviewfile/${i.id}/`
            );

            if (res.status === 200 || res.status === 201) {
                await getReviewteam();
                setShow(false);
            } else {
                ;
            }
        } catch (err) {

        } finally {

        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Review File of {i.review_team_details?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Review File of {i.review_team_details?.name}
                        <div className="button-models">
                            <button
                                className="model-button font-weight500 model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};



const TableReviewfile = () => {
    const [mainData, SetMainData] = useState([]);
    const getReviewteam = async () => {
        try {
            const result = await axios.get(`${BASE_URL}/detail-design/reviewfile/`);
            SetMainData(result.data);
        } catch (err) {
            handleErrorToast(err);
        }

    };

    useEffect(() => {
        getReviewteam();

    }, []);


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-box">
                <div className="info-box-div-without-bg">
                    <div className="info-box-div-bg">
                        <div>
                            <div className="jd-heading-outer-flex">
                                <h2 className="jd-heading-main font-weight600 font-size-heading">Review File</h2>
                                <div className="field-cont">

                                    <AddReviewteam getReviewteam={getReviewteam} />
                                    <div className="Toastify"></div>
                                </div>
                            </div>
                            <div>
                                <div className="jd-heading-bottom-bold"></div>
                                <div className="jd-heading-bottom-light"></div>
                            </div>
                        </div>
                        <div className="table-css-white-background table-box scroll-container-table">

                            <table className="table-css">
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr">
                                        <th className="table-heading-text text-lefttt">Sr no.</th>
                                        <th className="text-center table-heading-text">Review Team Name</th>
                                        <th className="text-center table-heading-text">Employee Name</th>
                                        <th className="text-center table-heading-text">Structure Heading</th>
                                        <th className="text-center table-heading-text">Uploaded By</th>
                                        {/* <th className="text-center table-heading-text">Files</th> */}
                                        <th className="text-center table-heading-text">Date</th>
                                        <th className="text-center table-heading-text">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mainData.map((i, index) => (
                                        <React.Fragment key={i.id}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="text-center table-body">{i.review_team_details?.name}</td>
                                                <td className="text-center table-body">
                                                    <ViewEmployeeList titlename="employee table" sort="" list={i.review_team_details?.employee_detail} />
                                                </td>
                                                <td className="text-center table-body">
                                                    {i.structure_heading_name}
                                                </td>
                                                <td className="text-center table-body">{i.uploaded_by_detail?.name}</td>
                                                {/* <td className="text-center table-body">{i?.documents ?
                                                    <PDFIcon /> : ''}
                                                </td> */}
                                                <td className="text-center table-body">{i.date}</td>
                                                <td className="text-center table-body width-5vw">
                                                    <div className="flex-row">
                                                        <UpdateReviewfile
                                                            i={i}
                                                            getReviewteam={getReviewteam}
                                                        />
                                                        <ReviewteamDelete
                                                            i={i}
                                                            getReviewteam={getReviewteam}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TableReviewfile;
