
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableDesignsstage from "./Tabledesign"

const Designmain = () => {


    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="Designstage" name={"Designstage"} />
            <TableDesignsstage />
        </>
    );
};


export default Designmain