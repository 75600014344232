
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableStructureheading from "./Tablestructureheading"

const Structureheadingmain = () => {

    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            {/* modification required on navbar */}
            <Dashboardnavbar url="structure-heading" name={"Structure Heading"} />



            <TableStructureheading />


        </>
    );
};


export default Structureheadingmain