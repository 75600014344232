import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import {
    AddwithWhiteCircle,
    DropdownArrow,
    SearchBig,
    UpdatePencil,
    PDFIcon,
    CircularGrid
} from "../AllSvg";
import { Modal } from "react-bootstrap";
import { ViewEmployeeList, handleErrorToast } from "../CustomFunctions";
import { toast, ToastContainer } from 'react-toastify';

const AddSubcompanydetail = ({ getDetailsdesign }) => {
    // const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        company_name: "",
        company_address: "",
        company_number: "",
        company_gst: "",
        company_logo: "",
    });
    // const getSubCompany = async () => {

    //     try {
    //         const sub = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
    //         setSiteSubCompanyList(sub.data);
    //     } catch (err) {

    //     } finally {

    //     }
    // };
    // useEffect(() => {
    //     if (show) {
    //         getSubCompany();
    //     }
    // }, [formData.sub_company, show])
    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData(() =>
            Object.keys(formData).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(files);
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue);
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));
        let errorMessage = "";
        if (name === "title" && newValue.length > 50) {
            errorMessage = "Title cannot exceed 50 characters.";
        } else if (name === "company_name" && newValue.length > 150) {
            errorMessage = "Company Name cannot exceed 150 characters.";
        } else if (name === "company_address" && newValue.length > 250) {
            errorMessage = "Company Address cannot exceed 250 characters.";
        } else if (name === "company_number" && newValue.length > 20) {
            errorMessage = "Company Number cannot exceed 20 characters.";
        } else if (name === "company_gst" && newValue.length > 20) {
            errorMessage = "Company GST cannot exceed 20 characters.";
        }
        if (errorMessage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: errorMessage,
            }));
            return;
        } else {
            setErrors((prevErrors) => {
                const { [name]: removedError, ...rest } = prevErrors;
                return rest;
            });
        }

        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });

        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {};

        // if (formData.is_internal_source) {
        //     newerror.is_internal_source = "Is internal source is required "
        // }
        // setErrors(newerror)
        if (Object.keys(newerror).length === 0) {
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                let res = await axios.post(
                    `${BASE_URL}/detail-design/subCompany/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(res);
                if (res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getDetailsdesign();
                } else {
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button
                title="Add Detail Design"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Add SubCompany
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Add Sub Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="title" className="flex-column">
                                <label
                                    htmlFor="title"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Title
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="title"
                                    name="title"
                                    placeholder="Title"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">
                                        {errors.title}
                                    </span>
                                )}
                            </div>
                            <div title="company_name" className="flex-column">
                                <label
                                    htmlFor="company_name"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_name"
                                    name="company_name"
                                    placeholder="Company Name"
                                    onChange={handleInputChange}
                                    value={formData.company_name}
                                    className={`form-input ${errors.company_name
                                        ? "error"
                                        : inputState.company_name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_name && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_name}
                                    </span>
                                )}
                            </div>

                            <div title="company_address" className="flex-column">
                                <label
                                    htmlFor="company_address"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Address
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_address"
                                    name="company_address"
                                    placeholder="Company Address"
                                    onChange={handleInputChange}
                                    value={formData.company_address}
                                    className={`form-input ${errors.company_address
                                        ? "error"
                                        : inputState.company_address
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_address && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_address}
                                    </span>
                                )}
                            </div>
                            <div title="company_number" className="flex-column">
                                <label
                                    htmlFor="company_number"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_number"
                                    name="company_number"
                                    placeholder="company_number"
                                    onChange={handleInputChange}
                                    value={formData.company_number}
                                    className={`form-input ${errors.company_number
                                        ? "error"
                                        : inputState.company_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_number && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_number}
                                    </span>
                                )}
                            </div>
                            <div title="company_gst" className="flex-column">
                                <label
                                    htmlFor="company_gst"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Gst Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_gst"
                                    name="company_gst"
                                    placeholder="company_gst"
                                    onChange={handleInputChange}
                                    value={formData.company_gst}
                                    className={`form-input ${errors.company_gst
                                        ? "error"
                                        : inputState.company_gst
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_gst && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_gst}
                                    </span>
                                )}
                            </div>
                            <div title="company_logo" className="flex-column">
                                <label
                                    htmlFor="company_logo"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Logo
                                </label>
                                {formData.company_logo && (
                                    <div>
                                        <span>{formData.company_logo.name}</span>

                                        {formData.company_logo && (
                                            <img
                                                src={formData.company_logo}
                                                alt="company_logo Preview"
                                                width={100}
                                            />
                                        )}
                                    </div>
                                )}
                                <input
                                    type="file"
                                    maxLength={100}
                                    id="company_logo"
                                    name="company_logo"
                                    placeholder="company_logo"
                                    onChange={handleInputChange}
                                    accept=".png,.svg,.jpg,.jpeg"
                                    className={`form-input ${errors.company_logo
                                        ? "error"
                                        : inputState.drawing
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_logo && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_logo}
                                    </span>
                                )}
                            </div>

                            <div className="button-models" style={{ width: "100%" }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                //   disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
const UpdateSubcompanyDetail = ({ i, getDetailsdesign }) => {
    console.log(i, "update certificates");
    const [show, setShow] = useState(false);
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        company_name: "",
        company_address: "",
        company_number: "",
        company_gst: "",
        company_logo: "",
    });
    const [originalcompanylogo, setOriginalcompanylogo] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFormData(i);
        setOriginalcompanylogo(i.company_logo);
    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type, checked, options, files } = e.target;
        console.log(value);
        let newValue;
        if (type === "select-multiple") {
            newValue = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (type === "file") {
            newValue = files.length > 0 ? files[0] : null;
        } else {
            newValue = value;
        }

        console.log(newValue);
        setInputState((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? "green"
                        : ""
                    : newValue
                        ? "green"
                        : "",
        }));

        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: newValue,
            };

            return updatedFormData;
        });

        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newerror = {};

        if (Object.keys(newerror).length === 0) {
            // setErrors(newerrors)
            //   setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (formData[key] && key !== "company_logo") {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                if (
                    formData.company_logo &&
                    formData.company_logo !== originalcompanylogo
                ) {
                    Array.from(formData.company_logo).forEach((file) => {
                        formDataToSend.append("company_logo", formData.company_logo);
                    });
                }
                console.log("FormData to send:", formDataToSend);

                let res = await axios.put(
                    `${BASE_URL}/detail-design/subCompany/${formData.id}/`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                console.log(res);
                if (res.status === 200) {
                    setShow(false);
                    setFormData(() => Object.keys(formData).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {}));
                    await getDetailsdesign();
                } else {
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                // setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };
    return (
        <>
            <button onClick={handleShow} className="model-edit-button">
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update SubCompany Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log(formData)}
                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div title="title" className="flex-column">
                                <label
                                    htmlFor="title"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Title
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="title"
                                    name="title"
                                    placeholder="Title"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">
                                        {errors.title}
                                    </span>
                                )}
                            </div>
                            <div title="company_name" className="flex-column">
                                <label
                                    htmlFor="company_name"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_name"
                                    name="company_name"
                                    placeholder="Company Name"
                                    onChange={handleInputChange}
                                    value={formData.company_name}
                                    className={`form-input ${errors.company_name
                                        ? "error"
                                        : inputState.company_name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_name && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_name}
                                    </span>
                                )}
                            </div>

                            <div title="company_address" className="flex-column">
                                <label
                                    htmlFor="company_address"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Address
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_address"
                                    name="company_address"
                                    placeholder="Company Address"
                                    onChange={handleInputChange}
                                    value={formData.company_address}
                                    className={`form-input ${errors.company_address
                                        ? "error"
                                        : inputState.company_address
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_address && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_address}
                                    </span>
                                )}
                            </div>
                            <div title="company_number" className="flex-column">
                                <label
                                    htmlFor="company_number"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_number"
                                    name="company_number"
                                    placeholder="company_number"
                                    onChange={handleInputChange}
                                    value={formData.company_number}
                                    className={`form-input ${errors.company_number
                                        ? "error"
                                        : inputState.company_number
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_number && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_number}
                                    </span>
                                )}
                            </div>
                            <div title="company_gst" className="flex-column">
                                <label
                                    htmlFor="company_gst"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Gst Number
                                </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    id="company_gst"
                                    name="company_gst"
                                    placeholder="company_gst"
                                    onChange={handleInputChange}
                                    value={formData.company_gst}
                                    className={`form-input ${errors.company_gst
                                        ? "error"
                                        : inputState.company_gst
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_gst && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_gst}
                                    </span>
                                )}
                            </div>
                            <div title="company_logo" className="flex-column">
                                <label
                                    htmlFor="company_logo"
                                    className="form-labels  font-weight500    font-size-subheading"
                                >
                                    Company Logo
                                </label>
                                {formData.company_logo && (
                                    <div>
                                        <span>{formData.company_logo.name}</span>

                                        {formData.company_logo && (
                                            <img
                                                src={formData.company_logo}
                                                alt="company_logo Preview"
                                                width={100}
                                            />
                                        )}
                                    </div>
                                )}
                                <input
                                    type="file"
                                    maxLength={100}
                                    id="company_logo"
                                    name="company_logo"
                                    placeholder="company_logo"
                                    onChange={handleInputChange}
                                    accept=".png,.svg,.jpg,.jpeg"
                                    className={`form-input ${errors.company_logo
                                        ? "error"
                                        : inputState.drawing
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.company_logo && (
                                    <span className="error-message font-size-text ">
                                        {errors.company_logo}
                                    </span>
                                )}
                            </div>

                            <div className="button-models" style={{ width: "100%" }}>
                                <button
                                    onClick={handleFormSubmit}
                                    className="model-button   font-weight500    model-button-submit"
                                //   disabled={loading}
                                >
                                    {/* //loading logic */}
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const SubcompanyDelete = ({ i, getDetailsdesign }) => {
    const [show, setShow] = useState(false);
    // console.log(i)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();

        try {
            let res = await axios.delete(
                `${BASE_URL}/detail-design/subCompany/${i.id}/`
            );

            if (res.status === 200) {
                await getDetailsdesign();
                setShow(false);
            } else {
            }
        } catch (err) {
        } finally {
        }
    };

    return (
        <>
            <button
                title="Delete Insurance"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    Division="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Sub company of {i.company_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Sub Company of {i.company_name}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500 model-button-cancel   "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


const TableDesignssubcompany = () => {
    const [mainData, SetMainData] = useState([]);
    const [originalmainData, SetOriginalMainData] = useState([]);
    const getDetailsdesign = async () => {
        const result = await axios.get(`${BASE_URL}/detail-design/subCompany/`);
        SetMainData(result.data);
        SetOriginalMainData(result.data)
    };
    const [selectedDivision, setDivision] = useState(
        ""
    );
    const [selectedDivision2, setDivision2] = useState(
        ""
    );


    useEffect(() => {
        getDetailsdesign();

    }, []);
    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "Sub Company";
            case 2:
                return "Structure Heading";
            case 3:
                return "Review Team";
            default:
                return "Sub Company";
        }
    };

    const applyFilters = () => {
        let filteredData = originalmainData;

        if (selectedDivision !== "") {
            const lowercasesearch1 = selectedDivision?.toLowerCase();
            filteredData = filteredData.filter(
                (employee) => {
                    const projectMatch = employee?.title
                        ?.toLowerCase()
                        .includes(lowercasesearch1);

                    return projectMatch;
                }
            );


        }

        if (selectedDivision2 !== "") {
            const lowercasesearch1 = selectedDivision2?.toLowerCase();
            filteredData = filteredData.filter(
                (employee) => {
                    const projectMatch = employee?.company_name
                        ?.toLowerCase()
                        .includes(lowercasesearch1);

                    return projectMatch;
                }
            );


        }

        SetMainData(filteredData);
    }

    useEffect(() => {
        applyFilters();
    }, [selectedDivision, mainData, selectedDivision2]);
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="content-tabs">

                <div className="attendance-box">
                    <div className="attendance-subcont">

                        <div className="field-cont">
                            <div className="field-cont-div">
                                <SearchBig />
                                <input
                                    className="attendance-input-field width-10vw"
                                    placeholder="BY Company title"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                />




                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <SearchBig />
                                <input
                                    className="attendance-input-field width-10vw"
                                    placeholder="BY Company Name"
                                    type="text"
                                    value={selectedDivision2}
                                    onChange={(e) => setDivision2(e.target.value)}
                                />




                                <hr className="field-cont-hr" />
                            </div>
                        </div>
                        <div className='btn-cont'>
                            {/* <DownloadAndZip /> */}
                            <AddSubcompanydetail getDetailsdesign={getDetailsdesign} />
                        </div>
                    </div>
                    <div className="datagrid-container">
                        <div className="table-css-white-background table-box scroll-container-table ">
                            <div className="table-heading-flex  field-cont-between table-css-white-background">
                                <div className="repo-heading heading-padding-none font-weight500 font-size-heading">
                                    Subcompany
                                </div>

                            </div>
                            <table className="table-css">
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr">
                                        <th className="table-heading-text">Sr no.</th>
                                        <th className="table-heading-text text-center">Title</th>
                                        <th className="table-heading-text">Company Name</th>
                                        <th className="table-heading-text">Company Address</th>
                                        <th className="table-heading-text">Comapny Number</th>
                                        <th className="table-heading-text">Company Gst</th>
                                        <th className="table-heading-text">Company Logo</th>

                                        <th
                                            className="table-heading-text"
                                            style={{ padding: "30px 45px" }}
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mainData.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">

                                                {console.log(i, 'main data')}
                                                <td className="table-body">{index + 1}</td>
                                                <td className="table-body text-center">{i.title} </td>
                                                <td className="table-body">{i.company_name}</td>
                                                <td className="table-body">{i.company_address}</td>
                                                <td className="table-body">{i.company_number}</td>
                                                <td className="table-body">{i.company_gst}</td>
                                                <td className="table-body">
                                                    {i.company_logo && (
                                                        <a href={i.company_logo} target="_blank">
                                                            {" "}
                                                            <PDFIcon />
                                                        </a>
                                                    )}
                                                </td>

                                                <td className="table-body text-center">
                                                    <div className="flex-row" style={{ gap: "15px" }}>
                                                        {" "}
                                                        <UpdateSubcompanyDetail
                                                            i={i}
                                                            getDetailsdesign={getDetailsdesign}
                                                        />
                                                        <SubcompanyDelete
                                                            i={i}
                                                            getDetailsdesign={getDetailsdesign}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableDesignssubcompany;
