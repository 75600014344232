import axios from "axios"
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { DropdownArrow } from "../AllSvg";


const AddProjectDetails = () => {
    const [formData, setFormData] = useState({
        pr_code: '',
        sub_Company: '',
        project_name: '',
        client: '',
        location: '',
        concern_person: '',
        concern_person_name: '',
        length: '',
        award_date: '',
        wo_date: '',
        start_date: '',
        due_date: '',
        project_mode: '',
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
        confirm_by: '',
        confirmation_mode: '',
        status: 'pending',
        project_description: "",


    });




    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            'pr_code',
            'client',
            "sub_Company",
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const logingToastId = toast.loading(`Logging In...`); //toast Logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/project/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                console.log(err);

                //toast Logic
                if (err.response) {
                    toast.dismiss(logingToastId);
                    const errorData = err.response.data;
                    if (typeof errorData.error === 'string') { // Single error message   
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                setLoading(false);  //loading logic
                // toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // get request Client Data
    useEffect(() => {
        getClientDetails();
    }, []);

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/detail-design/client/`,
            );
            setAllClientData(res.data);
        } catch (err) {

        }

    }


    return (
        <div className='modal-body-padding'>
            <form>
                <div className='form-flex-wrap'>
                    <div className="flex-column">
                        <label className="form-labels">PR. Code <span className="required">*</span></label>
                        <input
                            type="text"
                            name="pr_code"
                            value={formData.pr_code}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                        />
                        {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Project Description</label>
                        <input
                            type="text"
                            name="project_description"
                            value={formData.project_description}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_description ? 'error' : inputState.project_description ? 'success' : ''}`}
                        />
                        {errors.project_description && <span className="error-message">{errors.project_description}</span>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Project Name <span className="required">*</span></label>
                        <input
                            type="text"
                            name="project_name"
                            value={formData.project_name}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                        />
                        {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                    </div>
                    {/* <div title="sub_Company" className="flex-column form-group-selectt">
                        <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                            Sub Company <span className="required">*</span>
                        </label>
                        <br />
                        {sub_CompanyList.length === 0 ?
                            <div className="form-loader">
                                <div className="bar-loader"></div>
                            </div> :
                            <>
                                <select
                                    id="sub_Company"
                                    name="sub_Company"
                                    value={formData.sub_Company}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                        }`}
                                >
                                    <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                                    {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                                        <option value={i.id}>{i.title}</option>
                                    </>))
                                    }
                                </select>
                                <DropdownArrow />
                                <div>
                                    {errors.sub_Company && (
                                        <span className="error-message font-size-text ">{errors.sub_Company}</span>
                                    )}
                                </div></>}
                    </div> */}
                    <div className="flex-column form-group-selectt">
                        <label className="form-labels"> Client Name<span className="required">*</span></label>
                        <br />
                        {allClientData.length === 0 ?
                            <div className="form-loader">
                                <div className="bar-loader"></div>
                            </div> : <>
                                <select
                                    name="client"
                                    value={formData.client}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                >
                                    <option value="">Select a Client</option>
                                    {
                                        allClientData.length > 0 ?
                                            (allClientData.map((e, index) => (
                                                <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                            ))) : (
                                                <option value="">No Clients Available</option>
                                            )
                                    }

                                </select>
                                <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                <br />
                                {errors.client && (
                                    <span className="error-message">{errors.client}</span>
                                )}
                            </>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">Location</label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                        />
                        {errors.location && <span className="error-message">{errors.location}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Length</label>
                        <input
                            type="text"
                            name="length"
                            value={formData.length}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                        />
                        {errors.length && <span className="error-message">{errors.length}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">WO Amount</label>
                        <input
                            type="number"
                            name="woamount"
                            value={formData.woamount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                        />
                        {errors.woamount && <span className="error-message">{errors.woamount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Without GST Amount</label>
                        <input
                            type="number"
                            name="without_gst_amount"
                            value={formData.without_gst_amount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                        />
                        {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> GST Amount</label>
                        <input
                            type="numbeer"
                            name="gst_amount"
                            value={formData.gst_amount}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                        />
                        {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Project Mode</label>
                        <input
                            type="text"
                            name="project_mode"
                            value={formData.project_mode}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                        />
                        {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Concern Person<span className="required">*</span></label>
                        <input
                            type="text"
                            name="concern_person"
                            value={formData.concern_person}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                        />
                        {errors.concern_person && <span className="error-message">{errors.concern_person}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Confirm By</label>
                        <input
                            type="text"
                            name="confirm_by"
                            value={formData.confirm_by}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                        />
                        {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                    </div>

                    <div className="flex-column">
                        <label className="form-labels">  Confirmation Mode</label>
                        <input
                            type="text"
                            name="confirmation_mode"
                            value={formData.confirmation_mode}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                        />
                        {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">Award Date</label>
                        <input
                            type="date"
                            name="award_date"
                            value={formData.award_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                        />
                        {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels">WO Date</label>
                        <input
                            type="date"
                            name="wo_date"
                            value={formData.wo_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                        />
                        {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Start Date</label>
                        <input
                            type="date"
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                        />
                        {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                    </div>
                    <div className="flex-column">
                        <label className="form-labels"> Due Date</label>
                        <input
                            type="date"
                            name="due_date"
                            value={formData.due_date}
                            onChange={handleInputChange}
                            className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                        />
                        {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                    </div>

























                    <div className="flex-column form-group-selectt">
                        <label className="form-labels">Status:</label>
                        <br />
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleInputChange}
                            className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                        >
                            <option value="">Select a Status</option>
                            <option value="pending">Pending</option>
                            <option value="inprogress">In Progeress</option>
                            <option value="cancelled">Cancelled</option>
                            <option value="completed">Completed</option>
                            <option value="hold">Hold</option>
                            <option value="cancelled">Cancelled</option>


                        </select>
                        <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                        {errors.status && (
                            <span className="error-message ">{errors.status}</span>
                        )}

                    </div>







                </div>

                <div className="button-models">
                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

const UpdateProjectDetails = ({
    i,
    getJobappdata
}) => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        pr_code: '',
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",



    });

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFormData(i)

    };
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);


        return Object.keys(newErrors).length === 0;
    };
    const [allClientData, setAllClientData] = useState([]);
    useEffect(() => {
        getClientDetails();
    }, []);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/detail-design/client`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }
    console.log(formData)

    console.log(errors)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/detail-design/projectupdate/${formData.pr_code}/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    setShow(false)
                    await getJobappdata(null);
                } else {
                    ;
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    return (
        <>
            <button title="upload-svg"
                style={{ margin: 0 }}
                className="upload-svg"
                onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                    id="pen-new-square"
                >
                    <path
                        fill=""
                        fill-rule="evenodd"
                        d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z"
                        clip-rule="evenodd"
                    ></path>
                    <path
                        fill=""
                        fill-rule="evenodd"
                        d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z"
                        clip-rule="evenodd"
                    ></path>
                </svg>{" "}
                Update Project Details
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500 font-size-heading"
                >
                    <Modal.Title>Update All Projects Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log(formData)}
                    <form>
                        <div className='form-flex-wrap'>



                            <div className="flex-column">
                                <label className="form-labels">PR. Code<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="pr_code"
                                    value={formData.pr_code}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                                />
                                {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                            </div>

                            <div className="flex-column form-group-select">
                                <label className="form-labels">Client:<span className="required">*</span></label>
                                <br />
                                <select
                                    name="client"
                                    value={formData.client}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                >
                                    <option value="">Select a Client</option>
                                    {
                                        allClientData.length > 0 ?
                                            (allClientData.map((e, index) => (
                                                <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                            ))) : (
                                                <option value="">No Clients Available</option>
                                            )
                                    }

                                </select>
                                <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.client && (
                                    <span className="error-message">{errors.client}</span>
                                )}

                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Project Name<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="project_name"
                                    value={formData.project_name}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                                />
                                {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Location<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                                />
                                {errors.location && <span className="error-message">{errors.location}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Concern Person</label>
                                <input
                                    type="text"
                                    name="concern_person_name"
                                    value={formData.concern_person_name}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.concern_person_name ? 'error' : inputState.concern_person_name ? 'success' : ''}`}
                                />
                                {errors.concern_person_name && <span className="error-message">{errors.concern_person_name}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Length<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="length"
                                    value={formData.length}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                />
                                {errors.length && <span className="error-message">{errors.length}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Award Date<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="award_date"
                                    value={formData.award_date}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                                />
                                {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                            </div>






                            <div className="flex-column">
                                <label className="form-labels">Workorder Date<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="wo_date"
                                    value={formData.wo_date}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                />
                                {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Start Date<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                                />
                                {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Due Date<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="due_date"
                                    value={formData.due_date}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                />
                                {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Project Mode<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="project_mode"
                                    value={formData.project_mode}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                                />
                                {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Confirm By<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="confirm_by"
                                    value={formData.confirm_by}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                />
                                {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels">Confirmatioin Mode<span className="required">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Confirmation Mode"
                                    name="confirmation_mode"
                                    value={formData.confirmation_mode}
                                    onChange={handleInputChange}
                                    className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                />
                                {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                            </div>

                            <div className="flex-column form-group-select">
                                <label className="form-labels">Status:</label>
                                <br />
                                <select
                                    name="status"
                                    value={formData.status}
                                    onChange={handleInputChange}
                                    className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                >
                                    <option value="">Select a Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="inprogress">In Progeress</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="completed">Completed</option>
                                    <option value="hold">Hold</option>
                                    <option value="cancelled">Cancelled</option>


                                </select>
                                <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.status && (
                                    <span className="error-message ">{errors.status}</span>
                                )}

                            </div>
                        </div>


                        <div className="button-models">
                            <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >

        </>
    );
};
// const UpdateProjectDetails = () => {

//     //   ****************   Modal Section
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);




//     return (
//         <>
//             {/* <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button> */}

//             <button title="upload-svg"
//                 style={{ margin: 0 }}
//                 className="upload-svg"
//                 onClick={handleShow}>
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="24"
//                     height="24"
//                     fill="white"
//                     viewBox="0 0 24 24"
//                     id="pen-new-square"
//                 >
//                     <path
//                         fill=""
//                         fill-rule="evenodd"
//                         d="M11.9426 1.25L13.5 1.25C13.9142 1.25 14.25 1.58579 14.25 2C14.25 2.41421 13.9142 2.75 13.5 2.75H12C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.23505 4.75455 2.91245 5.86509 2.80417 8.03734C2.78355 8.45104 2.43146 8.76969 2.01776 8.74907C1.60406 8.72845 1.28541 8.37636 1.30603 7.96266C1.41642 5.74802 1.74641 4.12187 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM22 9.75C22.4142 9.75 22.75 10.0858 22.75 10.5V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574L1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12V10.5C21.25 10.0858 21.5858 9.75 22 9.75Z"
//                         clip-rule="evenodd"
//                     ></path>
//                     <path
//                         fill=""
//                         fill-rule="evenodd"
//                         d="M16.7705 2.27592C18.1384 0.908029 20.3562 0.908029 21.7241 2.27592C23.092 3.6438 23.092 5.86158 21.7241 7.22947L18.0928 10.8607C17.7999 11.1536 17.3251 11.1536 17.0322 10.8607C16.7393 10.5678 16.7393 10.093 17.0322 9.80006L19.1051 7.72709C18.5892 7.50519 17.9882 7.14946 17.4193 6.58065C16.8505 6.01185 16.4948 5.41082 16.2729 4.89486L11.2175 9.95026C10.801 10.3668 10.6376 10.532 10.4988 10.71C10.3274 10.9297 10.1804 11.1676 10.0605 11.4192C9.96337 11.623 9.88868 11.8429 9.7024 12.4017L9.27051 13.6974L10.3026 14.7295L11.5983 14.2976C12.1571 14.1113 12.377 14.0366 12.5808 13.9395C12.8324 13.8196 13.0703 13.6726 13.2901 13.5012C13.468 13.3624 13.6332 13.199 14.0497 12.7825C14.3426 12.4896 14.8175 12.4896 15.1104 12.7825C15.4033 13.0754 15.4033 13.5503 15.1104 13.8432L15.076 13.8776C14.7047 14.2489 14.4721 14.4815 14.2126 14.684C13.9069 14.9224 13.5761 15.1268 13.2261 15.2936C12.929 15.4352 12.6169 15.5392 12.1188 15.7052L9.21426 16.6734C8.67801 16.8521 8.0868 16.7126 7.68711 16.3129C7.28742 15.9132 7.14785 15.322 7.3266 14.7857L8.29477 11.8812C8.46079 11.3831 8.56479 11.071 8.7064 10.7739C8.87319 10.4239 9.07761 10.0931 9.31605 9.78742C9.51849 9.52787 9.7511 9.29529 10.1224 8.92401L16.7705 2.27592ZM17.4546 3.7132C17.4773 3.80906 17.509 3.92327 17.5532 4.05066C17.6965 4.46372 17.9677 5.00771 18.48 5.51999C18.9923 6.03227 19.5363 6.30346 19.9493 6.44677C20.0767 6.49097 20.1909 6.52273 20.2868 6.54543L20.6634 6.16881C21.4455 5.38671 21.4455 4.11867 20.6634 3.33658C19.8813 2.55448 18.6133 2.55448 17.8312 3.33658L17.4546 3.7132Z"
//                         clip-rule="evenodd"
//                     ></path>
//                 </svg>{" "}
//                 Update Project Details
//             </button>

//             <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
//                 <Modal.Header closeButton>
//                     <Modal.Title className="modal-title">Update Projects Details</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <>

//                         <div className="content-tabs dialogbox-div ">

//                             <AddProjectDetails />

//                         </div>
//                     </>
//                 </Modal.Body>
//             </Modal>
//         </>
//     )
// }


const UpdateProjectDetailsEMP = ({ project }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(project)
        setShow(true)
    };


    const [formData, setFormData] = useState({
        pr_code: "",
        client: "",
        project_name: "",
        location: "",
        concern_person_name: "",
        length: "",
        award_date: "",
        wo_date: "",
        start_date: "",
        due_date: "",
        project_mode: "",
        confirm_by: "",
        confirmation_mode: "",
        status: "",
    });



    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            'project_name',
            // 'prebid_prcode',
            'client',
            // 'tender_id',
            'wo_date',
            // 'cost',
            'length',
            // 'remark',
            'confirm_by',
            'confirmation_mode',
        ];
        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const logingToastId = toast.loading(`Logging In...`); //toast Logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/highway/project/`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: "application/json",
                    }
                })

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                console.log(err);

                //toast Logic
                if (err.response) {
                    toast.dismiss(logingToastId);
                    const errorData = err.response.data;
                    if (typeof errorData.error === 'string') { // Single error message   
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                setLoading(false);  //loading logic
                // toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        // Handle other fields (e.g., project_name, client, etc.) as usual
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/detail-design/client/`,
            );
            setAllClientData(res.data);
        } catch (err) {

        }

    }

    useEffect(() => {
        getClientDetails();
    }, []);

    return (
        <>
            {/* <button className="model-button model-button-leave" onClick={handleShow}>+ Create New</button> */}

            <button className="upload-svga" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>
            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="content-tabs dialogbox-div ">
                            <div className='modal-body-padding'>
                                <form>
                                    <div className='form-flex-wrap'>


                                        <div className="flex-column">
                                            <label className="form-labels">PR. Code<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="pr_code"
                                                readOnly
                                                value={formData.pr_code}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                                            />
                                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                                        </div>

                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels"> Client Name<span className="required">*</span></label>
                                            <select
                                                name="client"
                                                value={formData.client}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Client</option>
                                                {
                                                    allClientData.length > 0 ?
                                                        (allClientData.map((e, index) => (
                                                            <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                        ))) : (
                                                            <option value="">No Clients Available</option>
                                                        )
                                                }

                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.client && (
                                                <span className="error-message">{errors.client}</span>
                                            )}

                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Location<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                                            />
                                            {errors.location && <span className="error-message">{errors.location}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Length<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="length"
                                                value={formData.length}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                            />
                                            {errors.length && <span className="error-message">{errors.length}</span>}
                                        </div>


                                        <div className="flex-column">
                                            <label className="form-labels">Award Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="award_date"
                                                value={formData.award_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                                            />
                                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">WO Date<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="wo_date"
                                                value={formData.wo_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                            />
                                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Start Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={formData.start_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                                            />
                                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Due Date<span className="required">*</span></label>
                                            <input
                                                type="date"
                                                name="due_date"
                                                value={formData.due_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                            />
                                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                        </div>



                                        <div className="flex-column">
                                            <label className="form-labels">Project Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="project_mode"
                                                value={formData.project_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                                            />
                                            {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels"> Confirm By<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirm_by"
                                                value={formData.confirm_by}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                            />
                                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">  Confirmation Mode<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="confirmation_mode"
                                                value={formData.confirmation_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                            />
                                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                        </div>

                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels">Status:</label>
                                            <select
                                                name="status"
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="inprogress">In Progeress</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="completed">Completed</option>
                                                <option value="hold">Hold</option>
                                                <option value="cancelled">Cancelled</option>


                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.status && (
                                                <span className="error-message ">{errors.status}</span>
                                            )}

                                        </div>

                                    </div>

                                    <div className="button-models">
                                        <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

const UpdateProject = ({ id, modalData }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false); //loading logic
    const [sub_CompanyList, setsub_CompanyList] = useState([]);
    const getAllsub_Company = async () => {

        try {
            const sub = await axios.get(`${BASE_URL}/detail-design/subCompany`);
            setsub_CompanyList(sub.data);
        } catch (err) {

        } finally {

        }
    };



    const [formData, setFormData] = useState({
        pr_code: '',
        sub_Company: '',
        project_name: '',
        client: '',
        location: '',
        concern_person: '',
        concern_person_name: '',
        length: '',
        award_date: '',
        wo_date: '',
        start_date: '',
        due_date: '',
        project_mode: '',
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
        confirm_by: '',
        confirmation_mode: '',
        status: 'pending',
        project_description: '',
    });


    useEffect(() => {
        setFormData(modalData)
        getAllsub_Company();
    }, [modalData]);

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            'pr_code',
            'client',
            "sub_Company",
            "concern_person"
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {


            if (formData.location === 'Other') {
                setFormData({
                    ...formData,
                    location: formData.otherLocation,
                });


            }
            const UpdatedFormData = {
                ...formData,
                username: sessionStorage.getItem('name')
            }
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(`${BASE_URL}/detail-design/projectupdate/${modalData.pr_code}/`, UpdatedFormData,)

                if (res.status === 200) {
                    window.location.reload();

                } else { }
            } catch (err) {

                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        // Update the 'location' or 'otherLocation' field based on the 'name' of the input
        if (name === 'location' || name === 'otherLocation') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'concern_person') {
            // If the input name is 'concern_person', split the value to get both code and name
            const [code, name] = value.split(' - ');

            setFormData({
                ...formData,
                concern_person: code, // Store code in 'concern_person'
                concern_person_name: name, // Store name in 'concern_person_name'
            });
        } else {
            // Handle other fields (e.g., project_name, client, etc.) as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleLocationChange = (event) => {
        const { name, value } = event.target;

        // If the selected location is "Other," clear the input field value
        if (value === "Other") {
            setFormData({
                ...formData,
                location: value, // Update the select field value
                otherLocation: "", // Clear the input field value
            });
        } else {
            setFormData({
                ...formData,
                location: value,
            });
        }
    };
    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/detail-design/client/`,
            );
            setAllClientData(res.data);
        } catch (err) {
            alert(err.message);
        }

    }

    useEffect(() => {
        getClientDetails();
    }, []);


    console.log(modalData, 'modaldata')




    return (
        <>

            <button className='upload-svg' onClick={handleShow}>
                Update Details {" "}
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>
            <Modal className="dialogbox-div" show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>

                        <div className="content-tabs dialogbox-div ">
                            <div className='modal-body-padding'>
                                <form>
                                    <div className='form-flex-wrap'>
                                        <div className="flex-column">
                                            <label className="form-labels">PR. Code <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="pr_code"
                                                value={formData.pr_code}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.pr_code ? 'error' : inputState.pr_code ? 'success' : ''}`}
                                            />
                                            {errors.pr_code && <span className="error-message">{errors.pr_code}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">Project Description</label>
                                            <input
                                                type="text"
                                                name="project_description"
                                                value={formData.project_description}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_description ? 'error' : inputState.project_description ? 'success' : ''}`}
                                            />
                                            {errors.project_description && <span className="error-message">{errors.project_description}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">Project Name <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="project_name"
                                                value={formData.project_name}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                                            />
                                            {errors.project_name && <span className="error-message">{errors.project_name}</span>}
                                        </div>
                                        <div title="sub_Company" className="flex-column form-group-selectt">
                                            <label htmlFor="sub_Company" className="form-labels  font-weight500    font-size-subheading">
                                                Sub Company <span className="required">*</span>
                                            </label>
                                            <br />
                                            {sub_CompanyList.length === 0 ?
                                                <div className="form-loader">
                                                    <div className="bar-loader"></div>
                                                </div> :
                                                <>
                                                    <select
                                                        id="sub_Company"
                                                        name="sub_Company"
                                                        value={formData.sub_Company}
                                                        onChange={handleInputChange}
                                                        className={`form-input form-group-selection ${errors.sub_Company ? "error" : inputState.sub_Company ? "success" : ""
                                                            }`}
                                                    >
                                                        <option value="">{sub_CompanyList.length > 0 ? sub_CompanyList?.find(company => company.id === formData.sub_Company)?.company_title || "Select a Sub Company" : formData?.sub_Company_name}</option>
                                                        {sub_CompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>

                                                            <option value={i.id}>{i.title}</option>
                                                        </>))
                                                        }
                                                    </select>
                                                    <DropdownArrow />
                                                    <div>
                                                        {errors.sub_Company && (
                                                            <span className="error-message font-size-text ">{errors.sub_Company}</span>
                                                        )}
                                                    </div></>}
                                        </div>
                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels"> Client Name<span className="required">*</span></label>
                                            <br />
                                            {allClientData.length === 0 ?
                                                <div className="form-loader">
                                                    <div className="bar-loader"></div>
                                                </div> : <>
                                                    <select
                                                        name="client"
                                                        value={formData.client}
                                                        onChange={handleInputChange}
                                                        className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                                    >
                                                        <option value="">Select a Client</option>
                                                        {
                                                            allClientData.length > 0 ?
                                                                (allClientData.map((e, index) => (
                                                                    <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                                ))) : (
                                                                    <option value="">No Clients Available</option>
                                                                )
                                                        }

                                                    </select>
                                                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                        </svg>
                                                    </div>
                                                    <br />
                                                    {errors.client && (
                                                        <span className="error-message">{errors.client}</span>
                                                    )}
                                                </>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">Location</label>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.location ? 'error' : inputState.location ? 'success' : ''}`}
                                            />
                                            {errors.location && <span className="error-message">{errors.location}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels"> Length</label>
                                            <input
                                                type="text"
                                                name="length"
                                                value={formData.length}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                            />
                                            {errors.length && <span className="error-message">{errors.length}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">WO Amount</label>
                                            <input
                                                type="number"
                                                name="woamount"
                                                value={formData.woamount}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                                            />
                                            {errors.woamount && <span className="error-message">{errors.woamount}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">Without GST Amount</label>
                                            <input
                                                type="number"
                                                name="without_gst_amount"
                                                value={formData.without_gst_amount}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                            />
                                            {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels"> GST Amount</label>
                                            <input
                                                type="numbeer"
                                                name="gst_amount"
                                                value={formData.gst_amount}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                                            />
                                            {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">Project Mode</label>
                                            <input
                                                type="text"
                                                name="project_mode"
                                                value={formData.project_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.project_mode ? 'error' : inputState.project_mode ? 'success' : ''}`}
                                            />
                                            {errors.project_mode && <span className="error-message">{errors.project_mode}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">Concern Person<span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="concern_person"
                                                value={formData.concern_person}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                                            />
                                            {errors.concern_person && <span className="error-message">{errors.concern_person}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels"> Confirm By</label>
                                            <input
                                                type="text"
                                                name="confirm_by"
                                                value={formData.confirm_by}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                            />
                                            {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                        </div>

                                        <div className="flex-column">
                                            <label className="form-labels">  Confirmation Mode</label>
                                            <input
                                                type="text"
                                                name="confirmation_mode"
                                                value={formData.confirmation_mode}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                            />
                                            {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">Award Date</label>
                                            <input
                                                type="date"
                                                name="award_date"
                                                value={formData.award_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.award_date ? 'error' : inputState.award_date ? 'success' : ''}`}
                                            />
                                            {errors.award_date && <span className="error-message">{errors.award_date}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels">WO Date</label>
                                            <input
                                                type="date"
                                                name="wo_date"
                                                value={formData.wo_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                            />
                                            {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels"> Start Date</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                value={formData.start_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.start_date ? 'error' : inputState.start_date ? 'success' : ''}`}
                                            />
                                            {errors.start_date && <span className="error-message">{errors.start_date}</span>}
                                        </div>
                                        <div className="flex-column">
                                            <label className="form-labels"> Due Date</label>
                                            <input
                                                type="date"
                                                name="due_date"
                                                value={formData.due_date}
                                                onChange={handleInputChange}
                                                className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                            />
                                            {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                        </div>

























                                        <div className="flex-column form-group-selectt">
                                            <label className="form-labels">Status:</label>
                                            <br />
                                            <select
                                                name="status"
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="inprogress">In Progeress</option>
                                                <option value="cancelled">Cancelled</option>
                                                <option value="completed">Completed</option>
                                                <option value="hold">Hold</option>
                                                <option value="cancelled">Cancelled</option>


                                            </select>
                                            <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            {errors.status && (
                                                <span className="error-message ">{errors.status}</span>
                                            )}

                                        </div>







                                    </div>

                                    <div className="button-models">
                                        <button onClick={handleSubmit} className="model-button model-button-submit">Update</button>
                                    </div>
                                </form>
                            </div>
                            <ToastContainer
                                position="top-center"
                                autoClose={1000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div>
                    </>
                </Modal.Body>
            </Modal>

        </>
    );
};



export { UpdateProjectDetails, UpdateProject, UpdateProjectDetailsEMP, AddProjectDetails }