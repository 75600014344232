import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios"
import axios from "axios"




const UpdateProject = ({ id, modalData, allEmployeeData }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const [formData, setFormData] = useState({
        project_name: '',
        client: '',
        concern_person: '',
        tender_id: '',
        wo_date: '',
        target_date: '',
        due_date: '',
        cost: '',
        length: '',
        link: '',
        place: '',
        remark: '',
        contract_mode: '',
        location: '',
        otherLocation: '',
        confirm_by: '',
        confirmation_mode: '',
        prebid_prcode: '',
        username: ''

    });

    useEffect(() => {
        setFormData(modalData)
    }, [modalData]);



    const allLocationData = [
        { id: '1', name: 'Andaman and Nicobar Islands' },
        { id: '2', name: 'Andhra Pradesh' },
        { id: '3', name: 'Arunachal Pradesh' },
        { id: '4', name: 'Assam' },
        { id: '5', name: 'Bihar' },
        { id: '6', name: 'Chandigarh' },
        { id: '7', name: 'Chhattisgarh' },
        { id: '8', name: 'Dadra and Nagar Haveli and Daman and Diu' },
        { id: '9', name: 'Delhi' },
        { id: '10', name: 'Goa' },
        { id: '11', name: 'Gujarat' },
        { id: '12', name: 'Haryana' },
        { id: '13', name: 'Himachal Pradesh' },
        { id: '14', name: 'Jammu and Kashmir' },
        { id: '15', name: 'Jharkhand' },
        { id: '16', name: 'Karnataka' },
        { id: '17', name: 'Kerala' },
        { id: '18', name: 'Ladakh' },
        { id: '19', name: 'Lakshadweep' },
        { id: '20', name: 'Madhya Pradesh' },
        { id: '21', name: 'Maharashtra' },
        { id: '22', name: 'Manipur' },
        { id: '23', name: 'Meghalaya' },
        { id: '24', name: 'Mizoram' },
        { id: '25', name: 'Nagaland' },
        { id: '26', name: 'Odisha' },
        { id: '27', name: 'Puducherry' },
        { id: '28', name: 'Punjab' },
        { id: '29', name: 'Rajasthan' },
        { id: '30', name: 'Sikkim' },
        { id: '31', name: 'Tamil Nadu' },
        { id: '32', name: 'Telangana' },
        { id: '33', name: 'Tripura' },
        { id: '34', name: 'Uttar Pradesh' },
        { id: '35', name: 'Uttarakhand' },
        { id: '36', name: 'West Bengal' },
    ];


    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project_name',
            'client',
            'concern_person',
            'location',
            'wo_date',
            'target_date',
            'due_date',
            'contract_mode',
            'confirmation_mode',
            'confirm_by',
            'prebid_prcode'

        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };



    // const isValidDate = (dateString) => {
    //     // Check if the date string is in dd-mm-yyyy format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;

    //     if (!datePattern.test(dateString)) {
    //         return false;
    //     }

    //     const [day, month, year] = dateString.split('-');
    //     const date = new Date(`${year}-${month}-${day}`);
    //     return !isNaN(date.getTime());
    // };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {


            if (formData.location === 'Other') {
                setFormData({
                    ...formData,
                    location: formData.otherLocation,
                });


            }
            const UpdatedFormData = {
                ...formData,
                username: sessionStorage.getItem('name')
            }

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/highway/projectupdate/${modalData.pr_code}/`, UpdatedFormData,)

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                alert(err);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Update the 'location' or 'otherLocation' field based on the 'name' of the input
        if (name === 'location' || name === 'otherLocation') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'concern_person') {
            // If the input name is 'concern_person', split the value to get both code and name
            const [code, name] = value.split(' - ');

            setFormData({
                ...formData,
                concern_person: code, // Store code in 'concern_person'
                concern_person_name: name, // Store name in 'concern_person_name'
            });
        } else {
            // Handle other fields (e.g., project_name, client, etc.) as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleLocationChange = (event) => {
        const { name, value } = event.target;

        // If the selected location is "Other," clear the input field value
        if (value === "Other") {
            setFormData({
                ...formData,
                location: value, // Update the select field value
                otherLocation: "", // Clear the input field value
            });
        } else {
            setFormData({
                ...formData,
                location: value,
            });
        }
    };








    return (
        <>

            <button onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16" className='upload-svg'>
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                </svg>
            </button>




            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Stage 1</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>

                                <div className="flex-column">
                                    <label className="form-labels">Project Code:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="prebid_prcode"
                                        value={formData.prebid_prcode}
                                        readOnly={formData.prebid_prcode ? true : false}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.prebid_prcode ? 'error' : inputState.prebid_prcode ? 'success' : ''}`}
                                    />
                                    {errors.prebid_prcode && <span className="error-message">{errors.prebid_prcode}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Project Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="project_name"
                                        value={formData.project_name}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                                    />
                                    {errors.name && <span className="error-message">{errors.name}</span>}
                                </div>


                                {/* <div className="flex-column form-group-select">
                                    <label className="form-labels">Client:<span className="required">*</span></label>
                                    <select
                                        name="client"
                                        value={formData.client}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection  ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {
                                            allClientData.length > 0 ?
                                                (allClientData.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.name} - {e.pos}</option>
                                                ))) : (
                                                    <option value="">No Clients Available</option>
                                                )
                                        }

                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.client && (
                                        <span className="error-message">{errors.client}</span>
                                    )}

                                </div> */}
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Concern Person:<span className="required">*</span></label>
                                    <select
                                        name="concern_person"
                                        value={`${formData.concern_person} - ${formData.concern_person_name}`}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection form-input-background ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                                    >
                                        <option value="">Select a concern_person</option>
                                        {allEmployeeData.length > 0 ? (
                                            allEmployeeData.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => (
                                                <option key={index} value={`${e.emp_code} - ${e.name}`}>{e.emp_code} - {e.name}</option>
                                            ))
                                        ) : (
                                            <option value="">No Employee Available</option>
                                        )}
                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.concern_person ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.concern_person && (
                                        <span className="error-message">{errors.concern_person}</span>
                                    )}

                                </div>



                                <div className="flex-column">
                                    <label className="form-labels">Tender Id:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="tender_id"
                                        value={formData.tender_id}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.tender_id ? 'error' : inputState.tender_id ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>


                                <div className="flex-column">
                                    <label>Cost ( In Crores):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="cost"
                                        value={formData.cost}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.cost ? 'error' : inputState.cost ? 'success' : ''}`}
                                    />
                                    {/* {errors.cost && (
                            <span className="error-message">{errors.cost}</span>
                        )} */}
                                </div>


                                <div className="flex-column">
                                    <label className="form-labels">Length (in KMS):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="length"
                                        value={formData.length}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                    />
                                    {/* {errors.altcontact_number && (
                            <span className="error-message">{errors.contact_number}</span>
                        )} */}
                                </div>

                                {/* <div className="flex-column">
                                    <label className="form-labels">Workorder Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="wo_date"
                                        value={formData.wo_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                    />
                                    {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                </div> */}


                                <div className="flex-column">
                                    <label className="form-labels">Target Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="target_date"
                                        value={formData.target_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.target_date ? 'error' : inputState.target_date ? 'success' : ''}`}
                                    />
                                    {errors.target_date && (
                                        <span className="error-message">{errors.target_date}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Due Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={formData.due_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                    />
                                    {errors.due_date && (
                                        <span className="error-message">{errors.due_date}</span>
                                    )}
                                </div>


                                <div className="flex-column ">
                                    <label className="form-labels">Link:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="link"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.link ? 'error' : inputState.link ? 'success' : ''}`}
                                    />
                                    {errors.link && (
                                        <span className="error-message">{errors.link}</span>
                                    )}
                                </div>


                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Location:<span className="required">*</span></label>
                                    {formData.location !== 'Other' ? (
                                        <>
                                            <select
                                                name="location"
                                                value={formData.location}
                                                onChange={handleLocationChange}
                                                className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Location</option>
                                                {
                                                    allLocationData.length > 0 ?
                                                        (allLocationData.map((e, index) => (
                                                            <option key={index} value={e.name}>{e.name}</option>
                                                        ))) : (
                                                            <option value="">No Locations Available</option>
                                                        )
                                                }
                                                <option value="Other">Other</option>
                                            </select><div className={`form-group-selection-arrow ${errors.location ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div></>) : (
                                        <input
                                            type="text"
                                            name="otherLocation"
                                            value={formData.otherLocation}
                                            onChange={handleInputChange}
                                            placeholder="Enter Other Location"
                                            className={`form-input  ${errors.otherLocation ? 'error' : ''}`}
                                        />
                                    )}
                                    {errors.location && (
                                        <span className="error-message">{errors.location}</span>
                                    )}
                                </div>


                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Contract Mode:<span className="required">*</span></label>
                                    <select
                                        name="contract_mode"
                                        value={formData.contract_mode}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.contract_mode ? 'error' : inputState.contract_mode ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Contract Mode</option>
                                        <option value="epc">EPC</option>
                                        <option value="ham">HAM</option>


                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.contract_mode ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.contract_mode && (
                                        <span className="error-message ">{errors.contract_mode}</span>
                                    )}

                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Remark:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.remark ? 'error' : inputState.remark ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>



                                <div className="flex-column">
                                    <label className="form-labels">Confirmed By:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirm_by"
                                        value={formData.confirm_by}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                    />
                                    {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirmation Mode:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirmation_mode"
                                        value={formData.confirmation_mode}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                    />
                                    {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                </div>



                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Status:</label>
                                    <select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="inprogress">In Progeress</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="completed">Completed</option>
                                        <option value="hold">Hold</option>
                                        <option value="cancelled">Cancelled</option>


                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.status ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.status && (
                                        <span className="error-message ">{errors.status}</span>
                                    )}

                                </div>
                            </div>

                            <div className="flex-column">
                                <Button onClick={handleSubmit} className="modal-button" style={{ width: "140px" }}>Submit</Button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    );
};




const UpdateProjectAmount = ({ id }) => {


    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "without_gst_amount",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const gstPercentage = 0.18; // 18% GST rate in decimal form
        const withoutGstAmount = parseFloat(formData.without_gst_amount);
        // Calculate the GST amount
        const gstAmount = formData.without_gst_amount * gstPercentage;
        const woAmount = withoutGstAmount + gstAmount



        console.log(withoutGstAmount + gstAmount)

        const updatedFormData = {


            ...formData,
            gst_amount: gstAmount,
            woamount: woAmount,
        }

        if (validateForm()) {
            try {
                let res = await axios.put(`${BASE_URL_PREBID}/highway/projectupdate/${id}/`, updatedFormData,)

                if (res.status === 200) {
                    window.location.reload();
                } else { }
            } catch (err) {
                alert(err);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };







    return (

        <>

            <button className="upload-svga" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    enable-background="new 0 0 32 32"
                    viewBox="0 0 32 32"
                    fill="white"
                    id="add"
                >
                    <path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path>
                </svg>
            </button>


            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Workorder Amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='modal-body-padding'>
                        <form >

                            <div className='form-flex-wrap'>

                                <div className="flex-column">
                                    <label>Work Order Amount Without Gst:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="without_gst_amount"
                                        value={formData.without_gst_amount}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>
            </Modal>

        </>

    );
};



export { UpdateProject, UpdateProjectAmount };