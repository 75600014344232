
import React, { useState, useEffect } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';

import TableDesignclient from './Tabledesignclient';

const Clientmain = () => {


    return (
        <>
            <Dashboardnavbar url="clients" name={"clients"} />
            <TableDesignclient />
        </>
    );
};


export default Clientmain